import React, { useCallback, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { chartColors, sensorInterval, themeColors } from '../../../constants';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IReduxState, ISampledTelemetry, IChartProps } from '../../../types';
import { IPoint } from '../../../types';
import { useWindowSize } from '../../useWindowSize';
import SkeletonLoaderGraph from '../SkeletonLoaderGraph';
import { ActivePointLayer } from '../../ActivePointLayer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      flexGrow: 1,
    },
    card: {
      height: 500,
      [theme.breakpoints.up('xs')]: {
        margin: `${theme.spacing(4)}px 0`,
      },
      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px`,
      },
    },
    cardContent: {
      height: '100%',
    },
    actionsArena: {
      height: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      position: 'relative',
    },
    tooltipWrapper: {
      position: 'absolute',
      background: themeColors.white,
      opacity: 0.9,
      padding: theme.spacing(2),
      paddingRight: 0,
      color: theme.palette.secondary.main,
      zIndex: 100,
      top: 20,
      left: -50,
      [theme.breakpoints.up('sm')]: {
        width: 160,
        top: 20,
        left: -70,
      },
    },
    tooltipLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    center: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      position: 'absolute',
      width: '100%',
      top: theme.spacing(2),
    },
  })
);

export default function FuelGraphLine(props: IChartProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const size = useWindowSize();

  let isSmallScreen = size.width !== undefined && size.width < 500;
  const { isPdfReport } = props;
  // Set isSmallScreen to false for the charts used in the PDF report
  isSmallScreen = isPdfReport === true ? false : isSmallScreen;

  const graphMargin = { top: 50, right: 80, bottom: 100, left: 50 };
  const graphMarginMobile = { top: 25, right: 25, bottom: 150, left: 50 };

  const isHybrid = useSelector(
    (state: IReduxState) =>
      !!state.machineDetails.machine.telemetrySnapshot?.isHybrid
  );

  const isFetchingTelemetry = useSelector(
    (state: IReduxState) => state.machineDetails.isFetchingTelemtry
  );
  const telemetry = useSelector(
    (state: IReduxState) => state.machineDetails.telemetry
  );
  const telemetryData: ISampledTelemetry | any = !!telemetry ? telemetry : [];
  const graphDateFormat = useSelector(
    (state: IReduxState) => state.machineDetails.graphDateFormat
  );

  // animate point on hover
  const [current, setCurrent] = useState(null);
  const handleHover = useCallback(
    (point) => {
      setCurrent(point);
    },
    [setCurrent]
  );

  const chartData = [
    {
      id: t('graph.diesel'),
      color: chartColors.deepPurple,
      data: telemetryData
        .filter(
          (item: ISampledTelemetry, index: number) =>
            (!item.isEmpty && item.fuelLevel !== null) ||
            (item.durationInSeconds &&
              item.durationInSeconds > sensorInterval.fuelLevel) ||
            // TODO KANM remove when updating NIVO and add min/max to xScale
            // enable first and last items
            index === 0 ||
            index === telemetryData.length - 1
        )
        .map((item: ISampledTelemetry) => {
          return {
            x: moment(item.timestamp)
              .utc()
              .local()
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            y:
              item.fuelLevel !== null && item.fuelLevel !== undefined
                ? item.fuelLevel.toFixed(2)
                : null,
          };
        }),
    },
    {
      id: t('graph.adblue'),
      color: chartColors.blue,
      data: telemetryData
        .filter(
          (item: ISampledTelemetry, index: number) =>
            (!item.isEmpty && item.adBlueLevel !== null) ||
            (item.durationInSeconds &&
              item.durationInSeconds > sensorInterval.adBlueLevel) ||
            // TODO KANM remove when updating NIVO and add min/max to xScale
            // enable first and last items
            index === 0 ||
            index === telemetryData.length - 1
        )
        .map((item: ISampledTelemetry) => {
          return {
            x: moment(item.timestamp)
              .utc()
              .local()
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            y:
              item.adBlueLevel !== null && item.adBlueLevel !== undefined
                ? item.adBlueLevel.toFixed(2)
                : null,
          };
        }),
    },
  ];

  const CustomTooltip = (pointData: IPoint) => {
    const { data, serieId, serieColor } = pointData.point;
    return (
      <div className={classes.tooltipWrapper}>
        <div className={classes.tooltipLabel}>
          <div style={{ background: `${serieColor}`, width: 10, height: 10 }} />
          <span style={{ color: `${serieColor}`, marginLeft: 8 }}>
            {serieId}
          </span>
        </div>
        <div>
          <span style={{ color: `${serieColor}` }}>{t('graph.date')}: </span>
          <span>{moment(data.x).format('DD.MM.YYYY')}</span>
        </div>
        <div>
          <span style={{ color: `${serieColor}` }}>{t('graph.time')}: </span>
          <span>{moment(data.x).format('HH:mm:ss')}</span>
        </div>
        <div>
          <span style={{ color: `${serieColor}` }}>
            {t('graph.fuelLevel')}:{' '}
          </span>
          <span>{data.y} %</span>
        </div>
      </div>
    );
  };

  //console.log(chartData[0]);

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Card
          className={classes.cardContent}
          style={{
            boxShadow:
              isHybrid && !isPdfReport
                ? `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`
                : 'none',
          }}
        >
          <CardContent className={classes.actionsArena}>
            {!isFetchingTelemetry ? (
              <React.Fragment>
                {telemetryData.length === 0 ||
                (telemetryData.fuelLevel === null &&
                  telemetryData.adBlueLevel === null) ? (
                  <div className={classes.center}>
                    <Typography align="center" variant="h4">
                      {t('graph.fuelLevels')}
                    </Typography>
                    <Typography align="center" variant="h5">
                      {t('graph.noData')}
                    </Typography>
                  </div>
                ) : (
                  <React.Fragment>
                    {!isSmallScreen && (
                      <div className={classes.title}>
                        <Typography align="center" variant="h6">
                          {t('graph.fuelLevels')}
                        </Typography>
                      </div>
                    )}
                    <ResponsiveLine
                      theme={{
                        tooltip: {
                          container: {
                            background: '#2d374d',
                            color: 'inherit',
                            boxShadow: '0 3px 9px rgba(0, 0, 0, 0.5)',
                          },
                        },
                        axis: {
                          legend: {
                            text: {
                              fill: '#fff',
                            },
                          },
                          ticks: {
                            line: {
                              stroke: 'gray',
                            },
                            text: {
                              fill: '#fff',
                            },
                          },
                        },
                      }}
                      data={chartData}
                      animate={false}
                      colors={(d: any) => d.color}
                      lineWidth={2}
                      margin={isSmallScreen ? graphMarginMobile : graphMargin}
                      xScale={{
                        type: 'time',
                        format: '%Y-%m-%d %H:%M:%S.%L',
                        precision: 'millisecond',
                        useUTC: false,
                      }}
                      xFormat="time:%Y-%m-%d %H:%M:%S.%L"
                      yScale={{ type: 'linear', min: 0, max: 100 }}
                      curve="monotoneX"
                      axisTop={null}
                      axisRight={null}
                      enablePoints={true}
                      layers={[
                        'grid',
                        'markers',
                        'axes',
                        'areas',
                        'lines',
                        'slices',
                        'points',
                        'mesh',
                        'legends',
                        () => <ActivePointLayer point={current} />,
                      ]}
                      //@ts-ignore
                      axisBottom={{
                        orient: 'bottom',
                        tickSize: 10,
                        tickPadding: 40,
                        tickRotation: 270,
                        format: (tick) => moment(tick).format(graphDateFormat),
                      }}
                      tooltip={CustomTooltip}
                      onMouseEnter={handleHover}
                      onMouseMove={handleHover}
                      onMouseLeave={handleHover}
                      axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: `${t('graph.fuelLevel')} [${t('graph.%')}]`,
                        legendOffset: -40,
                        legendPosition: 'middle',
                      }}
                      enableGridX={false}
                      enableCrosshair={false}
                      pointSize={2}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      pointLabel="x"
                      pointLabelYOffset={-12}
                      useMesh={true}
                      legends={[
                        {
                          anchor: isSmallScreen ? 'bottom-right' : 'bottom',
                          direction: isSmallScreen ? 'column' : 'row',
                          justify: false,
                          translateX: 0,
                          translateY: isSmallScreen ? 140 : 85,
                          itemsSpacing: isSmallScreen ? 0 : 10,
                          itemDirection: 'left-to-right',
                          itemWidth: isSmallScreen ? 200 : 150,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          itemTextColor: '#fff',
                          symbolSize: 12,
                          symbolShape: 'circle',
                          symbolBorderColor: 'rgba(0, 0, 0, .5)',
                          effects: [
                            {
                              on: 'hover',
                              style: {
                                itemBackground: 'rgba(0, 0, 0, .03)',
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <SkeletonLoaderGraph />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
