/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PointDto,
    PointDtoFromJSON,
    PointDtoFromJSONTyped,
    PointDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TelemetrySnapshotDto
 */
export interface TelemetrySnapshotDto {
    /**
     * 
     * @type {string}
     * @memberof TelemetrySnapshotDto
     */
    serialNumber: string;
    /**
     * 
     * @type {Date}
     * @memberof TelemetrySnapshotDto
     */
    updatedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof TelemetrySnapshotDto
     */
    isIgnitionOn?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TelemetrySnapshotDto
     */
    operationgModeGenerator?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TelemetrySnapshotDto
     */
    operatingModeExternal?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof TelemetrySnapshotDto
     */
    operatingModeNeutral?: boolean | null;
    /**
     * 
     * @type {PointDto}
     * @memberof TelemetrySnapshotDto
     */
    location?: PointDto | null;
    /**
     * 
     * @type {number}
     * @memberof TelemetrySnapshotDto
     */
    totalHoursOfEngineOperation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TelemetrySnapshotDto
     */
    totalHoursOfCrusherOperation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TelemetrySnapshotDto
     */
    totalHoursOfInternalPowerSupply?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TelemetrySnapshotDto
     */
    totalHoursOfExternalPowerSupply?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TelemetrySnapshotDto
     */
    fuelLevel?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TelemetrySnapshotDto
     */
    adBlueLevel?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof TelemetrySnapshotDto
     */
    isHybrid?: boolean | null;
}

export function TelemetrySnapshotDtoFromJSON(json: any): TelemetrySnapshotDto {
    return TelemetrySnapshotDtoFromJSONTyped(json, false);
}

export function TelemetrySnapshotDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetrySnapshotDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serialNumber': json['serialNumber'],
        'updatedAt': (new Date(json['updatedAt'])),
        'isIgnitionOn': !exists(json, 'isIgnitionOn') ? undefined : json['isIgnitionOn'],
        'operationgModeGenerator': !exists(json, 'operationgModeGenerator') ? undefined : json['operationgModeGenerator'],
        'operatingModeExternal': !exists(json, 'operatingModeExternal') ? undefined : json['operatingModeExternal'],
        'operatingModeNeutral': !exists(json, 'operatingModeNeutral') ? undefined : json['operatingModeNeutral'],
        'location': !exists(json, 'location') ? undefined : PointDtoFromJSON(json['location']),
        'totalHoursOfEngineOperation': !exists(json, 'totalHoursOfEngineOperation') ? undefined : json['totalHoursOfEngineOperation'],
        'totalHoursOfCrusherOperation': !exists(json, 'totalHoursOfCrusherOperation') ? undefined : json['totalHoursOfCrusherOperation'],
        'fuelLevel': !exists(json, 'fuelLevel') ? undefined : json['fuelLevel'],
        'adBlueLevel': !exists(json, 'adBlueLevel') ? undefined : json['adBlueLevel'],
        'isHybrid': !exists(json, 'isHybrid') ? undefined : json['isHybrid'],
    };
}

export function TelemetrySnapshotDtoToJSON(value?: TelemetrySnapshotDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serialNumber': value.serialNumber,
        'updatedAt': (value.updatedAt.toISOString()),
        'isIgnitionOn': value.isIgnitionOn,
        'operationgModeGenerator': value.operationgModeGenerator,
        'operatingModeExternal': value.operatingModeExternal,
        'operatingModeNeutral': value.operatingModeNeutral,
        'location': PointDtoToJSON(value.location),
        'totalHoursOfEngineOperation': value.totalHoursOfEngineOperation,
        'totalHoursOfCrusherOperation': value.totalHoursOfCrusherOperation,
        'fuelLevel': value.fuelLevel,
        'adBlueLevel': value.adBlueLevel,
        'isHybrid': value.isHybrid,
    };
}


