/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StateDto,
    StateDtoFromJSON,
    StateDtoToJSON,
    TagDto,
    TagDtoFromJSON,
    TagDtoToJSON,
} from '../models';

export interface ApiMachineTagsDeleteRequest {
    machineId?: number;
    tagId?: number;
}

export interface ApiMachineTagsGetRequest {
    machineId?: number;
}

export interface ApiMachineTagsPostRequest {
    machineId?: number;
    tagId?: number;
}

/**
 * no description
 */
export class MachineTagsApi extends runtime.BaseAPI {

    /**
     * Deletes a MachineTag-assignment for the given Ids. If the Ids are not assigned to each other a HTTP404 will be returned.
     */
    async apiMachineTagsDeleteRaw(requestParameters: ApiMachineTagsDeleteRequest): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.tagId !== undefined) {
            queryParameters['tagId'] = requestParameters.tagId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MachineTags`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * Deletes a MachineTag-assignment for the given Ids. If the Ids are not assigned to each other a HTTP404 will be returned.
     */
    async apiMachineTagsDelete(requestParameters: ApiMachineTagsDeleteRequest): Promise<boolean> {
        const response = await this.apiMachineTagsDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiMachineTagsGetRaw(requestParameters: ApiMachineTagsGetRequest): Promise<runtime.ApiResponse<Array<TagDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MachineTags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagDtoFromJSON));
    }

    /**
     */
    async apiMachineTagsGet(requestParameters: ApiMachineTagsGetRequest): Promise<Array<TagDto>> {
        const response = await this.apiMachineTagsGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiMachineTagsPostRaw(requestParameters: ApiMachineTagsPostRequest): Promise<runtime.ApiResponse<StateDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.machineId !== undefined) {
            queryParameters['machineId'] = requestParameters.machineId;
        }

        if (requestParameters.tagId !== undefined) {
            queryParameters['tagId'] = requestParameters.tagId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MachineTags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StateDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiMachineTagsPost(requestParameters: ApiMachineTagsPostRequest): Promise<StateDto> {
        const response = await this.apiMachineTagsPostRaw(requestParameters);
        return await response.value();
    }

}
