/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MeasurementSystem,
    MeasurementSystemFromJSON,
    MeasurementSystemToJSON,
    TelemetryRangeDto,
    TelemetryRangeDtoFromJSON,
    TelemetryRangeDtoToJSON,
} from '../models';

export interface ApiTelemetryMachineIdGetRequest {
    machineId: number;
    from?: Date;
    till?: Date;
    measurementSystem?: MeasurementSystem;
    resolutionInSeconds?: number;
}

/**
 * no description
 */
export class TelemetryApi extends runtime.BaseAPI {

    /**
     */
    async apiTelemetryMachineIdGetRaw(requestParameters: ApiTelemetryMachineIdGetRequest): Promise<runtime.ApiResponse<TelemetryRangeDto>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling apiTelemetryMachineIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString();
        }

        if (requestParameters.till !== undefined) {
            queryParameters['till'] = (requestParameters.till as any).toISOString();
        }

        if (requestParameters.measurementSystem !== undefined) {
            queryParameters['measurementSystem'] = requestParameters.measurementSystem;
        }

        if (requestParameters.resolutionInSeconds !== undefined) {
            queryParameters['resolutionInSeconds'] = requestParameters.resolutionInSeconds;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Telemetry/{machineId}`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TelemetryRangeDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTelemetryMachineIdGet(requestParameters: ApiTelemetryMachineIdGetRequest): Promise<TelemetryRangeDto> {
        console.warn("In apiTelemetryMachineIdGet");
        const response = await this.apiTelemetryMachineIdGetRaw(requestParameters);
        return await response.value();
    }

}
