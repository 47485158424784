import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AutoCompleteSearch from './AutoCompleteSearch';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    searchIcon: {
      width: theme.spacing(7),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export default function DesktopSearchTextInput() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <AutoCompleteSearch />
    </React.Fragment>
  );
}
