import React from 'react';
import { chartColors, themeColors } from '../../../constants';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IChartProps, IReduxState } from '../../../types';
import SkeletonLoaderCardSmall from '../SkeletonLoaderCardSmall';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      flexGrow: 1,
    },
    card: {
      height: 500,
      [theme.breakpoints.up('xs')]: {
        margin: `${theme.spacing(4)}px 0`,
      },
      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px`,
      },
    },
    cardContent: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(2)}px 0`,
      },
    },
    actionsArena: {
      position: 'relative',
      height: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(4),
        justifyContent: 'start',
      },
    },
    lastUpdate: {
      position: 'absolute',
      left: theme.spacing(1),
      top: 0,
      color: themeColors.white,
    },
    gaugeWrapper: {
      height: 175,
      width: 200,
    },
    firstEntry: {
      margin: `${theme.spacing(2)}px 0 0 0`,
      [theme.breakpoints.up('md')]: {
        margin: 0,
      },
      [theme.breakpoints.up('lg')]: {
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0`,
      },
    },
    title: {
      marginTop: theme.spacing(2),
    },
    displayItem: {
      margin: theme.spacing(1),
    },
    display: {
      margin: theme.spacing(1),
    },
  })
);

export default function FuelConsumptionDisplay(props: IChartProps) {
  const { isPdfReport } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const isFetchingTelemetry = useSelector(
    (state: IReduxState) => state.machineDetails.isFetchingTelemtry
  );
  const isHybrid = useSelector(
    (state: IReduxState) =>
      !!state.machineDetails.machine.telemetrySnapshot?.isHybrid
  );

  const averageFuelConsumption = useSelector(
    (state: IReduxState) => state.machineDetails.averageFuelConsumption
  );

  const isThirdParty = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.machineType.isThirdParty
  );

  const {
    engineFuelRate,
    engineFuelConsumed,
    mainDischargeConveyorFuelRate,
    sideDischargeConveyorFuelRate,
  } = averageFuelConsumption;

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Card
          className={classes.cardContent}
          style={{
            boxShadow:
              isHybrid && !isPdfReport
                ? `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`
                : 'none',
          }}
        >
          <CardContent
            className={classes.actionsArena}
            style={{
              paddingBottom: 0,
              paddingTop: isFetchingTelemetry ? '1rem' : '2rem',
            }}
          >
            {!isFetchingTelemetry ? (
              <React.Fragment>
                <Typography className={classes.lastUpdate} variant="caption">
                  {`${t('graph.lastReceivedValue')}: ${
                    !!averageFuelConsumption.timestamp
                      ? moment
                          .utc(averageFuelConsumption.timestamp)
                          .local()
                          .format(`DD.MM.YYYY HH:mm`)
                      : '-'
                  }`}
                </Typography>
                <Typography
                  align="center"
                  variant="h5"
                  className={classes.title}
                >
                  {t('graph.averageFuelConsumption')}
                </Typography>
                <div className={classes.firstEntry}>
                  <Typography align="center" variant="body1">
                    {t('graph.engine')}
                  </Typography>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{ color: chartColors.purple }}
                  >
                    {engineFuelRate !== null &&
                    engineFuelRate.value !== undefined
                      ? `${engineFuelRate.value.toFixed(2)} ${
                          engineFuelRate.unit
                        }`
                      : t('graph.noData')}
                  </Typography>
                </div>
                {!isThirdParty && (
                  <>
                    <div className={classes.display}>
                      <Typography align="center" variant="body1">
                        {t('graph.mainDischargeConveyor')}
                      </Typography>
                      <Typography
                        align="center"
                        variant="h6"
                        style={{ color: chartColors.cyan }}
                      >
                        {mainDischargeConveyorFuelRate !== null &&
                        mainDischargeConveyorFuelRate.value !== undefined
                          ? `${mainDischargeConveyorFuelRate.value.toFixed(
                              2
                            )} ${mainDischargeConveyorFuelRate.unit}`
                          : t('graph.noData')}
                      </Typography>
                    </div>
                    <div className={classes.display}>
                      <Typography align="center" variant="body1">
                        {t('graph.secondDischargeConveyor')}
                      </Typography>
                      <Typography
                        align="center"
                        variant="h6"
                        style={{ color: chartColors.green }}
                      >
                        {sideDischargeConveyorFuelRate !== null &&
                        sideDischargeConveyorFuelRate.value !== undefined
                          ? `${sideDischargeConveyorFuelRate?.value.toFixed(
                              2
                            )} ${sideDischargeConveyorFuelRate.unit}`
                          : t('graph.noData')}
                      </Typography>
                    </div>
                  </>
                )}
                <br />
                <div className={classes.display}>
                  <Typography align="center" variant="body1">
                    {t('graph.engineFuelConsumed')}
                  </Typography>
                  <Typography
                    align="center"
                    variant="h6"
                    style={{ color: chartColors.purple }}
                  >
                    {engineFuelConsumed !== null &&
                    engineFuelConsumed.value !== undefined
                      ? `${engineFuelConsumed.value.toFixed(2)} ${
                          engineFuelConsumed.unit
                        }`
                      : t('graph.noData')}
                  </Typography>
                </div>
              </React.Fragment>
            ) : (
              <SkeletonLoaderCardSmall />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
