/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UnitValueDto,
    UnitValueDtoFromJSON,
    UnitValueDtoFromJSONTyped,
    UnitValueDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TelemetryRangeSavingsDto
 */
export interface TelemetryRangeSavingsDto {
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeSavingsDto
     */
    dieselSaved?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeSavingsDto
     */
    energySaved?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeSavingsDto
     */
    co2Saved?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeSavingsDto
     */
    co2Emission?: UnitValueDto | null;
}

export function TelemetryRangeSavingsDtoFromJSON(json: any): TelemetryRangeSavingsDto {
    return TelemetryRangeSavingsDtoFromJSONTyped(json, false);
}

export function TelemetryRangeSavingsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetryRangeSavingsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dieselSaved': !exists(json, 'dieselSaved') ? undefined : UnitValueDtoFromJSON(json['dieselSaved']),
        'energySaved': !exists(json, 'energySaved') ? undefined : UnitValueDtoFromJSON(json['energySaved']),
        'co2Saved': !exists(json, 'co2Saved') ? undefined : UnitValueDtoFromJSON(json['co2Saved']),
        'co2Emission': !exists(json, 'co2Emission') ? undefined : UnitValueDtoFromJSON(json['co2Emission']),
    };
}

export function TelemetryRangeSavingsDtoToJSON(value?: TelemetryRangeSavingsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dieselSaved': UnitValueDtoToJSON(value.dieselSaved),
        'energySaved': UnitValueDtoToJSON(value.energySaved),
        'co2Saved': UnitValueDtoToJSON(value.co2Saved),
        'co2Emission': UnitValueDtoToJSON(value.co2Emission),
    };
}


