/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MeasurementSystem,
    MeasurementSystemFromJSON,
    MeasurementSystemToJSON,
    UserDto,
    UserDtoFromJSON,
    UserDtoToJSON,
} from '../models';

export interface ApiUsersIdDeleteRequest {
    id: number;
}

export interface ApiUsersIdGetRequest {
    id: number;
}

export interface ApiUsersIdPutRequest {
    id: number;
    userDto?: UserDto;
}

export interface ApiUsersPostRequest {
    userDto?: UserDto;
}

export interface UpdatePreferencesPutRequest {
    id?: number;
    isoLanguageString?: string;
    measurementSystem?: MeasurementSystem;
}

/**
 * no description
 */
export class UsersApi extends runtime.BaseAPI {

    /**
     */
    async apiUsersGetRaw(): Promise<runtime.ApiResponse<Array<UserDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserDtoFromJSON));
    }

    /**
     */
    async apiUsersGet(): Promise<Array<UserDto>> {
        const response = await this.apiUsersGetRaw();
        return await response.value();
    }

    /**
     * Deletes a User for the given Id. If the Users Id is used for any relation then the deletion will fail. HTTP422 will be returned. If the Id does not exist HTTP404 will be returned.
     */
    async apiUsersIdDeleteRaw(requestParameters: ApiUsersIdDeleteRequest): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Deletes a User for the given Id. If the Users Id is used for any relation then the deletion will fail. HTTP422 will be returned. If the Id does not exist HTTP404 will be returned.
     */
    async apiUsersIdDelete(requestParameters: ApiUsersIdDeleteRequest): Promise<UserDto> {
        const response = await this.apiUsersIdDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUsersIdGetRaw(requestParameters: ApiUsersIdGetRequest): Promise<runtime.ApiResponse<UserDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersIdGet(requestParameters: ApiUsersIdGetRequest): Promise<UserDto> {
        const response = await this.apiUsersIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiUsersIdPutRaw(requestParameters: ApiUsersIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters.userDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersIdPut(requestParameters: ApiUsersIdPutRequest): Promise<void> {
        await this.apiUsersIdPutRaw(requestParameters);
    }

    /**
     */
    async apiUsersPostRaw(requestParameters: ApiUsersPostRequest): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserDtoToJSON(requestParameters.userDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersPost(requestParameters: ApiUsersPostRequest): Promise<UserDto> {
        const response = await this.apiUsersPostRaw(requestParameters);
        return await response.value();
    }

    /**
     * Update user preferences for isoLanguageCode and MeasurementSystem.
     */
    async updatePreferencesPutRaw(requestParameters: UpdatePreferencesPutRequest): Promise<runtime.ApiResponse<UserDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        if (requestParameters.isoLanguageString !== undefined) {
            queryParameters['isoLanguageString'] = requestParameters.isoLanguageString;
        }

        if (requestParameters.measurementSystem !== undefined) {
            queryParameters['measurementSystem'] = requestParameters.measurementSystem;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/update-preferences`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UserDtoFromJSON(jsonValue));
    }

    /**
     * Update user preferences for isoLanguageCode and MeasurementSystem.
     */
    async updatePreferencesPut(requestParameters: UpdatePreferencesPutRequest): Promise<UserDto> {
        const response = await this.updatePreferencesPutRaw(requestParameters);
        return await response.value();
    }

}
