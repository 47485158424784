import {
  FETCH_MACHINE_DETAILS_REQUEST,
  FETCH_MACHINE_DETAILS_SUCCESS,
  FETCH_MACHINE_DETAILS_FAIL,
  UPDATE_START_DATE,
  UPDATE_END_DATE,
  CREATE_PDF_REPORT,
  CREATE_PDF_REPORT_SUCCESS,
  UPDATE_MACHINE_STATE_SUCCESS,
  ADD_MACHINE_TAG_SUCCESS,
  DELETE_MACHINE_TAG_SUCCESS,
  EDIT_MACHINE_NOTE_SUCCESS,
  DELETE_MACHINE_NOTE_SUCCESS,
  FETCH_MACHINE_DETAILS_ADDRESS_FAIL,
  FETCH_MACHINE_DETAILS_ADDRESS_SUCCESS,
  FETCH_MACHINE_TELEMETRY_SUCCESS,
  FETCH_MACHINE_TELEMETRY_REQUEST,
  FETCH_MACHINE_TELEMETRY_FAIL,
  SET_AVERAGE_ENGINE_UTILIZATION,
  SET_AVERAGE_FUEL_CONSUMPTION,
  RESET_MACHINE_DETAILS,
  UPDATE_GRAPH_TICK_VALUES,
  EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS,
  DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS,
  RESET_AVERAGE_FUEL_CONSUMPTION,
  RESET_AVERAGE_ENGINE_UTILIZATION,
  SET_FUEL_CONSUMED,
  RESET_FUEL_CONSUMED,
  SET_AVERAGE_THROUGHPUTS,
  RESET_AVERAGE_THROUGHPUTS,
  SET_AVERAGE_FUEL_LEVELS,
  RESET_AVERAGE_FUEL_LEVELS,
  SET_TOTAL_HOURS,
  RESET_TOTAL_HOURS,
  RESET_MACHINE_TELEMETRY,
  SET_AVERAGE_ENERGY_UTILIZATION,
  RESET_AVERAGE_ENERGY_UTILIZATION,
  RESET_AVERAGE_ENERGY_CONSUMPTION,
  SET_AVERAGE_ENERGY_CONSUMPTION,
} from '../actions/types';
import { IReduxStateMachineDetails, IExtendedMachineTagOption } from '../types';
import { initialMachineState } from '../constants';

const today = new Date();
// Set initial start date to 12hours back
const yesterday = new Date(today);
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(yesterday.getHours() + 12);
const startDate = yesterday;
const initialState: IReduxStateMachineDetails = {
  machine: initialMachineState,
  isFetching: false,
  updatedAt: new Date(),
  search: {
    startDate,
    endDate: today,
    maxEndDate: today,
  },
  isFetchingTelemtry: false,
  errorCodes: null,
  telemetry: null,
  graphTickValues: 'every day',
  graphDateFormat: 'DD.MM',
  averageFuelLevels: {
    fuelLevel: null,
    adBlueLevel: null,
    timestamp: null,
  },
  averageEngineUtilization: {
    value: null,
    timestamp: null,
  },
  averageEnergyUtilization: {
    value: null,
    timestamp: null,
  },
  averageThroughputs: {
    weightLoad1: null,
    weightLoad2: null,
    weightLoad3: null,
    weightLoad4: null,
    timestamp: null,
  },
  averageFuelConsumption: {
    mainDischargeConveyorFuelRate: null,
    sideDischargeConveyorFuelRate: null,
    engineFuelConsumed: null,
    engineFuelRate: null,
    timestamp: null,
  },
  averageEnergyConsumption: {
    mainDischargeConveyorEnergyRate: null,
    sideDischargeConveyorEnergyRate: null,
    energyConsumeRate: null,
    totalEnergyConsumed: null,
    timestamp: null,
  },
  totalEngineFuelConsumed: null,
  totalWeightLoad1: null,
  totalWeightLoad2: null,
  totalWeightLoad3: null,
  totalWeightLoad4: null,
  totalHoursOfEngineOperation: null,
  totalHoursOfCrusherOperation: null,
  totalHoursOfExternalPowerSupply: null,
  totalHoursOfInternalPowerSupply: null,
  co2Saved: null,
  dieselSaved: null,
  energySaved: null,
  co2Emission: null,
  suggestedAddress: '',
  isCreatingPdf: false,
};

export default function(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_MACHINE_DETAILS_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_MACHINE_DETAILS_SUCCESS:
      return {
        ...state,
        machine: action.payload,
        isFetching: false,
      };
    case FETCH_MACHINE_DETAILS_FAIL:
      return {
        ...state,
        isFetching: false,
      };
    case UPDATE_START_DATE:
      return {
        ...state,
        search: {
          ...state.search,
          startDate: action.payload,
        },
      };
    case UPDATE_END_DATE:
      return {
        ...state,
        search: {
          ...state.search,
          endDate: action.payload,
        },
      };
    case FETCH_MACHINE_TELEMETRY_REQUEST:
      return {
        ...state,
        isFetchingTelemtry: true,
      };
    case FETCH_MACHINE_TELEMETRY_SUCCESS:
      return {
        ...state,
        isFetchingTelemtry: false,
        telemetry: action.payload.telemetry,
        errorCodes: action.payload.errorCodes,
      };
    case FETCH_MACHINE_TELEMETRY_FAIL:
      return {
        ...state,
        isFetchingTelemtry: false,
      };
    case RESET_MACHINE_TELEMETRY:
      return {
        ...state,
        isFetchingTelemtry: false,
        errorCodes: null,
        telemetry: null,
        graphTickValues: 'every day',
        graphDateFormat: 'DD.MM',
        averageFuelLevels: {
          fuelLevel: null,
          adBlueLevel: null,
          timestamp: null,
        },
        averageEngineUtilization: {
          value: null,
          timestamp: null,
        },
        averageThroughputs: {
          weightLoad1: null,
          weightLoad2: null,
          weightLoad3: null,
          weightLoad4: null,
          timestamp: null,
        },
        averageFuelConsumption: {
          mainDischargeConveyorFuelRate: null,
          sideDischargeConveyorFuelRate: null,
          engineFuelConsumed: null,
          engineFuelRate: null,
          timestamp: null,
        },
        averageEnergyConsumption: {
          mainDischargeConveyorEnergyRate: null,
          sideDischargeConveyorEnergyRate: null,
          energyConsumeRate: null,
          totalEnergyConsumed: null,
          timestamp: null,
        },
        sumEngineFuelConsumed: null,
        totalWeightLoad1: null,
        totalWeightLoad2: null,
        totalWeightLoad3: null,
        totalWeightLoad4: null,
        totalHoursOfEngineOperation: null,
        totalHoursOfCrusherOperation: null,
        totalHoursOfExternalPowerSupply: null,
        totalHoursOfInternalPowerSupply: null,
        totalEngineFuelConsumed: null,
        co2Saved: null,
        dieselSaved: null,
        energySaved: null,
        co2Emission: null,
        isCreatingPdf: false,
      };
    case SET_AVERAGE_FUEL_LEVELS:
      return {
        ...state,
        averageFuelLevels: {
          fuelLevel: action.payload.value.fuelLevel,
          adBlueLevel: action.payload.value.adBlueLevel,
          timestamp: action.payload.timestamp,
        },
      };
    case RESET_AVERAGE_FUEL_LEVELS:
      return {
        ...state,
        averageFuelLevels: {
          fuelLevel: null,
          adBlueLevel: null,
          timestamp: null,
        },
      };
    case SET_AVERAGE_ENGINE_UTILIZATION:
      return {
        ...state,
        averageEngineUtilization: {
          value: action.payload.value,
          timestamp: action.payload.timestamp,
        },
      };
    case RESET_AVERAGE_ENGINE_UTILIZATION:
      return {
        ...state,
        averageEngineUtilization: {
          value: null,
          timestamp: null,
        },
      };
    case SET_AVERAGE_ENERGY_UTILIZATION:
      return {
        ...state,
        averageEnergyUtilization: {
          value: action.payload.value,
          timestamp: action.payload.timestamp,
        },
      };
    case RESET_AVERAGE_ENERGY_UTILIZATION:
      return {
        ...state,
        averageEnergyUtilization: {
          value: null,
          timestamp: null,
        },
      };
    case SET_AVERAGE_THROUGHPUTS:
      return {
        ...state,
        averageThroughputs: {
          weightLoad1: action.payload.value.weightLoad1,
          weightLoad2: action.payload.value.weightLoad2,
          weightLoad3: action.payload.value.weightLoad3,
          weightLoad4: action.payload.value.weightLoad4,
          timestamp: action.payload.timestamp,
        },
      };
    case RESET_AVERAGE_THROUGHPUTS:
      return {
        ...state,
        averageThroughputs: {
          weightLoad1: null,
          weightLoad2: null,
          weightLoad3: null,
          weightLoad4: null,
          timestamp: null,
        },
      };
    case SET_AVERAGE_FUEL_CONSUMPTION:
      return {
        ...state,
        averageFuelConsumption: {
          mainDischargeConveyorFuelRate:
            action.payload.mainDischargeConveyorFuelRate,
          sideDischargeConveyorFuelRate:
            action.payload.sideDischargeConveyorFuelRate,
          engineFuelConsumed: action.payload.engineFuelConsumed,
          engineFuelRate: action.payload.engineFuelRate,
          timestamp: action.payload.timestamp,
        },
      };
    case RESET_AVERAGE_FUEL_CONSUMPTION:
      return {
        ...state,
        averageFuelConsumption: {
          mainDischargeConveyorFuelRate: null,
          sideDischargeConveyorFuelRate: null,
          engineFuelConsumed: null,
          engineFuelRate: null,
          timestamp: null,
        },
      };
    case SET_AVERAGE_ENERGY_CONSUMPTION:
      return {
        ...state,
        averageEnergyConsumption: {
          mainDischargeConveyorEnergyRate:
            action.payload.mainDischargeConveyorEnergyRate,
          sideDischargeConveyorEnergyRate:
            action.payload.sideDischargeConveyorEnergyRate,
          energyConsumeRate: action.payload.energyConsumeRate,
          totalEnergyConsumed: action.payload.totalEnergyConsumed,
          timestamp: action.payload.timestamp,
        },
      };
    case RESET_AVERAGE_ENERGY_CONSUMPTION:
      return {
        ...state,
        averageEnergyConsumption: {
          mainDischargeConveyorEnergyRate: null,
          sideDischargeConveyorEnergyRate: null,
          energyConsumeRate: null,
          totalEnergyConsumed: null,
          timestamp: null,
        },
      };
    case SET_FUEL_CONSUMED:
      return {
        ...state,
        totalEngineFuelConsumed: {
          value: action.payload.value,
          unit: action.payload.unit,
        },
      };
    case RESET_FUEL_CONSUMED:
      return {
        ...state,
        sumEngineFuelConsumed: null,
      };
    case SET_TOTAL_HOURS:
      return {
        ...state,
        totalWeightLoad1: action.payload.totalWeightLoad1,
        totalWeightLoad2: action.payload.totalWeightLoad2,
        totalWeightLoad3: action.payload.totalWeightLoad3,
        totalWeightLoad4: action.payload.totalWeightLoad4,
        totalHoursOfEngineOperation: action.payload.totalHoursOfEngineOperation,
        totalHoursOfCrusherOperation:
          action.payload.totalHoursOfCrusherOperation,
        totalHoursOfExternalPowerSupply:
          action.payload.totalHoursOfExternalPowerSupply,
        totalHoursOfInternalPowerSupply:
          action.payload.totalHoursOfInternalPowerSupply,
        co2Saved: action.payload.co2Saved,
        dieselSaved: action.payload.dieselSaved,
        energySaved: action.payload.energySaved,
        co2Emission: action.payload.co2Emission,
      };
    case RESET_TOTAL_HOURS:
      return {
        ...state,
        totalWeightLoad1: null,
        totalWeightLoad2: null,
        totalWeightLoad3: null,
        totalWeightLoad4: null,
        totalHoursOfEngineOperation: null,
        totalHoursOfCrusherOperation: null,
        totalHoursOfExternalPowerSupply: null,
        totalHoursOfInternalPowerSupply: null,
        co2Saved: null,
        dieselSaved: null,
        energySaved: null,
        co2Emission: null,
      };
    case FETCH_MACHINE_DETAILS_ADDRESS_SUCCESS:
      return {
        ...state,
        suggestedAddress: action.payload,
      };
    case FETCH_MACHINE_DETAILS_ADDRESS_FAIL:
      return {
        ...state,
        suggestedAddress: 'notFound',
      };
    case CREATE_PDF_REPORT:
      return {
        ...state,
        isCreatingPdf: true,
      };
    case CREATE_PDF_REPORT_SUCCESS:
      return {
        ...state,
        isCreatingPdf: false,
      };
    case UPDATE_MACHINE_STATE_SUCCESS:
      return {
        ...state,
        machine: {
          ...state.machine,
          state: action.payload,
        },
      };
    case ADD_MACHINE_TAG_SUCCESS:
      return {
        ...state,
        machine: {
          ...state.machine,
          tags: [...state.machine.tags, action.payload],
        },
      };
    case DELETE_MACHINE_TAG_SUCCESS:
      return {
        ...state,
        machine: {
          ...state.machine,
          tags: state.machine?.tags?.filter(
            (tag: IExtendedMachineTagOption) => tag.id !== action.payload.id
          ),
        },
      };
    case EDIT_MACHINE_NOTE_SUCCESS:
      return {
        ...state,
        machine: {
          ...state.machine,
          notes: action.payload,
        },
      };
    case DELETE_MACHINE_NOTE_SUCCESS:
      return {
        ...state,
        machine: {
          ...state.machine,
          notes: null,
        },
      };
    case EDIT_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS:
      return {
        ...state,
        machine: {
          ...state.machine,
          projectConstructionSite: action.payload,
        },
      };
    case DELETE_MACHINE_PROJECT_CONSTRUCTION_SITE_SUCCESS:
      return {
        ...state,
        machine: {
          ...state.machine,
          projectConstructionSite: null,
        },
      };
    case RESET_MACHINE_DETAILS:
      return {
        ...state,
        machine: initialMachineState,
        isFetchingTelemtry: false,
        errorCodes: null,
        telemetry: null,
        graphTickValues: 'every day',
        graphDateFormat: 'DD.MM',
        averageFuelLevels: {
          fuelLevel: null,
          adBlueLevel: null,
          timestamp: null,
        },
        averageEngineUtilization: {
          value: null,
          timestamp: null,
        },
        averageEnergyUtilization: {
          value: null,
          timestamp: null,
        },
        averageThroughputs: {
          weightLoad1: null,
          weightLoad2: null,
          weightLoad3: null,
          weightLoad4: null,
          timestamp: null,
        },
        averageFuelConsumption: {
          mainDischargeConveyorFuelRate: null,
          sideDischargeConveyorFuelRate: null,
          engineFuelConsumed: null,
          engineFuelRate: null,
          timestamp: null,
        },
        averageEnergyConsumption: {
          mainDischargeConveyorEnergyRate: null,
          sideDischargeConveyorEnergyRate: null,
          energyConsumeRate: null,
          totalEnergyConsumed: null,
          timestamp: null,
        },
        totalEngineFuelConsumed: null,
        totalWeightLoad1: null,
        totalWeightLoad2: null,
        totalWeightLoad3: null,
        totalWeightLoad4: null,
        totalHoursOfEngineOperation: null,
        totalHoursOfCrusherOperation: null,
        totalHoursOfExternalPowerSupply: null,
        totalHoursOfInternalPowerSupply: null,
        co2Saved: null,
        dieselSaved: null,
        energySaved: null,
        co2Emission: null,
        suggestedAddress: '',
        isCreatingPdf: false,
      };
    case UPDATE_GRAPH_TICK_VALUES:
      return {
        ...state,
        graphTickValues: action.payload.graphTickValues,
        graphDateFormat: action.payload.graphDateFormat,
      };
    default:
      return state;
  }
}
