/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    OrganizationDto,
    OrganizationDtoFromJSON,
    OrganizationDtoToJSON,
} from '../models';

export interface ApiOrganizationsIdDeleteRequest {
    id: string;
}

export interface ApiOrganizationsIdGetRequest {
    id: string;
}

export interface ApiOrganizationsIdPutRequest {
    id: number;
    organizationDto?: OrganizationDto;
}

export interface ApiOrganizationsPostRequest {
    organizationDto?: OrganizationDto;
}

/**
 * no description
 */
export class OrganizationsApi extends runtime.BaseAPI {

    /**
     */
    async apiOrganizationsGetRaw(): Promise<runtime.ApiResponse<Array<OrganizationDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Organizations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OrganizationDtoFromJSON));
    }

    /**
     */
    async apiOrganizationsGet(): Promise<Array<OrganizationDto>> {
        const response = await this.apiOrganizationsGetRaw();
        return await response.value();
    }

    /**
     */
    async apiOrganizationsIdDeleteRaw(requestParameters: ApiOrganizationsIdDeleteRequest): Promise<runtime.ApiResponse<OrganizationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrganizationsIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiOrganizationsIdDelete(requestParameters: ApiOrganizationsIdDeleteRequest): Promise<OrganizationDto> {
        const response = await this.apiOrganizationsIdDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiOrganizationsIdGetRaw(requestParameters: ApiOrganizationsIdGetRequest): Promise<runtime.ApiResponse<OrganizationDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrganizationsIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiOrganizationsIdGet(requestParameters: ApiOrganizationsIdGetRequest): Promise<OrganizationDto> {
        const response = await this.apiOrganizationsIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiOrganizationsIdPutRaw(requestParameters: ApiOrganizationsIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiOrganizationsIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Organizations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationDtoToJSON(requestParameters.organizationDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiOrganizationsIdPut(requestParameters: ApiOrganizationsIdPutRequest): Promise<void> {
        await this.apiOrganizationsIdPutRaw(requestParameters);
    }

    /**
     */
    async apiOrganizationsPostRaw(requestParameters: ApiOrganizationsPostRequest): Promise<runtime.ApiResponse<OrganizationDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Organizations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrganizationDtoToJSON(requestParameters.organizationDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganizationDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiOrganizationsPost(requestParameters: ApiOrganizationsPostRequest): Promise<OrganizationDto> {
        const response = await this.apiOrganizationsPostRaw(requestParameters);
        return await response.value();
    }

}
