import React, { useEffect } from 'react';
import EnhancedTable from './EnhancedTable';
import { useSelector, useDispatch } from 'react-redux';
import { CircularProgress, Grid, makeStyles, Theme, createStyles } from '@material-ui/core';
import RubbleBreadcrumbs from '../RubbleBreadcrumbs';
import { routes } from '../../constants';
import { IReduxState } from '../../types';
import { getAllMachines } from '../../actions/machineActions';
import { handleUserIdle } from '../../actions/authActions';
import { useIdle } from '../useIdle';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(4),
      margin: `${theme.spacing(4)}px auto`,
      position: 'relative',
      maxWidth: '1960px',
    },
  })
);

export default function RubbleList() {
  const classes = useStyles();
  const isFetchingAllMachines = useSelector((state: IReduxState) => state.machines.isFetchingAllMachines);
  const dispatch = useDispatch();

  const isIdle = useIdle();

  console.log("******************************************");
  console.log("In RubbleList");
  console.log("******************************************");

  // Get all machines on mount
  useEffect(() => {
    dispatch(getAllMachines());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(handleUserIdle(isIdle));
  }, [isIdle, dispatch]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        <RubbleBreadcrumbs current={routes.list.pathName} />
      </div>
      <Grid container direction="column" justify="center" alignItems="center">
        {isFetchingAllMachines ? <CircularProgress color="primary" /> : <EnhancedTable startRowsPerPage={10} />}
      </Grid>
    </React.Fragment>
  );
}
