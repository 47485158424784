import { MachineTypeDto } from '../../rubblemaster_openapi3_typescript-fetch/models';
import defaultImg from '../../assets/img/DefaultRM.png';

export function MachineImage(machineType: MachineTypeDto, isHybrid: boolean) {
  if (machineType) {
    if (isHybrid) {
      return machineType?.hybridImage ?? defaultImg;
    }
    return machineType?.normalImage ?? defaultImg;
  }
  return defaultImg;
}
