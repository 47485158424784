import {
  FETCH_ALL_MACHINES_REQUEST,
  FETCH_ALL_MACHINES_SUCCESS,
  FETCH_ALL_MACHINES_FAIL,
  UPDATE_FILTERED_MACHINES,
  SEARCH_MACHINES,
  FETCH_MACHINE_ADDRESS_SUCCESS,
  FETCH_MACHINE_ADDRESS_FAIL,
  FETCH_MACHINE_DETAILS_SUCCESS,
} from '../actions/types';
import { IReduxMachines, IMachine } from '../types';

const initialState: IReduxMachines = {
  isFetchingAllMachines: false,
  hasFetchedAllMachines: false,
  allMachines: [],
  filteredMachines: [],
};

export default function(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_ALL_MACHINES_REQUEST:
      return {
        ...state,
        isFetchingAllMachines: true,
      };
    case FETCH_ALL_MACHINES_SUCCESS:
      return {
        ...state,
        hasFetchedAllMachines: true,
        isFetchingAllMachines: false,
        allMachines: action.payload,
        filteredMachines: action.payload,
      };
    case FETCH_ALL_MACHINES_FAIL:
      return {
        ...state,
        isFetchingAllMachines: false,
      };
    case SEARCH_MACHINES:
      return {
        ...state,
        filteredMachines: [],
      };
    case UPDATE_FILTERED_MACHINES:
      return {
        ...state,
        filteredMachines: action.payload,
      };
    case FETCH_MACHINE_ADDRESS_SUCCESS:
      const updatedAllMachines = state.allMachines.map((item: IMachine) => {
        if (item.id === action.payload.machineId) {
          return {
            ...item,
            suggestedAddress: action.payload.suggestedAddress,
          };
        } else {
          return item;
        }
      });
      const updatedFilteredMachines = state.filteredMachines.map(
        (item: IMachine) => {
          if (item.id === action.payload.machineId) {
            return {
              ...item,
              suggestedAddress: action.payload.suggestedAddress,
            };
          } else {
            return item;
          }
        }
      );
      return {
        ...state,
        allMachines: updatedAllMachines,
        filteredMachines: updatedFilteredMachines,
      };
    case FETCH_MACHINE_ADDRESS_FAIL:
      const notFound = 'notFound';
      return {
        ...state,
        allMachines: state.allMachines.map((item: IMachine) => {
          if (item.id === action.payload.machineId) {
            return {
              ...item,
              suggestedAddress: notFound,
            };
          } else {
            return item;
          }
        }),
        filteredMachines: state.filteredMachines.map((item: IMachine) => {
          if (item.id === action.payload.machineId) {
            return {
              ...item,
              suggestedAddress: notFound,
            };
          } else {
            return item;
          }
        }),
      };
    case FETCH_MACHINE_DETAILS_SUCCESS:
      return {
        ...state,
        allMachines: state.allMachines.map((item: IMachine) => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        }),
        filteredMachines: state.filteredMachines.map((item: IMachine) => {
          if (item.id === action.payload.id) {
            return action.payload;
          } else {
            return item;
          }
        }),
      };
    default:
      return state;
  }
}
