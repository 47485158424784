/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    StateDto,
    StateDtoFromJSON,
    StateDtoToJSON,
} from '../models';

export interface ApiStatesIdDeleteRequest {
    id: number;
}

export interface ApiStatesIdGetRequest {
    id: number;
}

export interface ApiStatesIdPutRequest {
    id: number;
    stateDto?: StateDto;
}

export interface ApiStatesPostRequest {
    stateDto?: StateDto;
}

/**
 * no description
 */
export class StatesApi extends runtime.BaseAPI {

    /**
     */
    async apiStatesGetRaw(): Promise<runtime.ApiResponse<Array<StateDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/States`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StateDtoFromJSON));
    }

    /**
     */
    async apiStatesGet(): Promise<Array<StateDto>> {
        const response = await this.apiStatesGetRaw();
        return await response.value();
    }

    /**
     * Deletes a StateType for the given Id. If the StateType Id is used for any Machines then the deletion will fail. HTTP422 will be returned. If the Id does not exist HTTP404 will be returned.
     */
    async apiStatesIdDeleteRaw(requestParameters: ApiStatesIdDeleteRequest): Promise<runtime.ApiResponse<StateDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStatesIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/States/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StateDtoFromJSON(jsonValue));
    }

    /**
     * Deletes a StateType for the given Id. If the StateType Id is used for any Machines then the deletion will fail. HTTP422 will be returned. If the Id does not exist HTTP404 will be returned.
     */
    async apiStatesIdDelete(requestParameters: ApiStatesIdDeleteRequest): Promise<StateDto> {
        const response = await this.apiStatesIdDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiStatesIdGetRaw(requestParameters: ApiStatesIdGetRequest): Promise<runtime.ApiResponse<StateDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStatesIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/States/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StateDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStatesIdGet(requestParameters: ApiStatesIdGetRequest): Promise<StateDto> {
        const response = await this.apiStatesIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiStatesIdPutRaw(requestParameters: ApiStatesIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiStatesIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/States/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: StateDtoToJSON(requestParameters.stateDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiStatesIdPut(requestParameters: ApiStatesIdPutRequest): Promise<void> {
        await this.apiStatesIdPutRaw(requestParameters);
    }

    /**
     */
    async apiStatesPostRaw(requestParameters: ApiStatesPostRequest): Promise<runtime.ApiResponse<StateDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/States`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StateDtoToJSON(requestParameters.stateDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StateDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiStatesPost(requestParameters: ApiStatesPostRequest): Promise<StateDto> {
        const response = await this.apiStatesPostRaw(requestParameters);
        return await response.value();
    }

}
