import React, { useState } from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateSearchText, searchMachines } from '../../actions/searchActions';
import { IReduxState, IMachine } from '../../types';
import { RESET_MAP_CENTER } from '../../actions/types';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      color: 'inherit',
      padding: theme.spacing(1, 1, 1, 7),
      transition: theme.transitions.create('width'),
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: 300,
      },
    },
  })
);

interface IProps {
  mobile?: boolean;
}

export default function AutoCompleteSearch(props: IProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const searchText = useSelector(
    (state: IReduxState) => state.search.searchText
  );
  const autoCompleteOptions = useSelector(
    (state: IReduxState) => state.machines.allMachines
  );
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const handleEnter = (event: any) => {
    if (event.key === 'Enter') {
      setIsOpen(false);
      dispatch(searchMachines());
    }
  };
  const handleClear = () => {
    dispatch(updateSearchText(''));
    dispatch(searchMachines());
    dispatch({ type: RESET_MAP_CENTER });
  };

  let stringOptions = autoCompleteOptions
    .map((m: IMachine) => m.owner)
    .concat(autoCompleteOptions.map((m: IMachine) => m.serialNumber))
    .filter((i) => i !== null);
  let uniqueStringOptions = [...new Set(stringOptions)];
  return (
    <Autocomplete
      freeSolo
      options={uniqueStringOptions}
      popupIcon={false}
      inputValue={searchText}
      multiple={undefined}
      getOptionLabel={(option: string) => option}
      style={{ width: '100%', paddingRight: 0 }}
      open={searchText === '' || !isOpen ? false : true}
      onClose={() => setIsOpen(false)}
      onChange={(event: React.ChangeEvent<{}>, value: string | null) => {
        handleEnter(event);
        if (value === null) {
          handleClear();
        }
        if (value !== null) {
          dispatch(updateSearchText(value));
          dispatch(searchMachines());
        }
      }}
      filterOptions={(options: string[]) => {
        let ownerRegex = RegExp(
          '^' + searchText.toLowerCase() + '|\\s' + searchText.toLowerCase(),
          'g'
        );

        const filteredOptions = options.filter((item: string) => {
          return (
            item.toLowerCase().includes(searchText.toLowerCase()) ||
            ownerRegex.test(item.toLowerCase())
          );
        });
        return filteredOptions;
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={props.mobile ? '' : classes.inputRoot}
          variant={props.mobile ? 'outlined' : 'standard'}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(updateSearchText(event.target.value));
            setIsOpen(true);
          }}
          value={searchText}
          fullWidth
          placeholder={t('search.inputPlaceholder')}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end">
                {searchText && searchText.length ? (
                  <IconButton
                    title={t('controls.clear')}
                    aria-label={t('controls.clear')}
                    onClick={handleClear}
                  >
                    <ClearIcon />
                  </IconButton>
                ) : (
                  ''
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
