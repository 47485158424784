/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccessLevelDto,
    AccessLevelDtoFromJSON,
    AccessLevelDtoFromJSONTyped,
    AccessLevelDtoToJSON,
    MachineTypeDto,
    MachineTypeDtoFromJSON,
    MachineTypeDtoFromJSONTyped,
    MachineTypeDtoToJSON,
    NextServiceDto,
    NextServiceDtoFromJSON,
    NextServiceDtoFromJSONTyped,
    NextServiceDtoToJSON,
    StateDto,
    StateDtoFromJSON,
    StateDtoFromJSONTyped,
    StateDtoToJSON,
    TagDto,
    TagDtoFromJSON,
    TagDtoFromJSONTyped,
    TagDtoToJSON,
    TelemetrySnapshotDto,
    TelemetrySnapshotDtoFromJSON,
    TelemetrySnapshotDtoFromJSONTyped,
    TelemetrySnapshotDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface MachineDto
 */
export interface MachineDto {
    /**
     * 
     * @type {number}
     * @memberof MachineDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MachineDto
     */
    serialNumber: string;
    /**
     * 
     * @type {string}
     * @memberof MachineDto
     */
    notes: string;
    /**
     * 
     * @type {number}
     * @memberof MachineDto
     */
    organizationId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof MachineDto
     */
    machineTypeId: number;
    /**
     * 
     * @type {MachineTypeDto}
     * @memberof MachineDto
     */
    machineType: MachineTypeDto;
    /**
     * 
     * @type {string}
     * @memberof MachineDto
     */
    owner: string;
    /**
     * 
     * @type {string}
     * @memberof MachineDto
     */
    projectConstructionSite: string;
    /**
     * 
     * @type {StateDto}
     * @memberof MachineDto
     */
    state: StateDto;
    /**
     * 
     * @type {string}
     * @memberof MachineDto
     */
    stateText: string;
    /**
     * 
     * @type {Array<TagDto>}
     * @memberof MachineDto
     */
    tags?: Array<TagDto> | null;
    /**
     * 
     * @type {boolean}
     * @memberof MachineDto
     */
    useBlacklist: boolean;
    /**
     * 
     * @type {string}
     * @memberof MachineDto
     */
    errorBlackList: string;
    /**
     * 
     * @type {AccessLevelDto}
     * @memberof MachineDto
     */
    currentlyAuthenticatedUserAccessLevel: AccessLevelDto;
    /**
     * 
     * @type {TelemetrySnapshotDto}
     * @memberof MachineDto
     */
    telemetrySnapshot?: TelemetrySnapshotDto | null;
    /**
     * 
     * @type {NextServiceDto}
     * @memberof MachineDto
     */
    nextService?: NextServiceDto | null;
    /**
     * 
     * @type {string}
     * @memberof MachineDto
     */
    info: string;
    /**
     * 
     * @type {string}
     * @memberof MachineDto
     */
    logInfo: string;
}

export function MachineDtoFromJSON(json: any): MachineDto {
    return MachineDtoFromJSONTyped(json, false);
}

export function MachineDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'serialNumber': json['serialNumber'],
        'notes': json['notes'],
        'organizationId': !exists(json, 'organizationId') ? undefined : json['organizationId'],
        'machineTypeId': json['machineTypeId'],
        'machineType': MachineTypeDtoFromJSON(json['machineType']),
        'owner': json['owner'],
        'projectConstructionSite': json['projectConstructionSite'],
        'state': StateDtoFromJSON(json['state']),
        'stateText': json['stateText'],
        'tags': !exists(json, 'tags') ? undefined : (json['tags'] === null ? null : (json['tags'] as Array<any>).map(TagDtoFromJSON)),
        'useBlacklist': json['useBlacklist'],
        'errorBlackList': json['errorBlackList'],
        'currentlyAuthenticatedUserAccessLevel': AccessLevelDtoFromJSON(json['currentlyAuthenticatedUserAccessLevel']),
        'telemetrySnapshot': !exists(json, 'telemetrySnapshot') ? undefined : TelemetrySnapshotDtoFromJSON(json['telemetrySnapshot']),
        'nextService': !exists(json, 'nextService') ? undefined : NextServiceDtoFromJSON(json['nextService']),
        'info': json['info'],
        'logInfo': json['logInfo'],
    };
}

export function MachineDtoToJSON(value?: MachineDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'serialNumber': value.serialNumber,
        'notes': value.notes,
        'organizationId': value.organizationId,
        'machineTypeId': value.machineTypeId,
        'machineType': MachineTypeDtoToJSON(value.machineType),
        'owner': value.owner,
        'projectConstructionSite': value.projectConstructionSite,
        'state': StateDtoToJSON(value.state),
        'stateText': value.stateText,
        'tags': value.tags === undefined ? undefined : (value.tags === null ? null : (value.tags as Array<any>).map(TagDtoToJSON)),
        'useBlacklist': value.useBlacklist,
        'errorBlackList': value.errorBlackList,
        'currentlyAuthenticatedUserAccessLevel': AccessLevelDtoToJSON(value.currentlyAuthenticatedUserAccessLevel),
        'telemetrySnapshot': TelemetrySnapshotDtoToJSON(value.telemetrySnapshot),
        'nextService': NextServiceDtoToJSON(value.nextService),
        'info': value.info,
        'logInfo': value.logInfo,
    };
}


