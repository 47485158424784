import React from 'react';
import { Container } from '@material-ui/core';
import AutoCompleteSearch from './AutoCompleteSearch';

export default function SearchTextInput() {
  return (
    <Container maxWidth="sm">
      <AutoCompleteSearch mobile />
    </Container>
  );
}
