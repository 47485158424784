import React from 'react';
import { IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import DirectionsIcon from '@material-ui/icons/Directions';
import { IMachineDetailsProps } from '../../types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  // margin: `${theme.spacing(2)}px`,
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: 'auto',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
  },
}));

export default function NavigateToLocation(props: IMachineDetailsProps) {
  const classes = useStyles();
  const { machine } = props;
  const { t } = useTranslation();
  const handleNavigate = () => {
    const url = `https://www.google.com/maps/dir/?api=1&destination=${machine.telemetrySnapshot?.location?.latitude},${machine.telemetrySnapshot?.location?.longitude}`;
    window.open(url);
  };
  return (
    <div className={classes.root}>
      <div className={classes.iconWrapper}>
        <IconButton
          aria-label="directions"
          onClick={handleNavigate}
          title="open directions"
        >
          <DirectionsIcon color="action" />
        </IconButton>
      </div>
      <Typography
        align="center"
        variant="caption"
        color="textSecondary"
        component="p"
      >
        {t('machine.directions')}
      </Typography>
    </div>
  );
}
