import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function SkeletonLoaderGraph() {
  return (
    <div style={{ margin: '16px' }}>
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="text" animation="wave" />
      <Skeleton variant="rect" animation="wave" height={400} />
      <Skeleton variant="text" animation="wave" />
    </div>
  );
}
