import React from 'react';
import { FormLabel, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { useDispatch, useSelector } from "react-redux";
import { updateSearchIgnitionOn, updateSearchIgnitionOff } from '../../actions/searchActions';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IReduxState } from '../../types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(1)}px 0`
  },
}));

export default function IgnitionCheckboxes() {
  const dispatch = useDispatch();
  const checkboxIgnitionOn = useSelector((state: IReduxState) => state.search.checkboxIgnitionOn);
  const checkboxIgnitionOff = useSelector((state: IReduxState) => state.search.checkboxIgnitionOff);
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
    <FormLabel component="legend">{t('search.ignitionStatus')}</FormLabel>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxIgnitionOn}
              onChange={() => dispatch(updateSearchIgnitionOn())}
              color="primary"
              name="ignition-on"
            />
          }
          label={t('search.checkboxOn')}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxIgnitionOff}
              onChange={() => dispatch(updateSearchIgnitionOff())}
              color="primary"
              name="igntion-off"
            />
          }
          label={t('search.checkboxOff')}
        />
      </FormGroup>
    </div>
  )
}
