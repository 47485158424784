import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, makeStyles, Theme } from '@material-ui/core';
import RubbleBreadcrumbs from '../RubbleBreadcrumbs';
import { routes } from '../../constants';
import { handleUserIdle } from '../../actions/authActions';
import { useIdle } from '../useIdle';
import { digEcoSysYouTubeUrl } from '../../constants/index';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
  },
  fullHeight: {
    minHeight: 'calc(100vH - 88px)',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vH - 96px)',
    },
  },
  pageTitle: {
    marginTop: theme.spacing(2),
  },
  content: {},
  container: {
    paddingLeft: theme.spacing(4),
    margin: `${theme.spacing(4)}px auto`,
    position: 'relative',
    maxWidth: '1960px',
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
  },
  player: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
}));

export default function Welcome() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isIdle = useIdle();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(handleUserIdle(isIdle));
  }, [isIdle, dispatch]);

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <RubbleBreadcrumbs home={routes.portal.pathName} current={routes.welcome.pathName} />
      </Container>
      <Container maxWidth="lg">
        <div className={classes.playerWrapper}>
          <iframe
            className={classes.player}
            title={t('welcome.videoTitle')}
            width="100%"
            height="100%"
            src={digEcoSysYouTubeUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      </Container>
    </div>
  );
}
