/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';

export interface AzureADB2CAccountEditProfileSchemeGetRequest {
    scheme: string;
}

export interface AzureADB2CAccountResetPasswordSchemeGetRequest {
    scheme: string;
}

export interface AzureADB2CAccountSignInSchemeGetRequest {
    scheme: string;
}

export interface AzureADB2CAccountSignOutSchemeGetRequest {
    scheme: string;
}

/**
 * no description
 */
export class AccountApi extends runtime.BaseAPI {

    /**
     */
    async azureADB2CAccountEditProfileSchemeGetRaw(requestParameters: AzureADB2CAccountEditProfileSchemeGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.scheme === null || requestParameters.scheme === undefined) {
            throw new runtime.RequiredError('scheme','Required parameter requestParameters.scheme was null or undefined when calling azureADB2CAccountEditProfileSchemeGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/AzureADB2C/Account/EditProfile/{scheme}`.replace(`{${"scheme"}}`, encodeURIComponent(String(requestParameters.scheme))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async azureADB2CAccountEditProfileSchemeGet(requestParameters: AzureADB2CAccountEditProfileSchemeGetRequest): Promise<void> {
        await this.azureADB2CAccountEditProfileSchemeGetRaw(requestParameters);
    }

    /**
     */
    async azureADB2CAccountResetPasswordSchemeGetRaw(requestParameters: AzureADB2CAccountResetPasswordSchemeGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.scheme === null || requestParameters.scheme === undefined) {
            throw new runtime.RequiredError('scheme','Required parameter requestParameters.scheme was null or undefined when calling azureADB2CAccountResetPasswordSchemeGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/AzureADB2C/Account/ResetPassword/{scheme}`.replace(`{${"scheme"}}`, encodeURIComponent(String(requestParameters.scheme))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async azureADB2CAccountResetPasswordSchemeGet(requestParameters: AzureADB2CAccountResetPasswordSchemeGetRequest): Promise<void> {
        await this.azureADB2CAccountResetPasswordSchemeGetRaw(requestParameters);
    }

    /**
     */
    async azureADB2CAccountSignInSchemeGetRaw(requestParameters: AzureADB2CAccountSignInSchemeGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.scheme === null || requestParameters.scheme === undefined) {
            throw new runtime.RequiredError('scheme','Required parameter requestParameters.scheme was null or undefined when calling azureADB2CAccountSignInSchemeGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/AzureADB2C/Account/SignIn/{scheme}`.replace(`{${"scheme"}}`, encodeURIComponent(String(requestParameters.scheme))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async azureADB2CAccountSignInSchemeGet(requestParameters: AzureADB2CAccountSignInSchemeGetRequest): Promise<void> {
        await this.azureADB2CAccountSignInSchemeGetRaw(requestParameters);
    }

    /**
     */
    async azureADB2CAccountSignOutSchemeGetRaw(requestParameters: AzureADB2CAccountSignOutSchemeGetRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.scheme === null || requestParameters.scheme === undefined) {
            throw new runtime.RequiredError('scheme','Required parameter requestParameters.scheme was null or undefined when calling azureADB2CAccountSignOutSchemeGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/AzureADB2C/Account/SignOut/{scheme}`.replace(`{${"scheme"}}`, encodeURIComponent(String(requestParameters.scheme))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async azureADB2CAccountSignOutSchemeGet(requestParameters: AzureADB2CAccountSignOutSchemeGetRequest): Promise<void> {
        await this.azureADB2CAccountSignOutSchemeGetRaw(requestParameters);
    }

}
