/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MachineDto,
    MachineDtoFromJSON,
    MachineDtoToJSON,
    StateDto,
    StateDtoFromJSON,
    StateDtoToJSON,
} from '../models';

export interface ApiMachinesIdDeleteRequest {
    id: number;
}

export interface ApiMachinesIdGetRequest {
    id: number;
}

export interface ApiMachinesIdPutRequest {
    id: number;
    machineDto?: MachineDto;
}

export interface ApiMachinesMachineIdNotesPutRequest {
    machineId: number;
    notes?: string;
}

export interface ApiMachinesMachineIdProjectConstructionSitePutRequest {
    machineId: number;
    projectConstructionSite?: string;
}

export interface ApiMachinesMachineIdStatePutRequest {
    machineId: number;
    stateId?: number;
}

export interface ApiMachinesPostRequest {
    machineDto?: MachineDto;
}

/**
 * no description
 */
export class MachinesApi extends runtime.BaseAPI {

    /**
     */
    async apiMachinesGetRaw(): Promise<runtime.ApiResponse<Array<MachineDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Machines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineDtoFromJSON));
    }

    /**
     */
    async apiMachinesGet(): Promise<Array<MachineDto>> {
        console.log("apiMachinesGet: start " + new Date().toLocaleTimeString());
        const response = await this.apiMachinesGetRaw();
        console.log("apiMachinesGet: ende " + new Date().toLocaleTimeString());
        const res = await response.value();
        console.log(res);
        return res;
    }

    /**
     */
    async apiMachinesIdDeleteRaw(requestParameters: ApiMachinesIdDeleteRequest): Promise<runtime.ApiResponse<MachineDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiMachinesIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Machines/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiMachinesIdDelete(requestParameters: ApiMachinesIdDeleteRequest): Promise<MachineDto> {
        const response = await this.apiMachinesIdDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiMachinesIdGetRaw(requestParameters: ApiMachinesIdGetRequest): Promise<runtime.ApiResponse<MachineDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiMachinesIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Machines/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiMachinesIdGet(requestParameters: ApiMachinesIdGetRequest): Promise<MachineDto> {
        const response = await this.apiMachinesIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiMachinesIdPutRaw(requestParameters: ApiMachinesIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiMachinesIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Machines/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MachineDtoToJSON(requestParameters.machineDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiMachinesIdPut(requestParameters: ApiMachinesIdPutRequest): Promise<void> {
        await this.apiMachinesIdPutRaw(requestParameters);
    }

    /**
     */
    async apiMachinesMachineIdNotesPutRaw(requestParameters: ApiMachinesMachineIdNotesPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling apiMachinesMachineIdNotesPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.notes !== undefined) {
            queryParameters['notes'] = requestParameters.notes;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Machines/{machineId}/notes`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiMachinesMachineIdNotesPut(requestParameters: ApiMachinesMachineIdNotesPutRequest): Promise<void> {
        await this.apiMachinesMachineIdNotesPutRaw(requestParameters);
    }

    /**
     */
    async apiMachinesMachineIdProjectConstructionSitePutRaw(requestParameters: ApiMachinesMachineIdProjectConstructionSitePutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling apiMachinesMachineIdProjectConstructionSitePut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.projectConstructionSite !== undefined) {
            queryParameters['projectConstructionSite'] = requestParameters.projectConstructionSite;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Machines/{machineId}/projectConstructionSite`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiMachinesMachineIdProjectConstructionSitePut(requestParameters: ApiMachinesMachineIdProjectConstructionSitePutRequest): Promise<void> {
        await this.apiMachinesMachineIdProjectConstructionSitePutRaw(requestParameters);
    }

    /**
     */
    async apiMachinesMachineIdStatePutRaw(requestParameters: ApiMachinesMachineIdStatePutRequest): Promise<runtime.ApiResponse<StateDto>> {
        if (requestParameters.machineId === null || requestParameters.machineId === undefined) {
            throw new runtime.RequiredError('machineId','Required parameter requestParameters.machineId was null or undefined when calling apiMachinesMachineIdStatePut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.stateId !== undefined) {
            queryParameters['stateId'] = requestParameters.stateId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Machines/{machineId}/state`.replace(`{${"machineId"}}`, encodeURIComponent(String(requestParameters.machineId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StateDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiMachinesMachineIdStatePut(requestParameters: ApiMachinesMachineIdStatePutRequest): Promise<StateDto> {
        const response = await this.apiMachinesMachineIdStatePutRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiMachinesPostRaw(requestParameters: ApiMachinesPostRequest): Promise<runtime.ApiResponse<MachineDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Machines`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineDtoToJSON(requestParameters.machineDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MachineDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiMachinesPost(requestParameters: ApiMachinesPostRequest): Promise<MachineDto> {
        const response = await this.apiMachinesPostRaw(requestParameters);
        return await response.value();
    }

}
