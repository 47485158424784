/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface NextServiceDto
 */
export interface NextServiceDto {
    /**
     * 
     * @type {string}
     * @memberof NextServiceDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof NextServiceDto
     */
    workingHoursTillService?: number;
}

export function NextServiceDtoFromJSON(json: any): NextServiceDto {
    return NextServiceDtoFromJSONTyped(json, false);
}

export function NextServiceDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): NextServiceDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'workingHoursTillService': !exists(json, 'workingHoursTillService') ? undefined : json['workingHoursTillService'],
    };
}

export function NextServiceDtoToJSON(value?: NextServiceDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'workingHoursTillService': value.workingHoursTillService,
    };
}


