/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum AccessLevelDto {
    None = 'None',
    RestrictedRead = 'RestrictedRead',
    Read = 'Read',
    Write = 'Write'
}

export function AccessLevelDtoFromJSON(json: any): AccessLevelDto {
    return AccessLevelDtoFromJSONTyped(json, false);
}

export function AccessLevelDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccessLevelDto {
    return json as AccessLevelDto;
}

export function AccessLevelDtoToJSON(value?: AccessLevelDto | null): any {
    return value as any;
}

