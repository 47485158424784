/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    TagDto,
    TagDtoFromJSON,
    TagDtoToJSON,
} from '../models';

export interface ApiTagsIdDeleteRequest {
    id: number;
}

export interface ApiTagsIdGetRequest {
    id: number;
}

export interface ApiTagsIdPutRequest {
    id: number;
    tagDto?: TagDto;
}

export interface ApiTagsPostRequest {
    tagDto?: TagDto;
}

/**
 * no description
 */
export class TagsApi extends runtime.BaseAPI {

    /**
     */
    async apiTagsGetRaw(): Promise<runtime.ApiResponse<Array<TagDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Tags`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(TagDtoFromJSON));
    }

    /**
     */
    async apiTagsGet(): Promise<Array<TagDto>> {
        const response = await this.apiTagsGetRaw();
        return await response.value();
    }

    /**
     * Deletes a Tag for the given Id. If the Tag Id is used for any Machines then the deletion will fail. HTTP422 will be returned. If the Id does not exist HTTP404 will be returned.
     */
    async apiTagsIdDeleteRaw(requestParameters: ApiTagsIdDeleteRequest): Promise<runtime.ApiResponse<TagDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTagsIdDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDtoFromJSON(jsonValue));
    }

    /**
     * Deletes a Tag for the given Id. If the Tag Id is used for any Machines then the deletion will fail. HTTP422 will be returned. If the Id does not exist HTTP404 will be returned.
     */
    async apiTagsIdDelete(requestParameters: ApiTagsIdDeleteRequest): Promise<TagDto> {
        const response = await this.apiTagsIdDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiTagsIdGetRaw(requestParameters: ApiTagsIdGetRequest): Promise<runtime.ApiResponse<TagDto>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTagsIdGet.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTagsIdGet(requestParameters: ApiTagsIdGetRequest): Promise<TagDto> {
        const response = await this.apiTagsIdGetRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiTagsIdPutRaw(requestParameters: ApiTagsIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiTagsIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Tags/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TagDtoToJSON(requestParameters.tagDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiTagsIdPut(requestParameters: ApiTagsIdPutRequest): Promise<void> {
        await this.apiTagsIdPutRaw(requestParameters);
    }

    /**
     */
    async apiTagsPostRaw(requestParameters: ApiTagsPostRequest): Promise<runtime.ApiResponse<TagDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Tags`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TagDtoToJSON(requestParameters.tagDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => TagDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiTagsPost(requestParameters: ApiTagsPostRequest): Promise<TagDto> {
        const response = await this.apiTagsPostRaw(requestParameters);
        return await response.value();
    }

}
