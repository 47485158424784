/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    BackendInfoDto,
    BackendInfoDtoFromJSON,
    BackendInfoDtoToJSON,
    CurrentUserDto,
    CurrentUserDtoFromJSON,
    CurrentUserDtoToJSON,
} from '../models';

export interface ApiSessionLoginGetRequest {
    returnUrl?: string;
}

export interface ApiSessionLogoutGetRequest {
    returnUrl?: string;
}

/**
 * no description
 */
export class SessionApi extends runtime.BaseAPI {

    /**
     */
    async apiSessionBackendinfoGetRaw(): Promise<runtime.ApiResponse<BackendInfoDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Session/backendinfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => BackendInfoDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiSessionBackendinfoGet(): Promise<BackendInfoDto> {
        const response = await this.apiSessionBackendinfoGetRaw();
        return await response.value();
    }

    /**
     */
    async apiSessionLoginGetRaw(requestParameters: ApiSessionLoginGetRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.returnUrl !== undefined) {
            queryParameters['returnUrl'] = requestParameters.returnUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Session/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSessionLoginGet(requestParameters: ApiSessionLoginGetRequest): Promise<void> {
        await this.apiSessionLoginGetRaw(requestParameters);
    }

    /**
     */
    async apiSessionLogoutGetRaw(requestParameters: ApiSessionLogoutGetRequest): Promise<runtime.ApiResponse<void>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.returnUrl !== undefined) {
            queryParameters['returnUrl'] = requestParameters.returnUrl;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Session/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiSessionLogoutGet(requestParameters: ApiSessionLogoutGetRequest): Promise<void> {
        await this.apiSessionLogoutGetRaw(requestParameters);
    }

    /**
     */
    async apiSessionUserGetRaw(): Promise<runtime.ApiResponse<CurrentUserDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/Session/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => CurrentUserDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiSessionUserGet(): Promise<CurrentUserDto> {
        const response = await this.apiSessionUserGetRaw();
        return await response.value();
    }

}
