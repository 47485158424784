import React from 'react';
import { Button, makeStyles, Theme } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { useDispatch } from 'react-redux';
import { searchMachines } from '../../actions/searchActions';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: `${theme.spacing(1)}px 0`
  },
  searchButton: {
    padding: "6px 12px"
  }
}),
);

export default function SearchButton() {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div className={classes.root}>
      <Button 
        className={classes.searchButton}
        variant="contained"
        color="primary"
        onClick={() => dispatch(searchMachines())}
      >
        <SearchIcon />
      </Button>
    </div>
  );
}
