import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

export const themeColors = {
  darkGray: '#30333c',
  lightGray: '#D9D9D9',
  black: '#000000',
  yellow: '#FFEB3B', // 500
  red: '#f44336',
  green: '#4caf50',
  white: 'rgba(255, 255, 255, 1)',
  petrol100: 'rgba(81, 101, 111, 1)',
  petrol20: 'rgba(81, 101, 111, 0.2)',
  petrol15: 'rgba(81, 101, 111, 0.15)',
  petrol10: 'rgba(81, 101, 111, 0.1)',
  gray: 'rgba(217, 218, 291, 1)',
  blueGray: '#B0BEC5',
};

// based on SAP https://experience.sap.com/fiori-design-web/values-and-names/ and tablau
// export const chartColors = {
//   blue: '#4e79a7',
//   green1: '#59a14f',
//   brown: '#9c755f',
//   orange: '#f28e2b',
//   yellow1: '#fd0',
//   yellow2: '#edc948',
//   gray: '#bab0ac',
//   red: '#e15759',
//   purple: '#b07aa1',
//   green2: '#76b7b2',
//   pink: '#ff9da7',
//   white: '#FFF',
// }

// based on material ui [200]: https://material.io/design/color/the-color-system.html
export const chartColors = {
  red: '#EF9A9A',
  pink: '#F48FB1',
  purple: '#CE93D8',
  deepPurple: '#B39DDB',
  indigo: '#9FA8DA',
  blue: '#90CAF9',
  lightBlue: '#81D4FA',
  //cyan: '#80DEEA',
  cyan: '#B39DDB',
  teal: '#80CBC4',
  green: '#A5D6A7',
  lightGreen: '#C5E1A5',
  lime: '#E6EE9C',
  yellow: '#FFEB3B', // 500
  rmYellow: '#fd0', // RM yellow
  amber: '#FFE082',
  orange: '#FFCC80',
  deepOrange: '#FFAB91',
  brown: '#BCAAA4',
  gray: '#EEEEEE',
  blueGray: '#B0BEC5',
  white: '#FFFFFF',
  black: '#000000',
  electricBlue: '#51D6E7',
};

export const rubbleMasterTheme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      type: 'dark',
      text: {
        primary: themeColors.white,
        secondary: 'rgba(255, 255, 255, 0.7)',
        disabled: themeColors.gray,
      },
      action: {
        active: themeColors.yellow,
        hover: 'rgba(255, 255, 255, 0.08)',
        selected: themeColors.yellow,
        disabled: 'rgba(255, 255, 255, 0.08)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
      },
      background: {
        default: '#303030',
        paper: '#424242',
      },
      primary: {
        main: themeColors.yellow,
      },
      secondary: {
        main: themeColors.darkGray,
      },
    },
    overrides: {
      MuiMenuItem: {
        root: {
          '&$selected': {
            backgroundColor: themeColors.yellow,
          },
        },
      },
      MuiCardHeader: {
        root: {
          textAlign: 'left',
        },
      },
    },
  })
);

export const mapStyleDark = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#181818',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1b1b1b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#2c2c2c',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a8a8a',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#373737',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3c3c3c',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [
      {
        color: '#4e4e4e',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3d3d3d',
      },
    ],
  },
];
