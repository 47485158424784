import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Theme,
  Chip,
  ListItem,
  List,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  IReduxState,
  IExtendedMachineTagOption,
  AccessLevel,
} from '../../types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditMachineTags from './EditMachineTags';
import MachineNotes from './MachineNotes';
import { chartColors, themeColors } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1,
    flexGrow: 1,
  },
  card: {
    [theme.breakpoints.up('xs')]: {
      margin: `${theme.spacing(2)}px 0`,
    },
    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(2)}px`,
      height: 400,
    },
  },
  cardContent: {
    height: '100%',
  },
  actionsArena: {
    paddingTop: 0,
    paddingBottom: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    height: '100%',
    overflowY: 'auto',
    width: '100%',
  },
  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'space-around',
  },
}));

export default function MachineTagsNotes() {
  const classes = useStyles();
  const { t } = useTranslation();
  const machine = useSelector(
    (state: IReduxState) => state.machineDetails.machine
  );
  const machineTagOptions: IExtendedMachineTagOption[] = useSelector(
    (state: IReduxState) => state.search.machineTagOptions
  );
  const isHybrid = useSelector(
    (state: IReduxState) =>
      !!state.machineDetails.machine.telemetrySnapshot?.isHybrid
  );
  const currentlyAuthenticatedUserAccessLevel = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.currentlyAuthenticatedUserAccessLevel
  );
  const [machineTags, setMachineTags] = useState<IExtendedMachineTagOption[]>();

  // console.log("******************************************************");
  // console.log("In MachineTagsNotes");
  // console.log("******************************************************");
  // console.log("currentlyAuthenticatedUserAccessLevel : " + currentlyAuthenticatedUserAccessLevel);

  useEffect(() => {
    const tags = machine.tags;
    const machineTags = tags
      ? tags.map((machineTag: any) => { 
       const color = machineTagOptions.length
            ? machineTagOptions.filter(
                (tagOption: any) => tagOption.id === machineTag.id
              )[0].color
            : themeColors.yellow;
          return {
            id: machineTag.id,
            key: machineTag.key,
            color,
          };
        })
      : [];
    setMachineTags(machineTags);
  }, [machineTagOptions, machine.tags]);

  return (
    <div className={classes.root}>
      <div
        className={classes.card}
        style={{
          boxShadow: isHybrid
            ? `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`
            : 'none',
        }}
      >
        <Card className={classes.cardContent}>
          <CardContent
            className={classes.actionsArena}
            style={{ paddingBottom: 0 }}
          >
            <List className={classes.list}>
              <ListItem dense alignItems="flex-start">
                <div className={classes.title}>
                  <Typography gutterBottom variant="h6">
                    {t('machine.machineTags')}
                  </Typography>
                  { 
                    currentlyAuthenticatedUserAccessLevel === AccessLevel.Write 
                    && <EditMachineTags />
                  }
                </div>
              </ListItem>
              <ListItem>
                <div className={classes.tagsWrapper}>
                  {!!machineTags &&
                    machineTags.map((item: any, index: number) => {
                      return (
                        <Chip
                          variant="outlined"
                          style={{
                            borderWidth: '2px',
                            borderColor: item.color,
                            color: item.color,
                            margin: '4px',
                          }}
                          key={`tag-${index}-${item}`}
                          label={item.key}
                        />
                      );
                    })}
                </div>
              </ListItem>
              <MachineNotes />
            </List>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
