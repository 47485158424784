/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';

/**
 * 
 * @export
 * @interface UploadDto
 */
export interface UploadDto {
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    companyName: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    email: string | null;
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    purpose: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof UploadDto
     */
    description: string;
}

/**
 * 
 * @export
 * @interface UploadResponseDto
 */
export interface UploadResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UploadResponseDto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UploadResponseDto
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof UploadResponseDto
     */
    statusCode: number;
    /**
     * 
     * @type {string}
     * @memberof UploadResponseDto
     */
    statusText: string;
}


export function UploadDtoFromJSON(json: any): UploadDto {
    return UploadDtoFromJSONTyped(json, false);
}

export function UploadResponseDtoFromJSON(json: any): UploadResponseDto {
    return UploadResponseDtoFromJSONTyped(json, false);
}

export function UploadDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'companyName': json['companyName'],
        'name': json['name'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'purpose': json['purpose'],
        'subject': json['subject'],
        'description': json['description'],
    };
}

export function UploadResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UploadResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'status': json['status'],
        'statusCode': json['statusCode'],
        'statusText': json['statusText'],
    };
}

export function UploadDtoToJSON(value?: UploadDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'companyName': value.companyName,
        'name': value.name,
        'email': value.email,
        'purpose': value.purpose,
        'subject': value.subject,
        'description': value.description,
    };
}

export function UploadResponseDtoToJSON(value?: UploadResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'status': value.status,
        'statusCode': value.statusCode,
        'statusText': value.statusText,
    };
}


