import {
  FETCH_MACHINE_BY_ID_REQUEST,
  FETCH_MACHINE_BY_ID_SUCCESS,
  FETCH_MACHINE_BY_ID_FAIL,
  FETCH_ALL_MACHINES_REQUEST,
  FETCH_ALL_MACHINES_SUCCESS,
  FETCH_ALL_MACHINES_FAIL,
  FETCH_MACHINE_STATE_OPTIONS_REQUEST,
  FETCH_MACHINE_STATE_OPTIONS_SUCCESS,
  FETCH_MACHINE_STATE_OPTIONS_FAIL,
  GET_USER_POSITION_REQUEST,
  GET_USER_POSITION_SUCCESS,
  GET_USER_POSITION_FAIL,
  FETCH_MACHINE_ADDRESS_REQUEST,
  FETCH_MACHINE_ADDRESS_SUCCESS,
  FETCH_MACHINE_ADDRESS_FAIL,
  FETCH_MACHINE_TAG_OPTIONS_REQUEST,
  FETCH_MACHINE_TAG_OPTIONS_SUCCESS,
  FETCH_MACHINE_TAG_OPTIONS_FAIL,
} from './types';
import {
  machinesApi,
  fetchStatusApi,
  googleGeoCodeUrl,
  googleApiKey,
  fetchTagsApi,
  chartColors,
} from '../constants';
import { IReduxState, IMachine } from '../types';
import { resetMachineDetails } from './machineDetailsActions';
import { TagDto } from '../rubblemaster_openapi3_typescript-fetch/models';

export const getAllMachines = () => {
  return (dispatch: any, getState: () => IReduxState) => {
    const machineState = getState().machines;
    dispatch(resetMachineDetails());
    if (
      !machineState.hasFetchedAllMachines &&
      !machineState.isFetchingAllMachines
    ) {
      dispatch(fetchAllMachines());
    }
  };
};

export const getMachineById = (id:number) => {
  return (dispatch: any, getState: () => IReduxState) => {
    const machineState = getState().machines;
    dispatch(resetMachineDetails());
    if (
      !machineState.hasFetchedAllMachines &&
      !machineState.isFetchingAllMachines
    ) {
      dispatch(fetchAllMachines());
    }
  };
};

// *******************************************************************
// laden einer einzelnen Maschine inkl. abhängiger Felder
// *******************************************************************
export const loadMachineById = (id: number) => {
  return (dispatch: any) => {
    dispatch({ type: FETCH_MACHINE_BY_ID_REQUEST });
    dispatch(fetchMachineStateOptions());
    dispatch(fetchMachineTags());

    machinesApi
      .apiMachinesIdGet({id: id})
      .then((response: IMachine) => {
        const machine = {
          response,
          suggestedAddress: null,
          
        };
        return dispatch({
          type: FETCH_MACHINE_BY_ID_SUCCESS,
          payload: machine,
        });
      })
      .catch((err: any) => {
        console.error(err);
        return dispatch({ type: FETCH_MACHINE_BY_ID_FAIL });
      });
  };
};

// *******************************************************************
// laden der Liste der Maschinen inkl. abhängiger Felder
// *******************************************************************
export const fetchAllMachines = () => {
  return (dispatch: any) => {
    dispatch({ type: FETCH_ALL_MACHINES_REQUEST });
    dispatch(fetchMachineStateOptions());
    dispatch(fetchMachineTags());

    machinesApi
      .apiMachinesGet()
      .then((response: IMachine[]) => {
        const machines = response.map((item: IMachine) => {
          return {
            ...item,
            suggestedAddress: null,
          };
        });
        return dispatch({
          type: FETCH_ALL_MACHINES_SUCCESS,
          payload: machines,
        });
      })
      .catch((err: any) => {
        console.error(err);
        return dispatch({ type: FETCH_ALL_MACHINES_FAIL });
      });
  };
};

export const fetchMachineStateOptions = () => {
  return (dispatch: any) => {
    dispatch({ type: FETCH_MACHINE_STATE_OPTIONS_REQUEST });
    fetchStatusApi
      .apiStatesGet()
      .then((statustypes: any) => {
        return dispatch({
          type: FETCH_MACHINE_STATE_OPTIONS_SUCCESS,
          payload: statustypes,
        });
      })
      .catch((err: any) => {
        console.error(err);
        return dispatch({ type: FETCH_MACHINE_STATE_OPTIONS_FAIL });
      });
  };
};

export const getUserPositionRequest = () => {
  return { type: GET_USER_POSITION_REQUEST };
};

export const getUserPostion = (confirmed: boolean, payload: any) => {
  return (dispatch: any) => {
    if (confirmed) {
      dispatch({
        type: GET_USER_POSITION_SUCCESS,
        payload: payload,
      });
    } else {
      dispatch({
        type: GET_USER_POSITION_FAIL,
        payload: payload,
      });
    }
  };
};

export const fetchMachineTags = () => {
  return (dispatch: any) => {
    dispatch({ type: FETCH_MACHINE_TAG_OPTIONS_REQUEST });
    fetchTagsApi
      .apiTagsGet()
      .then((tags: TagDto[]) => {
        const chartColorsKeys = Object.keys(chartColors);
        const tagsWithColor = tags.map((tag: TagDto) => {
          const randomNum: number = Math.floor(
            Math.random() * chartColorsKeys.length
          );
          // @ts-ignore
          const randomColor: string = chartColors[chartColorsKeys[randomNum]];
          return {
            id: tag.id,
            key: tag.key,
            color: randomColor,
          };
        });
        return dispatch({
          type: FETCH_MACHINE_TAG_OPTIONS_SUCCESS,
          payload: tagsWithColor,
        });
      })
      .catch((err: any) => {
        console.error(err);
        return dispatch({ type: FETCH_MACHINE_TAG_OPTIONS_FAIL });
      });
  };
};

export const getSuggestedAddressForMachine = (
  id: number,
  latitude: number,
  longitude: number
) => {
  return (dispatch: any) => {
    dispatch({ type: FETCH_MACHINE_ADDRESS_REQUEST });
    // console.log("**********************************")
    // console.log("In getSuggestedAddressForMachine " + latitude + "/" + longitude);
    // console.log("**********************************")


    fetch(`${googleGeoCodeUrl}${latitude},${longitude}&key=${googleApiKey}&loading=async`)
      .then((res: any) => {
        res
        .json()
        .then((body: any) => {
          // console.log("Body:");
          // console.log(body);

          if (body.status === 'OK') {
            console.log("body.status === 'OK' ");
            // console.log("body.results: ");
            // console.log(body.results);
            // console.log("body: ");
            // console.log(body);

            let result = undefined;
            // zuerst: street_address holen
            const street_address = body.results.find((r: any) =>
              r.types.includes('street_address'));

            // console.log("street_address: ");
            // console.log(street_address);

            if(!!street_address){
              result = street_address.formatted_address;
              // console.log("result:");
              // console.log(result);
            }
            // alternativ: plus_code holen
            if(!result){
              const plus_code = body.results.find((r: any) =>
                r.types.includes('plus_code'));
              // console.log("plus_code: ");
              // console.log(plus_code);

              if(!!plus_code){
                result = plus_code.formatted_address;
              }
            }
            // sonst: 1. Wert holen
            if(!result){
              result = body.results[0].formatted_address;
            }

            dispatch({
              type: FETCH_MACHINE_ADDRESS_SUCCESS,
              payload: {
                machineId: id,
                suggestedAddress: result ?? undefined,
              },
            });
          } else if (body.status === 'ZERO_RESULTS') {
            console.log("(body.status === 'ZERO_RESULTS')");
            dispatch({
              type: FETCH_MACHINE_ADDRESS_SUCCESS,
              payload: {
                machineId: id,
                suggestedAddress: undefined,
              },
            });
          } else {
            //console.log("In else : " + body.status);
            
            // Other status codes will not result in an address, see details:
            // https://developers.google.com/maps/documentation/geocoding/overview#StatusCodes
            console.log("(body.status === " + body.status);

            dispatch({
              type: FETCH_MACHINE_ADDRESS_FAIL,
              payload: {
                machineId: id,
              },
            });
          }
        });
      })
      .catch((err) => {
        console.error('error fetching address', err);
        dispatch({
          type: FETCH_MACHINE_ADDRESS_FAIL,
          payload: {
            machineId: id,
          },
        });
      });
  };
};
