import React from 'react';
import { IPointData } from '../types';
import { chartColors } from '../constants';

interface IProps {
  point: IPointData | null;
}

export const ActivePointLayer = (props: IProps) => {
  const { point } = props;

  if (!point) return null;

  return (
    <g transform={`translate(${point.x},${point.y})`}>
      <circle r={2} fill={chartColors.white} />
    </g>
  );
};
