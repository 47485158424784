import React from 'react';
import { ListItemIcon, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import { useTranslation } from 'react-i18next';

export default function MuiMenuLogin() {
  const { t } = useTranslation();
  return (
    <div>
      <ListItemIcon>
        <LockIcon fontSize="small" />
      </ListItemIcon>
      <Typography variant="inherit">{t('navigation.login')}</Typography>
    </div>
  );
}
