import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import moment from "moment";

moment.updateLocale("en", { week: { dow: 1, doy: 1}})

// https://stackoverflow.com/questions/42308261/how-to-disable-caching-for-i18next-translation-json-files

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    lng: 'en',
    fallbackLng: 'en',
    whitelist: ['en', 'de', 'es', 'fr', 'it'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend:{
      requestOptions: {
        cache: 'no-store',
      },
    }
  });
export default i18n;