import React from 'react';
import { ListItemIcon, Typography } from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import { useTranslation } from 'react-i18next';
import { themeColors } from '../../constants';
import { IRouteProps } from '../../types';

export default function MuiMenuList(props: IRouteProps) {
  const { t } = useTranslation();
    return (
      <div style={props.isActive ? { color: themeColors.yellow } : {}}>
        <ListItemIcon>
          <ListIcon fontSize="small" />
        </ListItemIcon>
        <Typography variant="inherit">{t('navigation.list')}</Typography>
      </div>
    )
}
