import {
  MachineDto,
  StateDto,
  TagDto,
  AccessLevelDto,
  SampledTelemetryDto,
  TelemetryRangeDto,
  ErrorDto,
  TelemetrySnapshotDto,
  UserDto,
  UnitValueDto,
} from '../rubblemaster_openapi3_typescript-fetch/models';

export interface IRouteProps {
  isActive: boolean;
  translationString?: string;
}
export interface IMachine extends MachineDto {
  suggestedAddress?: string | null;
  isHybrid?: boolean; // only for testing
}

export interface IState extends StateDto {}

export interface ITelemetrySnapshot extends TelemetrySnapshotDto {}

export interface IError extends ErrorDto {}

export interface IErrorExtended extends ErrorDto {
  frequency: number;
  group: string;
  description: string;
}

export interface ISampledTelemetry extends SampledTelemetryDto {}

export interface ITelemetryRange extends TelemetryRangeDto {}

export { AccessLevelDto as AccessLevel };

export interface IMachineTagOption extends TagDto {}

export interface IReduxAuthProps {
  isAuthenticated: boolean | null;
  isLoading: boolean | null;
  user: IReduxAuthUserProps | null;
  backendInfo: {
    version: string | null;
  };
}

export interface IReduxAuthUserProps extends UserDto {}

export interface IMachineStateOption {
  id: number;
  key: string;
}

export interface IReduxSearchProps {
  showSearch: boolean;
  showSearchError: boolean;
  searchText: string;
  searchTextValue: string;
  checkboxIgnitionOn: boolean;
  checkboxIgnitionOff: boolean;
  machineStateOptions: IMachineStateOption[];
  extendedMachineStateOptions: IMachineStateOption[];
  selectedMachineState: IMachineStateOption;
  map: {
    panToCenter: {
      lat: number;
      lng: number;
    };
    userConfirmedGeo: boolean;
    userPosition: {
      lat: number;
      lng: number;
    };
    error: string;
  };
  machineTagOptions: IMachineTagOption[];
  selectedMachineTags: IMachineTagOption[];
}

export interface IReduxMachines {
  isFetchingAllMachines: boolean;
  hasFetchedAllMachines: boolean;
  allMachines: IMachine[];
  filteredMachines: IMachine[];
}

export interface IReduxState {
  auth: IReduxAuthProps;
  search: IReduxSearchProps;
  machines: IReduxMachines;
  machineDetails: IReduxStateMachineDetails;
}

export interface IMachineDetailsProps {
  machine: IMachine;
}

export interface IMachineStateOption {
  id: number;
}

export interface IMachineError {
  id: number;
  machineId: number;
  dateTime: Date | string | any;
  errorCode: string;
  frequency?: number;
  group?: string;
  hoursOfCrusherOperation?: number | null;
}

export interface IReduxStateMachineDetailsSearch {
  startDate: Date;
  endDate: Date;
  maxEndDate: Date;
}

export interface IFuel {
  fuelConsumption: number;
  dateTime: Date;
}

export interface IWeight {
  weight: number;
  dateTime: Date;
}

export interface IReduxStateMachineDetails {
  isFetching: boolean;
  updatedAt: Date;
  machine: IMachine;
  search: IReduxStateMachineDetailsSearch;
  suggestedAddress: string;
  isCreatingPdf: boolean;
  isFetchingTelemtry: boolean;
  telemetry: ISampledTelemetry | null;
  errorCodes: IError[] | null;
  graphTickValues: string;
  graphDateFormat: string;
  averageFuelLevels: {
    fuelLevel: number | null;
    adBlueLevel: number | null;
    timestamp: Date | null;
  };
  averageEngineUtilization: {
    value: number | null;
    timestamp: Date | null;
  };
  averageEnergyUtilization: {
    value: number | null;
    timestamp: Date | null;
  };
  averageThroughputs: {
    weightLoad1: UnitValueDto | null;
    weightLoad2: UnitValueDto | null;
    weightLoad3: UnitValueDto | null;
    weightLoad4: UnitValueDto | null;
    timestamp: Date | null;
  };
  averageFuelConsumption: {
    engineFuelRate: UnitValueDto | null;
    mainDischargeConveyorFuelRate: UnitValueDto | null;
    sideDischargeConveyorFuelRate: UnitValueDto | null;
    engineFuelConsumed: UnitValueDto | null;
    timestamp: number | null;
  };
  averageEnergyConsumption: {
    mainDischargeConveyorEnergyRate: UnitValueDto | null;
    sideDischargeConveyorEnergyRate: UnitValueDto | null;
    energyConsumeRate: UnitValueDto | null;
    totalEnergyConsumed: UnitValueDto | null;
    timestamp: number | null;
  };
  totalEngineFuelConsumed: UnitValueDto | null;
  totalWeightLoad1: UnitValueDto | null;
  totalWeightLoad2: UnitValueDto | null;
  totalWeightLoad3: UnitValueDto | null;
  totalWeightLoad4: UnitValueDto | null;
  totalHoursOfEngineOperation: number | null;
  totalHoursOfCrusherOperation: number | null;
  totalHoursOfExternalPowerSupply: number | null;
  totalHoursOfInternalPowerSupply: number | null;
  co2Saved: UnitValueDto | null;
  dieselSaved: UnitValueDto | null;
  energySaved: UnitValueDto | null;
  co2Emission: UnitValueDto | null;
}

export interface IExtendedMachineTagOption extends IMachineTagOption {
  color?: string;
}
