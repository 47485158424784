import * as api from '../rubblemaster_openapi3_typescript-fetch/';
import { AccessLevel } from '../types';
import jsPDF from 'jspdf';
import moment from 'moment';
import logo from '../assets/img/logo.png';
import i18next, { TFunction } from 'i18next';
import flagEnUs from '../assets/icons/en-US.png';
import flagEnGb from '../assets/icons/en-GB.png';
import flagDeAt from '../assets/icons/de-AT.png';
import flagEsEs from '../assets/icons/es-ES.png';
import flagFrFr from '../assets/icons/fr-FR.png';
import flagItIt from '../assets/icons/it-IT.png';
import { UnitValueDto } from '../rubblemaster_openapi3_typescript-fetch/';

export const rubbleMasterHq = {
  lat: +48.254022,
  lng: +14.379771,
};

export const basePath =
  process.env.NODE_ENV === 'production'
    ? window.location.protocol + '//' + window.location.host
    : 'https://localhost:44306';

export const machinesApi = new api.MachinesApi(
  new api.Configuration({ basePath: basePath, credentials: 'include' })
);
export const fetchStatusApi = new api.StatesApi(
  new api.Configuration({ basePath: basePath, credentials: 'include' })
);
export const fetchTagsApi = new api.TagsApi(
  new api.Configuration({ basePath: basePath, credentials: 'include' })
);
export const machineTagsApi = new api.MachineTagsApi(
  new api.Configuration({ basePath: basePath, credentials: 'include' })
);
export const fetchSessionApi = new api.SessionApi(
  new api.Configuration({ basePath: basePath, credentials: 'include' })
);
export const userApi = new api.UsersApi(
  new api.Configuration({ basePath: basePath, credentials: 'include' })
);

export const uploadApi = new api.UploadApi(
  new api.Configuration({ basePath: basePath })
);


export const googleApiKey = 'AIzaSyClV-PmY3CXAd0mrI2gMWJg3w7z02Aln8M';
// ACHTUNG: hier KEIN async! Führt zu F5-Problem
//export const googleMapUrlWithKey = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}&loading=async`;
export const googleMapUrlWithKey = `https://maps.googleapis.com/maps/api/js?key=${googleApiKey}`;
export const googleGeoCodeUrl = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=';

// The linking domains are subdomains. Rewrite is handled on backend.
export const openLinkWithRewrite = (route: string) => {
  console.log("In openLinkWithRewrite : " + route)
  window.location.href = route;
};

export const initialMachineState = {
  id: 0,
  serialNumber: '',
  notes: '',
  owner: '',
  machineTypeId: 0,
  state: {
    id: 0,
    key: '',
  },
  stateText: '',
  isHybrid: true, // only for testing
  projectConstructionSite: '',
  useBlacklist : false,
  errorBlackList: '',
  machineType: {
    id: 0,
    name: '',
    category: '',
  },
  currentlyAuthenticatedUserAccessLevel: AccessLevel.None,
  info: '',
  logInfo: '',
};

export const createPdfReportFooter = (
  pdf: jsPDF,
  serialNumber: string,
  page: TFunction,
  pageNumber: number | null,
  maxPage: number | null
) => {
  pdf
    .setLineWidth(0.25)
    .line(
      16,
      pdf.internal.pageSize.getHeight() - 22,
      pdf.internal.pageSize.getWidth() - 16,
      pdf.internal.pageSize.getHeight() - 22
    )
    .setFontSize(12)
    .setFontType('italic')
    .text(
      `${i18next.t('report.created')}: ${moment().format('DD.MM.YYYY HH:mm')}`,
      16,
      pdf.internal.pageSize.getHeight() - 16
    )
    .text(
      serialNumber,
      pdf.internal.pageSize.getWidth() / 2,
      pdf.internal.pageSize.getHeight() - 16,
      {
        align: 'center',
      }
    )
    .setFontSize(10)
    .text(
      i18next.t('report.accuracyNote'),
      pdf.internal.pageSize.getWidth() / 2,
      pdf.internal.pageSize.getHeight() - 10,
      {
        align: 'center',
      }
    );
  if (pageNumber !== null && maxPage !== null) {
    pdf
      .setFontSize(12)
      .text(
        `${page} ${pageNumber} / ${maxPage}`,
        pdf.internal.pageSize.getWidth() - 32,
        pdf.internal.pageSize.getHeight() - 16
      );
  }
  return pdf;
};

export const createPdfReportHeader = (
  pdf: jsPDF,
  reportTitle: string,
  reportTimeframe: string
) => {
  const logoImg = new Image();
  logoImg.src = logo;
  return pdf
    .setFontSize(20)
    .setFontType('bold')
    .text(reportTitle, 16, 16)
    .setFontSize(12)
    .setFontType('italic')
    .text(reportTimeframe, 16, 24)
    .addImage(
      logoImg,
      'PNG',
      pdf.internal.pageSize.getWidth() - 70 / 2,
      0,
      37.5 / 2,
      46.875 / 2
    )
    .setLineWidth(0.25)
    .line(16, 30, pdf.internal.pageSize.getWidth() - 16, 30);
};

export const createGraphReportsPage = (
  pdf: jsPDF,
  imgLeft: HTMLCanvasElement,
  imgRight: HTMLCanvasElement,
  isMobile: boolean
) => {
  const graphWidth = isMobile ? 80 : 90;
  const smallChartWidth = isMobile ? 65 : 75;
  return pdf
    .addImage(
      imgLeft,
      'JPEG',
      10,
      (pdf.internal.pageSize.getHeight() - 125) / 2,
      smallChartWidth,
      125,
      undefined,
      'FAST'
    )
    .addImage(
      imgRight,
      'JPEG',
      graphWidth,
      (pdf.internal.pageSize.getHeight() - 125) / 2,
      200,
      125,
      undefined,
      'FAST'
    );
};

export const languageOptions: { isoLanguageString: string; img: string }[] = [
  {
    isoLanguageString: 'en-GB',
    img: flagEnGb,
  },
  {
    isoLanguageString: 'en-US',
    img: flagEnUs,
  },
  {
    isoLanguageString: 'de-AT',
    img: flagDeAt,
  },
  {
    isoLanguageString: 'es-ES',
    img: flagEsEs,
  },
  {
    isoLanguageString: 'fr-FR',
    img: flagFrFr,
  },
  {
    isoLanguageString: 'it-IT',
    img: flagItIt,
  },
];

export const pdfUnitsFormatter = (
  unitValue: UnitValueDto | null,
  fallbackUnitTranslation: string,
  noDataTranslation: string
) => {
  if (unitValue !== null && unitValue.value !== undefined) {
    return `${unitValue.value.toFixed(2)} ${unitValue.unit ??
      fallbackUnitTranslation}`;
  }
  return noDataTranslation;
};

export const sensorInterval = {
  ignitionKeyOn: 60,
  engineFuelRate: 60,
  operatingMode: 900,
  percentLoad: 60,
  machineType: 60,
  machineSerialNo: 60,
  gpsLatitude: 60,
  gpsLongitude: 60,
  workingHoursIgnitionOn: 900,
  workingMinutesIgnitionOn: 900,
  workingSecondsIgnitionOn: 900,
  workingMilliSecondsIgnitionOn: 900,
  rmErrorMsg: 900,
  gpsSpeed: 900,
  gpsAccuracy: 900,
  crusherEngineSpeedIst: 60,
  crusherEngineSpeedSoll: 60,
  engineWorkingHoursH: 900,
  engineWorkingHoursMin: 900,
  engineWorkingHoursSec: 900,
  fuelLevel: 900,
  adBlueLevel: 900,
  temperatureCrusherEngine: 900,
  currentLoadCrusherDrive: 60,
  intermediateCircuitVoltage: 60,
  weigher1CurrentDisplayed: 60,
  weigher2CurrentDisplayed: 60,
  weigher3CurrentDisplayed: 60,
  weigher4CurrentDisplayed: 60,
};
