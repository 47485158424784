import React from 'react';
import { Card, CardContent, Typography, CardActions, Button, List, ListItem, IconButton, Grid, ListItemText, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/styles';
import history from '../history'
import ShareMachine from '../ShareMachine';
import { IMachineDetailsProps } from '../../types';
import { InfoWindow } from 'react-google-maps';
import { useTranslation } from 'react-i18next';
import './disableDefaultMap.css';
import { useWindowSize } from '../useWindowSize';
interface IProps {
  onClose: () => void
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    zIndex: 1
  },
  card: {
    boxShadow: 'none',
    width: '250px',
    [theme.breakpoints.up('sm')]: {
      width: '300px'
    },
  },
  listItem: {
    paddingBottom: 0,
  },
  textItem: {
    marginBottom: 0,
  },
  actionsArena: {
    paddingTop: 0,
    paddingBottom: 0
  },
  close: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    zIndex: 1000
  },
  noPadding: {
    padding: 0,
  }
})
);

export default function MachineCard(props: IProps & IMachineDetailsProps) {

  const { machine } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const windowSize = useWindowSize();

  const handleClose = () => {
    props.onClose();
  }

  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    history.push(`/fleetmanagement/machine/${props.machine.id}`);
  }

  const isSmall = windowSize.height !== undefined && windowSize.height < 600;


  return (
    <InfoWindow key={`overlay-${machine.id}`}>
      <div className={classes.root}>
        <div className={classes.card}>
          <Card>
            <div className={classes.close}>
              <IconButton aria-label="settings" onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <CardContent className={classes.actionsArena}>
              <List disablePadding={isSmall}>
                <ListItem className={isSmall ? classes.noPadding : classes.listItem} dense alignItems="flex-start">
                  <ListItemText
                    className={classes.textItem}
                    primary={t('machine.owner')}
                    secondary={
                      <React.Fragment>
                        <Typography variant="body1" color="textSecondary" component="span">
                          {machine.owner}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
                <ListItem className={isSmall ? classes.noPadding : classes.listItem} dense alignItems="flex-start">
                  <ListItemText
                    className={classes.textItem}
                    primary={t('machine.serialNumber')}
                    secondary={
                      <React.Fragment>
                        <Typography variant="body1" color="textSecondary" component="span">
                          {machine.serialNumber}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                </ListItem>
              </List>
            </CardContent>
            <CardActions disableSpacing>
              <Grid container alignItems="center" justify="space-around" direction="row">
                <ShareMachine machine={machine} dense={isSmall} />
                <Button variant="contained" size="small" color="primary"
                  onClick={handleClick}
                  startIcon={<TuneIcon />}>
                  {t('machine.details')}
                </Button>
              </Grid>
            </CardActions>
          </Card>
        </div>
      </div>
    </InfoWindow>
  )
}
