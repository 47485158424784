/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UnitValueDto,
    UnitValueDtoFromJSON,
    UnitValueDtoFromJSONTyped,
    UnitValueDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TelemetryRangeTotalsDto
 */
export interface TelemetryRangeTotalsDto {
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeTotalsDto
     */
    totalEngineFuelConsumed?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeTotalsDto
     */
    totalEnergyConsumed?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeTotalsDto
     */
    totalWeightLoad1?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeTotalsDto
     */
    totalWeightLoad2?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeTotalsDto
     */
    totalWeightLoad3?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeTotalsDto
     */
    totalWeightLoad4?: UnitValueDto | null;
    /**
     * 
     * @type {number}
     * @memberof TelemetryRangeTotalsDto
     */
    totalHoursOfEngineOperation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TelemetryRangeTotalsDto
     */
    totalHoursOfCrusherOperation?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TelemetryRangeTotalsDto
     */
    totalHoursOfExternalPowerSupply?: number | null;
    /**
     * 
     * @type {number}
     * @memberof TelemetryRangeTotalsDto
     */
    totalHoursOfInternalPowerSupply?: number | null;
}

export function TelemetryRangeTotalsDtoFromJSON(json: any): TelemetryRangeTotalsDto {
    return TelemetryRangeTotalsDtoFromJSONTyped(json, false);
}

export function TelemetryRangeTotalsDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetryRangeTotalsDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalEngineFuelConsumed': !exists(json, 'totalEngineFuelConsumed') ? undefined : UnitValueDtoFromJSON(json['totalEngineFuelConsumed']),
        'totalEnergyConsumed': !exists(json, 'totalEnergyConsumed') ? undefined : UnitValueDtoFromJSON(json['totalEnergyConsumed']),
        'totalWeightLoad1': !exists(json, 'totalWeightLoad1') ? undefined : UnitValueDtoFromJSON(json['totalWeightLoad1']),
        'totalWeightLoad2': !exists(json, 'totalWeightLoad2') ? undefined : UnitValueDtoFromJSON(json['totalWeightLoad2']),
        'totalWeightLoad3': !exists(json, 'totalWeightLoad3') ? undefined : UnitValueDtoFromJSON(json['totalWeightLoad3']),
        'totalWeightLoad4': !exists(json, 'totalWeightLoad4') ? undefined : UnitValueDtoFromJSON(json['totalWeightLoad4']),
        'totalHoursOfEngineOperation': !exists(json, 'totalHoursOfEngineOperation') ? undefined : json['totalHoursOfEngineOperation'],
        'totalHoursOfCrusherOperation': !exists(json, 'totalHoursOfCrusherOperation') ? undefined : json['totalHoursOfCrusherOperation'],
        'totalHoursOfExternalPowerSupply': !exists(json, 'totalHoursOfExternalPowerSupply') ? undefined : json['totalHoursOfExternalPowerSupply'],
        'totalHoursOfInternalPowerSupply': !exists(json, 'totalHoursOfInternalPowerSupply') ? undefined : json['totalHoursOfInternalPowerSupply'],
    };
}

export function TelemetryRangeTotalsDtoToJSON(value?: TelemetryRangeTotalsDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalEngineFuelConsumed': UnitValueDtoToJSON(value.totalEngineFuelConsumed),
        'totalEnergyConsumed': UnitValueDtoToJSON(value.totalEnergyConsumed),
        'totalWeightLoad1': UnitValueDtoToJSON(value.totalWeightLoad1),
        'totalWeightLoad2': UnitValueDtoToJSON(value.totalWeightLoad2),
        'totalWeightLoad3': UnitValueDtoToJSON(value.totalWeightLoad3),
        'totalWeightLoad4': UnitValueDtoToJSON(value.totalWeightLoad4),
        'totalHoursOfEngineOperation': value.totalHoursOfEngineOperation,
        'totalHoursOfCrusherOperation': value.totalHoursOfCrusherOperation,
        'totalHoursOfExternalPowerSupply': value.totalHoursOfExternalPowerSupply,
        'totalHoursOfInternalPowerSupply': value.totalHoursOfInternalPowerSupply,
    };
}


