import {
  TOGGLE_SEARCH,
  UPDATE_SEARCH_TEXTFIELD,
  UPDATE_SEARCH_IGNITION_ON,
  UPDATE_SEARCH_IGNITION_OFF,
  UPDATE_SEARCH_MACHINE_STATE,
  SEARCH_MACHINES,
  UPDATE_FILTERED_MACHINES,
  NO_MACHINES_FOUND,
  MACHINES_FOUND,
  RESET_SEARCH_FILTERS,
  UPDATE_MAP_CENTER,
  UPDATE_SEARCH_MACHINE_TAGS,
} from './types';
import { IReduxState, IMachine, IMachineStateOption } from '../types';

export const toggleSearch = () => {
  return (dispatch: any) => {
    dispatch({
      type: TOGGLE_SEARCH,
    });
  };
};

export const updateSearchText = (text: string) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_SEARCH_TEXTFIELD,
      payload: text,
    });
  };
};

export const updateSearchIgnitionOn = () => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_SEARCH_IGNITION_ON,
    });
    dispatch(filterMachines());
  };
};

export const updateSearchIgnitionOff = () => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_SEARCH_IGNITION_OFF,
    });
    dispatch(filterMachines());
  };
};

export const updateSelectedMachineState = (
  selectedMachineState: IMachineStateOption
) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_SEARCH_MACHINE_STATE,
      payload: selectedMachineState,
    });
    dispatch(filterMachines());
  };
};

export const searchMachines = () => {
  return (dispatch: any) => {
    dispatch({
      type: SEARCH_MACHINES,
    });
    dispatch(filterMachines());
  };
};

// @TODO: Refactoring
function filterMachinesAccordingToSetFilterOptions(
  machines: IMachine[],
  getState: () => IReduxState
) {
  const allMachines: IMachine[] = getState().machines.allMachines;
  const checkboxIgnitionOn: boolean = getState().search.checkboxIgnitionOn;
  const checkboxIgnitionOff: boolean = getState().search.checkboxIgnitionOff;
  const selectedMachineState = getState().search.selectedMachineState;
  const selectedMachineTags = getState().search.selectedMachineTags;
  const machineTagOptions = getState().search.machineTagOptions;

  if (checkboxIgnitionOn && checkboxIgnitionOff) {
    machines = allMachines;
  } else if (checkboxIgnitionOn && !checkboxIgnitionOff) {
    machines = allMachines.filter(
      (item: IMachine) =>
        item.telemetrySnapshot?.isIgnitionOn === checkboxIgnitionOn
    );
  } else if (!checkboxIgnitionOn && checkboxIgnitionOff) {
    machines = allMachines.filter(
      (item: IMachine) =>
        !item.telemetrySnapshot?.isIgnitionOn === checkboxIgnitionOff
    );
  } else {
    machines = [];
  }

  if (selectedMachineState.id !== 0) {
    machines = machines.filter(
      (item: IMachine) => item.state?.id === selectedMachineState.id
    );
  }

  // filter by tags
  const selectedMachineTagIds = selectedMachineTags
    .map(({ id }) => id as number)
    .sort((a: number, b: number) => a - b);
  const machineTagIds = machineTagOptions
    .map(({ id }) => id as number)
    .sort((a: number, b: number) => a - b);
  if (selectedMachineTagIds.length !== machineTagIds.length) {
    machines = machines.filter((item: IMachine) => {
      const tagIds: number[] | null =
        item.tags !== null ? item.tags!.map(({ id }) => id as number) : null;
      let value = 0;
      if (!!tagIds) {
        tagIds.forEach(
          (tag: number) =>
            (value = value + +selectedMachineTagIds.includes(tag))
        );
        return value === 1;
      } else return value;
    });
  }
  return machines;
}

function filterBySearchString(item: IMachine, searchText: string, ownerRegex : RegExp) {
  console.log("curItem:");
  console.log(item);
  if(!!item.serialNumber){
    if(item.serialNumber.toLowerCase().includes(searchText)){
      console.log("1-1");
      return true;
    }
    else{
      console.log("!1-1");
    }
  }
  else {
    console.log("!1");
  }
  if(!!item.owner){
    if(ownerRegex.test(item.owner.toLowerCase())){
      console.log("2-1");
      return true;
    }
    else{
      console.log("!2-1");
    }
  }
  else {
    console.log("!2");
  }
  return false;
}

export const filterMachines = () => {
  return (dispatch: any, getState: () => IReduxState) => {
    const allMachines: IMachine[] = getState().machines.allMachines;
    let filteredMachines: IMachine[] = allMachines;
    const searchText = getState().search.searchText.toLowerCase();

    console.log("In filterMachines");
    console.log("searchText : " + searchText);
    console.log("allMachines: ");
    console.log(allMachines);

    filteredMachines = filterMachinesAccordingToSetFilterOptions(
      filteredMachines,
      getState
    );

    console.log("filteredMachines1 : ");
    console.log(filteredMachines);

    let ownerRegex = RegExp(
      '^' + searchText.toLowerCase() + '|\\s' + searchText.toLowerCase(),
      ''
    );

    // filteredMachines = filteredMachines.filter((item: IMachine) => filterBySearchString(item, searchText, ownerRegex) === true);

    // console.log("filteredMachines1a : ");
    // console.log(filteredMachines);


    filteredMachines = filteredMachines.filter(
      (item: IMachine) =>
        (!!item.serialNumber &&
          item.serialNumber.toLowerCase().includes(searchText)) ||
        (!!item.owner && ownerRegex.test(item.owner.toLowerCase()))
    );

    console.log("filteredMachines2 : ");
    console.log(filteredMachines);
    console.log(filteredMachines.length);

    if (!filteredMachines.length) {
      console.log("NO_MACHINES_FOUND");
      dispatch({ type: NO_MACHINES_FOUND });
    } else {
      console.log("MACHINES_FOUND");
      dispatch({ type: MACHINES_FOUND });
      dispatch({ type: TOGGLE_SEARCH });
    }

    if (filteredMachines.length === 1) {
      if (
        !!filteredMachines[0].telemetrySnapshot &&
        !!filteredMachines[0].telemetrySnapshot.location
      ) {
        console.log("!!filteredMachines[0].telemetrySnapshot.location");
        const location = {
          lat: filteredMachines[0].telemetrySnapshot.location.latitude,
          lng: filteredMachines[0].telemetrySnapshot.location.longitude,
        };
        console.log("UPDATE_MAP_CENTER");
        dispatch({
          type: UPDATE_MAP_CENTER,
          payload: location,
        });
      }
    }

    console.log("UPDATE_FILTERED_MACHINES");
    console.log(filteredMachines);
    
    dispatch({
      type: UPDATE_FILTERED_MACHINES,
      payload: filteredMachines,
    });
  };
};

export const resetFilters = () => {
  return (dispatch: any) => {
    dispatch({
      type: RESET_SEARCH_FILTERS,
    });
    dispatch(filterMachines());
  };
};

export const updateSelectedMachineTags = (tags: any) => {
  return (dispatch: any) => {
    dispatch({
      type: UPDATE_SEARCH_MACHINE_TAGS,
      payload: tags,
    });
    dispatch(filterMachines());
  };
};
