import React from 'react';
import TopNavigation from './TopNavigation';
import Logo from './Logo';
import RubbleBar from './RubbleBar';
import { themeColors } from '../../constants';

export default function Header() {
  return (
    <div
      style={{
        backgroundColor: themeColors.darkGray,
        position: 'relative',
      }}
    >
      <TopNavigation />
      <Logo />
      <RubbleBar />
    </div>
  );
}
