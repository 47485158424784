import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  REDIRECT_LOGOUT,
  REDIRECT_LOGIN,
  FETCH_BACKEND_INFO_FAIL,
  FETCH_BACKEND_INFO_SUCCESS,
  FETCH_BACKEND_INFO_REQUEST,
  USER_IS_IDLE,
  USER_IS_NOT_IDLE,
  USER_LANGUAGE_CHANGE,
  USER_UNIT_CHANGE,
} from './types';
import history from '../components/history';
import { fetchSessionApi, basePath, routes, userApi } from '../constants';
import { IReduxState } from '../types';
import { MeasurementSystem } from '../rubblemaster_openapi3_typescript-fetch/models';

export const fetchUserSession = (returnUrl?: string) => {
  return (dispatch: any) => {
    dispatch({ type: FETCH_USER_REQUEST });
    fetchSessionApi
      .apiSessionUserGet()
      .then((user: any) => {
        // console.log('%c%s', 'color: green;', 'Fetching user - SUCCESS!');
        dispatch({ type: FETCH_USER_SUCCESS, payload: user });
        if (!returnUrl) {
          history.push('/');
        } else {
          history.push(`/${returnUrl}`);
        }
      })
      .catch(() => {
        return dispatch({ type: FETCH_USER_FAIL });
      });
  };
};

export const redirectAzureB2CLogin = (returnUrl?: string) => {
  return (dispatch: any) => {
    dispatch({ type: REDIRECT_LOGIN });
    if (returnUrl) {
      window.location.replace(`${basePath}/api/session/login/${returnUrl}`);
    } else {
      window.location.replace(`${basePath}/api/session/login`);
    }
  };
};

export const redirectLogin = (redirectUrl?: string) => {
  return (dispatch: any) => {
    dispatch({ type: FETCH_USER_REQUEST });
    fetchSessionApi
      .apiSessionUserGet()
      .then((user: any) => {
        console.log('%c%s', 'color: green;', 'Fetching user - SUCCESS!');
        dispatch({ type: FETCH_USER_SUCCESS, payload: user });
        if(!!redirectUrl) {
          console.log("redirectUrl : " + redirectUrl);
          history.push(redirectUrl);
        }
        else {
          console.log("redirect: else");
          history.push(routes.map.pathName);
        }
      })
      .catch(() => {
        dispatch({ type: FETCH_USER_FAIL });
        if (redirectUrl) {
          window.location.replace(
            `${basePath}/api/session/login?returnUrl=${redirectUrl?.substr(
              1,
              redirectUrl.length
            )}`
          );
        } else {
          window.location.replace(`${basePath}/api/session/login`);
        }
      });
  };
};

export const redirectAzureB2CLogout = (returnUrl?: string) => {
  return (dispatch: any) => {
    dispatch({ type: REDIRECT_LOGOUT });
    if (returnUrl) {
      window.location.replace(
        `${basePath}/api/session/logout?returnUrl=${returnUrl}`
      );
    } else {
      window.location.replace(`${basePath}/api/session/logout`);
    }
  };
};

export const fetchBackendInfo = () => {
  return (dispatch: any) => {
    dispatch({ type: FETCH_BACKEND_INFO_REQUEST });
    fetchSessionApi
      .apiSessionBackendinfoGet()
      .then((response: any) => {
        dispatch({
          type: FETCH_BACKEND_INFO_SUCCESS,
          payload: response,
        });
      })
      .catch((error: any) => {
        console.error(error);
        dispatch({ type: FETCH_BACKEND_INFO_FAIL });
      });
  };
};

export const handleUserIdle = (isIdle: boolean) => {
  return (dispatch: any) => {
    const pathName = !!history.location.pathname
      ? history.location.pathname
      : '/';
    // console.log(
    //   '%c%s',
    //   `color: ${isIdle ? 'red' : 'green'};`,
    //   `User is ${isIdle ? 'idle' : 'active'} at ${new Date().toISOString()}`
    // );

    if (pathName !== routes.portal.pathName && isIdle) {
      dispatch({ type: USER_IS_IDLE });
      history.push(routes.portal.pathName);
    }

    if (!isIdle) {
      dispatch({ type: USER_IS_NOT_IDLE });
    }
  };
};

export const handleUserLanguageChange = (isoLanguageString: string) => {
  return async (dispatch: any, getState: () => IReduxState) => {
    try {
      const user = getState().auth.user;
      if (user) {
        await userApi.updatePreferencesPut({
          id: user.id,
          isoLanguageString,
          measurementSystem: user.measurementSystem,
        });
        dispatch({
          type: USER_LANGUAGE_CHANGE,
          payload: { isoLanguageCode: isoLanguageString },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
};

export const handleUserUnitChange = (measurementSystem: MeasurementSystem) => {
  return async (dispatch: any, getState: () => IReduxState) => {
    try {
      const user = getState().auth.user;
      if (user) {
        await userApi.updatePreferencesPut({
          id: user.id,
          isoLanguageString: user.isoLanguageCode ?? undefined,
          measurementSystem,
        });
        dispatch({
          type: USER_UNIT_CHANGE,
          payload: { measurementSystem },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
};
