import {
  UPDATE_SEARCH_TEXTFIELD,
  UPDATE_SEARCH_IGNITION_ON,
  UPDATE_SEARCH_IGNITION_OFF,
  UPDATE_SEARCH_MACHINE_STATE,
  TOGGLE_SEARCH,
  MACHINES_FOUND,
  NO_MACHINES_FOUND,
  RESET_SEARCH_FILTERS,
  UPDATE_MAP_CENTER,
  RESET_MAP_CENTER,
  FETCH_MACHINE_STATE_OPTIONS_SUCCESS,
  GET_USER_POSITION_SUCCESS,
  GET_USER_POSITION_FAIL,
  FETCH_MACHINE_TAG_OPTIONS_SUCCESS,
  UPDATE_SEARCH_MACHINE_TAGS,
} from '../actions/types';
import { rubbleMasterHq } from '../constants';
import { IReduxSearchProps } from '../types';

const initialState: IReduxSearchProps = {
  showSearch: false,
  showSearchError: false,
  searchText: '',
  searchTextValue: '',
  checkboxIgnitionOn: true,
  checkboxIgnitionOff: true,
  machineStateOptions: [],
  extendedMachineStateOptions: [{ id: 0, key: 'all' }],
  selectedMachineState: { id: 0, key: 'all' },
  map: {
    panToCenter: rubbleMasterHq,
    userConfirmedGeo: false,
    userPosition: rubbleMasterHq,
    error: '',
  },
  machineTagOptions: [],
  selectedMachineTags: [],
};

export default function(state = initialState, action: any) {
  switch (action.type) {
    case TOGGLE_SEARCH:
      return {
        ...state,
        showSearch: !state.showSearch,
      };
    case UPDATE_SEARCH_TEXTFIELD:
      return {
        ...state,
        searchText: action.payload,
      };
    case UPDATE_SEARCH_IGNITION_ON:
      return {
        ...state,
        checkboxIgnitionOn: !state.checkboxIgnitionOn,
      };
    case UPDATE_SEARCH_IGNITION_OFF:
      return {
        ...state,
        checkboxIgnitionOff: !state.checkboxIgnitionOff,
      };
    case UPDATE_SEARCH_MACHINE_STATE:
      return {
        ...state,
        selectedMachineState: action.payload,
      };
    case FETCH_MACHINE_STATE_OPTIONS_SUCCESS: {
      return {
        ...state,
        machineStateOptions: [...action.payload],
        extendedMachineStateOptions: [{ id: 0, key: 'all' }, ...action.payload],
      };
    }
    case MACHINES_FOUND:
      return {
        ...state,
        showSearchError: false,
      };
    case NO_MACHINES_FOUND:
      return {
        ...state,
        showSearchError: true,
      };
    case RESET_SEARCH_FILTERS:
      return {
        ...state,
        checkboxIgnitionOn: true,
        checkboxIgnitionOff: true,
        selectedMachineState: { id: 0, key: 'all' },
        selectedMachineTags: state.machineTagOptions,
      };
    case UPDATE_MAP_CENTER:
      return {
        ...state,
        map: {
          ...state.map,
          panToCenter: action.payload,
        },
      };
    case RESET_MAP_CENTER:
      return {
        ...state,
        map: {
          ...state.map,
          panToCenter: state.map.userPosition,
        },
      };
    case GET_USER_POSITION_SUCCESS: {
      return {
        ...state,
        map: {
          ...state.map,
          panToCenter: action.payload,
          userConfirmedGeo: true,
          userPosition: action.payload,
        },
      };
    }
    case GET_USER_POSITION_FAIL: {
      return {
        ...state,
        map: {
          ...state.map,
          userConfirmedGeo: false,
          error: action.payload,
        },
      };
    }
    case FETCH_MACHINE_TAG_OPTIONS_SUCCESS:
      return {
        ...state,
        machineTagOptions: action.payload,
        selectedMachineTags: action.payload,
      };
    case UPDATE_SEARCH_MACHINE_TAGS:
      return {
        ...state,
        selectedMachineTags: action.payload,
      };
    default:
      return state;
  }
}
