import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  CardMedia,
  List,
  ListItem,
  ListItemText,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { IReduxState, AccessLevel } from '../../types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { MachineImage } from './MachineImage';
import Skeleton from '@material-ui/lab/Skeleton';
import EditMachineConstructionSite from './EditMachineConstructionSite';
import { chartColors } from '../../constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1,
    flexGrow: 1,
  },
  card: {
    [theme.breakpoints.up('xs')]: {
      margin: `${theme.spacing(2)}px 0`,
    },
    [theme.breakpoints.up('sm')]: {
      height: 400,
      margin: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
    },
  },
  cardContent: {
    height: '100%',
    boxShadow: `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`,
  },
  actionsArena: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  constructionSite: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: '100%',
  },
}));

export default function MachineGeneral() {
  const classes = useStyles();
  const { t } = useTranslation();
  const machine = useSelector(
    (state: IReduxState) => state.machineDetails.machine
  );
  const isHybrid = useSelector(
    (state: IReduxState) =>
      !!state.machineDetails.machine.telemetrySnapshot?.isHybrid
  );
  const currentlyAuthenticatedUserAccessLevel: AccessLevel = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.currentlyAuthenticatedUserAccessLevel
  );

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Card
          className={classes.cardContent}
          style={{
            boxShadow: isHybrid
              ? `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`
              : 'none',
          }}
        >
          {!!machine.machineType.name ? (
            <CardMedia
              component="img"
              height="160"
              image={MachineImage(machine.machineType, isHybrid)}
            />
          ) : (
            <Skeleton variant="rect" height={160} animation="wave" />
          )}
          <CardContent
            className={classes.actionsArena}
            style={{ paddingBottom: 0 }}
          >
            <List>
              <ListItem alignItems="flex-start" dense>
                <ListItemText
                  primary={t('machine.serialNumber')}
                  secondary={
                    <React.Fragment>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        component="span"
                      >
                        {machine.serialNumber}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem alignItems="flex-start" dense>
                <ListItemText
                  primary={t('machine.owner')}
                  secondary={
                    <React.Fragment>
                      <Typography
                        variant="body1"
                        color="textSecondary"
                        component="span"
                      >
                        {machine.owner}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </ListItem>
              <ListItem dense alignItems="flex-start">
                <div className={classes.constructionSite}>
                  <ListItemText
                    primary={t('machine.constructionSite')}
                    secondary={
                      <React.Fragment>
                        <Typography
                          variant="body1"
                          color="textSecondary"
                          component="span"
                        >
                          { 
                            //currentlyAuthenticatedUserAccessLevel === AccessLevel.Read ||
                            currentlyAuthenticatedUserAccessLevel === AccessLevel.Write
                            ? machine.projectConstructionSite
                            : t('accessLevel.placeholder')}
                        </Typography>
                      </React.Fragment>
                    }
                  />
                  {currentlyAuthenticatedUserAccessLevel ===
                  AccessLevel.Write ? (
                    <EditMachineConstructionSite />
                  ) : null}
                </div>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
