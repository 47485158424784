import React, { Component } from 'react';
import { Marker } from 'react-google-maps';
import pinGreen from '../../assets/icons/marker-green.png';
import pinRed from '../../assets/icons/marker-red.png';
import MachineCard from './MachineCard';
import { IMachineDetailsProps } from '../../types';

interface IProps {
  filteredMachines?: any;
  key: string;
  disableClick?: boolean;
  hideOverlay?: boolean;
}

type IMergedProps = IProps & IMachineDetailsProps;

export default class RubbleMarker extends Component<IMergedProps> {
  state = {
    selectedMachine: null,
  };

  // Open Machine Card if Search Result is exact = only 1 machine
  componentDidUpdate = (prevProps: IMergedProps) => {
    if (this.props.filteredMachines !== prevProps.filteredMachines) {
      if (
        !!this.props.filteredMachines &&
        this.props.filteredMachines.length === 1 &&
        this.state.selectedMachine !== this.props.filteredMachines[0]
      ) {
        this.setState({
          selectedMachine: { ...this.props.filteredMachines[0] },
        });
      }
    }
  };

  // Reset State on unmount
  componentWillUnmount = () => {
    this.setState({ selectedMachine: null });
  };

  render() {
    const { machine, hideOverlay, disableClick } = this.props;
    const { selectedMachine } = this.state;
    const position = {
      lat: machine.telemetrySnapshot?.location?.latitude,
      lng: machine.telemetrySnapshot?.location?.longitude,
    };

    return (
      <Marker
        // @ts-ignore
        optimized={false}
        clickable={disableClick ? false : true}
        noRedraw
        key={machine.id}
        position={position}
        icon={{
          url: machine.telemetrySnapshot?.isIgnitionOn ? pinGreen : pinRed,
          size: { width: 60, height: 100 },
          anchor: { x: 15, y: 50 },
          scaledSize: { width: 30, height: 50 },
        }}
        onClick={() => this.setState({ selectedMachine: { ...machine } })}
      >
        {selectedMachine && !hideOverlay && (
          <MachineCard
            machine={selectedMachine!}
            onClose={() => this.setState({ selectedMachine: null })}
          />
        )}
      </Marker>
    );
  }
}
