import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {
  Button,
  IconButton,
  TextField,
  InputAdornment,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IReduxState } from '../../types';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  editMachineNote,
  deleteMachineNote,
} from '../../actions/machineDetailsActions';

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    padding: `${theme.spacing(2)}px`,
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: `${theme.spacing(4)}px`,
  },
  saveButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: `${theme.spacing(4)}px`,
  },
  tagsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  contentWrapper: {
    margin: `${theme.spacing(4)}px 0`,
  },
}));

export interface IMachineStateDialogProps {
  open: boolean;
  onClose: () => void;
}

function MachineNotesDialog(props: IMachineStateDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open } = props;
  const dispatch = useDispatch();
  const machine = useSelector(
    (state: IReduxState) => state.machineDetails.machine
  );
  const machineId = machine.id;
  const [machineNote, setMachineNote] = useState<string>('');

  const handleClose = () => {
    setMachineNote(machine.notes as string);
    onClose();
  };

  const handleSave = () => {
    dispatch(editMachineNote(machineId as number, machineNote.trim()));
    onClose();
  };

  const handleDelete = () => {
    dispatch(deleteMachineNote(machineId as number));
    handleClose();
  };

  const preventEnter = (ev: React.KeyboardEvent<HTMLDivElement>) => {
    if (ev.key === 'Enter') {
      ev.preventDefault();
    }
  };

  const handleChange = (text: string) => {
    if (text.length === 0) {
      text = '';
    }

    setMachineNote(text);
  };

  useEffect(() => {
    const machineNote = machine.notes as string;
    setMachineNote(machineNote);
    // eslint-disable-next-line
  }, [machine.notes]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="machine-tag-dialog"
      open={open}
      fullWidth
      maxWidth="md"
    >
      <div className={classes.dialog}>
        <div className={classes.title}>
          <DialogTitle id="machine-tag-dialog">
            {t('machine.editMachineNotes')}
          </DialogTitle>
          <IconButton
            size="medium"
            aria-label="close"
            onClick={handleClose}
            title="edit"
          >
            <CloseIcon color="action" />
          </IconButton>
        </div>
        <div className={classes.content}>
          <form noValidate autoComplete="off">
            <TextField
              multiline
              fullWidth
              id="machine-notes-textfield"
              label={t('machine.addNote')}
              variant="filled"
              value={machineNote ? machineNote : ''}
              onKeyPress={(ev: React.KeyboardEvent<HTMLDivElement>) => preventEnter(ev)}
              onChange={(event: React.ChangeEvent<{ name?: string; value: string | any }>) => handleChange(event.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      title={t('machine.deleteNote')}
                      aria-label={t('machine.deleteNote')}
                      onClick={handleDelete}
                    >
                      {machineNote && machineNote.length ? <DeleteIcon /> : null}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </form>
        </div>
        <div className={classes.saveButton}>
          <Button
            title={t('controls.save')}
            variant="outlined"
            color="primary"
            onClick={handleSave}
          >
            {t('controls.save')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default function EditMachineNote() {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.iconWrapper}>
        <IconButton
          size="small"
          aria-label="edit"
          onClick={handleClickOpen}
          title="edit"
        >
          <EditIcon color="action" />
        </IconButton>
      </div>
      <MachineNotesDialog open={open} onClose={handleClose} />
    </div>
  );
}
