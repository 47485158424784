import React from 'react';
import { ListItemIcon, Typography } from '@material-ui/core';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { useTranslation } from 'react-i18next';

export default function MuiMenuLogout() {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <ListItemIcon>
        <LockOpenIcon fontSize="small" />
      </ListItemIcon>
      <Typography variant="inherit">{t('navigation.logout')}</Typography>
    </React.Fragment>
  )
}
