import React from 'react';
import { ListItemIcon, Typography } from '@material-ui/core';
import MapIcon from '@material-ui/icons/Map';
import { useTranslation } from 'react-i18next';
import { themeColors } from '../../constants';
import { IRouteProps } from '../../types';

export default function MuiMenuMap(props: IRouteProps) {
  const { t } = useTranslation();
  const translationString = props.translationString ? props.translationString : 'navigation.map';
  return (
    <div style={props.isActive ? { color: themeColors.yellow } : {}}>
      <ListItemIcon>
        <MapIcon fontSize="small" />
      </ListItemIcon>
      <Typography variant="inherit">{t(translationString)}</Typography>
    </div>
  );
}
