import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import IgnitionCheckboxes from './IgnitionCheckboxes';
import MachineStateSelect from './MachineStateSelect';

const useStyles = makeStyles({
  root: {
    textAlign: 'center'
  },
  container: {
    display: 'flex',
  }
});

export default function SearchFilters() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <IgnitionCheckboxes />
        <MachineStateSelect />
      </Grid>
    </div>
  );
}
