/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MachineTypeDto
 */
export interface MachineTypeDto {
    /**
     * 
     * @type {number}
     * @memberof MachineTypeDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof MachineTypeDto
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof MachineTypeDto
     */
    category: string;
    /**
     * 
     * @type {string}
     * @memberof MachineTypeDto
     */
    normalImage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MachineTypeDto
     */
    hybridImage?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof MachineTypeDto
     */
    isThirdParty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof MachineTypeDto
     */
    isJawCrusher?: boolean;
    /**
     * 
     * @type {number}
     * @memberof MachineTypeDto
     */
    maxBeltScales?: number;
}

export function MachineTypeDtoFromJSON(json: any): MachineTypeDto {
    return MachineTypeDtoFromJSONTyped(json, false);
}

export function MachineTypeDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MachineTypeDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'category': json['category'],
        'normalImage': !exists(json, 'normalImage') ? undefined : json['normalImage'],
        'hybridImage': !exists(json, 'hybridImage') ? undefined : json['hybridImage'],
        'isThirdParty': !exists(json, 'isThirdParty') ? undefined : json['isThirdParty'],
    };
}

export function MachineTypeDtoToJSON(value?: MachineTypeDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'category': value.category,
        'normalImage': value.normalImage,
        'hybridImage': value.hybridImage,
        'isThirdParty': value.isThirdParty,
    };
}


