import React from 'react';
import SearchTextInput from './SearchTextInput';
import SearchButton from './SearchButton';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Grid, Collapse } from '@material-ui/core';
import SearchFilters from './SearchFilters';
import { useSelector } from 'react-redux';
import { IReduxState } from '../../types';
import SearchTags from './SearchTags';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 'auto',
      margin: `${theme.spacing(4)}px 0 0 0`,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    searchContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
  })
);

export default function MobileSearch() {
  const classes = useStyles();
  const showSearch = useSelector(
    (state: IReduxState) => state.search.showSearch
  );
  return (
    <Collapse in={showSearch}>
      <div className={classes.root}>
        <form style={{ width: 'inherit' }} noValidate autoComplete="off">
          <div className={classes.searchContainer}>
            <SearchTextInput />
            <SearchButton />
          </div>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <SearchFilters />
            <SearchTags />
          </Grid>
        </form>
      </div>
    </Collapse>
  );
}
