import React from 'react';
import { chartColors, themeColors } from '../../../constants';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IChartProps, IReduxState } from '../../../types';
import SkeletonLoaderCardSmall from '../SkeletonLoaderCardSmall';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      flexGrow: 1,
    },
    card: {
      height: 500,
      [theme.breakpoints.up('xs')]: {
        margin: `${theme.spacing(4)}px 0`,
      },
      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px`,
      },
    },
    cardContent: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(2)}px 0`,
      },
    },
    actionsArena: {
      position: 'relative',
      height: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(4),
        justifyContent: 'start',
      },
    },
    lastUpdate: {
      position: 'absolute',
      left: theme.spacing(1),
      top: 0,
      color: themeColors.white,
    },
    gaugeWrapper: {
      height: 175,
      width: 200,
    },
    title: {
      marginTop: '1rem',
    },
    titleWrapper: {
      margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0`,
      [theme.breakpoints.up('md')]: {
        margin: 0,
      },
      [theme.breakpoints.up('lg')]: {
        margin: `${theme.spacing(2)}px 0 ${theme.spacing(1)}px 0`,
      },
    },
    display: {
      margin: theme.spacing(1),
    },
  })
);

export default function ThroughputDisplay(props: IChartProps) {
  const { isPdfReport } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const averageThroughputs = useSelector(
    (state: IReduxState) => state.machineDetails.averageThroughputs
  );
  const isFetchingTelemetry = useSelector(
    (state: IReduxState) => state.machineDetails.isFetchingTelemtry
  );
  const isHybrid = useSelector(
    (state: IReduxState) =>
      !!state.machineDetails.machine.telemetrySnapshot?.isHybrid
  );

// default: false
const isJawCrusher = useSelector(
  (state: IReduxState) => state.machineDetails.machine.machineType.isJawCrusher ?? false
)

// default: 4
const maxBeltScales = useSelector(
  (state: IReduxState) => state.machineDetails.machine.machineType.maxBeltScales ?? 4
)


  const {
    weightLoad1,
    weightLoad2,
    weightLoad3,
    weightLoad4,
  } = averageThroughputs;
  const totalWeightLoad1 = useSelector(
    (state: IReduxState) => state.machineDetails.totalWeightLoad1
  );
  const totalWeightLoad2 = useSelector(
    (state: IReduxState) => state.machineDetails.totalWeightLoad2
  );
  const totalWeightLoad3 = useSelector(
    (state: IReduxState) => state.machineDetails.totalWeightLoad3
  );
  const totalWeightLoad4 = useSelector(
    (state: IReduxState) => state.machineDetails.totalWeightLoad4
  );

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Card
          className={classes.cardContent}
          style={{
            boxShadow:
              isHybrid && !isPdfReport
                ? `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`
                : 'none',
          }}
        >
          <CardContent
            className={classes.actionsArena}
            style={{
              paddingBottom: 0,
              paddingTop: isFetchingTelemetry ? '1rem' : '2rem',
            }}
          >
            {!isFetchingTelemetry ? (
              <React.Fragment>
                <Typography className={classes.lastUpdate} variant="caption">
                  {`${t('graph.lastReceivedValue')}: ${
                    !!averageThroughputs.timestamp
                      ? moment
                          .utc(averageThroughputs.timestamp)
                          .local()
                          .format(`DD.MM.YYYY HH:mm`)
                      : '-'
                  }`}
                </Typography>
                <Typography
                  className={classes.title}
                  align="center"
                  variant="h5"
                >
                  {t('graph.throughput')}
                </Typography>
                {!!averageThroughputs ? (
                  <React.Fragment>
                    <div className={classes.titleWrapper}>
                      <Typography align="center" variant="body1">
                        {t('graph.mainDischargeConveyor')}
                      </Typography>
                      <Typography align="center" variant="body1">
                        {`${t('graph.average')}: `}
                        <span style={{ color: chartColors.cyan }}>
                          {weightLoad1 !== null &&
                          weightLoad1.value !== undefined
                            ? `${weightLoad1.value.toFixed(2)} ${
                                weightLoad1.unit
                              }`
                            : t('graph.noData')}
                        </span>
                      </Typography>
                      <Typography align="center" variant="body1">
                        {`${t('graph.total')}: `}
                        <span style={{ color: chartColors.cyan }}>
                          {totalWeightLoad1 !== null &&
                          totalWeightLoad1.value !== undefined
                            ? `${totalWeightLoad1.value.toFixed(2)} ${
                                totalWeightLoad1.unit
                              }`
                            : t('graph.noData')}
                        </span>
                      </Typography>
                    </div>
                    <div className={classes.display}>
                      <Typography align="center" variant="body1">
                        {t('graph.secondDischargeConveyor')}
                      </Typography>
                      <Typography align="center" variant="body1">
                        {`${t('graph.average')}: `}
                        <span style={{ color: chartColors.green }}>
                          {weightLoad2 !== null &&
                          weightLoad2.value !== undefined
                            ? `${weightLoad2.value.toFixed(2)} ${
                                weightLoad2.unit
                              }`
                            : t('graph.noData')}
                        </span>
                      </Typography>
                      <Typography align="center" variant="body1">
                        {`${t('graph.total')}: `}
                        <span style={{ color: chartColors.green }}>
                          {totalWeightLoad2 !== null &&
                          totalWeightLoad2.value !== undefined
                            ? `${totalWeightLoad2.value.toFixed(2)} ${
                                totalWeightLoad2.unit
                              }`
                            : t('graph.noData')}
                        </span>
                      </Typography>
                    </div>
                    {maxBeltScales >= 3 
                      ? (
                        <div className={classes.display}>
                          <Typography align="center" variant="body1">
                            {t('graph.thirdDischargeConveyor')}
                          </Typography>
                          <Typography align="center" variant="body1">
                            {`${t('graph.average')}: `}
                            <span style={{ color: chartColors.orange }}>
                              {weightLoad3 !== null &&
                                weightLoad3.value !== undefined
                                ? `${weightLoad3.value.toFixed(2)} ${weightLoad3.unit}`
                                : t('graph.noData')}
                            </span>
                          </Typography>
                          <Typography align="center" variant="body1">
                            {`${t('graph.total')}: `}
                            <span style={{ color: chartColors.orange }}>
                              {totalWeightLoad3 !== null &&
                                totalWeightLoad3.value !== undefined
                                ? `${totalWeightLoad3.value.toFixed(2)} ${totalWeightLoad3.unit}`
                                : t('graph.noData')}
                            </span>
                          </Typography>
                        </div>
                      )
                      : (<div></div>)
                    }
                    {maxBeltScales >= 4 
                      ? (
                        <div className={classes.display}>
                          <Typography align="center" variant="body1">
                            {t('graph.fourthDischargeConveyor')}
                          </Typography>
                          <Typography align="center" variant="body1">
                            {`${t('graph.average')}: `}
                            <span style={{ color: chartColors.lime }}>
                              {weightLoad4 !== null &&
                                weightLoad4.value !== undefined
                                ? `${weightLoad4.value.toFixed(2)} ${weightLoad4.unit}`
                                : t('graph.noData')}
                            </span>
                          </Typography>
                          <Typography align="center" variant="body1">
                            {`${t('graph.total')}: `}
                            <span style={{ color: chartColors.lime }}>
                              {totalWeightLoad4 !== null &&
                                totalWeightLoad4.value !== undefined
                                ? `${totalWeightLoad4.value.toFixed(2)} ${totalWeightLoad4.unit}`
                                : t('graph.noData')}
                            </span>
                          </Typography>
                        </div>
                      )
                      : (<div></div>)
                    }
                  </React.Fragment>
                ) : (
                  <Typography
                    className={classes.title}
                    align="center"
                    variant="h4"
                  >
                    {t('graph.noData')}
                  </Typography>
                )}
              </React.Fragment>
            ) : (
              <SkeletonLoaderCardSmall />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
