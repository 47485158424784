import React from 'react';
import { makeStyles, Theme, createStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IReduxState } from '../../types';
import { useSelector } from 'react-redux';
import { chartColors } from '../../constants';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },
    normalFont: {
      fontSize: 'unset',
    },
    smallFont: {
      fontSize: 12,
    },
  })
);

interface IProps {
  isPdfReport?: boolean;
  isHybrid?: boolean;
}

// Total hours in telemetry time range
export default function TotalHours(props: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isHybrid, isPdfReport } = props;

  const {
    totalHoursOfCrusherOperation,
    totalHoursOfEngineOperation,
    totalHoursOfInternalPowerSupply,
    totalHoursOfExternalPowerSupply,
  } = useSelector((state: IReduxState) => state.machineDetails);

  const isThirdParty = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.machineType.isThirdParty
  );

  return (
    <div>
      <Typography
        className={classes.title}
        align="center"
        variant="h5"
        style={{
          marginBottom: isHybrid ? 0 : 'inherit',
          marginTop: isHybrid ? '1rem' : '2rem',
        }}
      >
        {t('graph.totalHours')}
      </Typography>
      {!isThirdParty && (
        <Typography
          align="left"
          className={
            isHybrid && isPdfReport ? classes.smallFont : classes.normalFont
          }
        >
          {`${t('graph.crusherOperationTotal')}: `}
          <span style={{ color: chartColors.rmYellow }}>
            {totalHoursOfCrusherOperation !== null
              ? `${totalHoursOfCrusherOperation.toFixed(2)} ${t('graph.h')}`
              : t('graph.noData')}
          </span>
        </Typography>
      )}
      <Typography
        align="left"
        className={
          isHybrid && isPdfReport ? classes.smallFont : classes.normalFont
        }
      >
        {`${t('graph.engineOperationTotal')}: `}
        <span style={{ color: chartColors.rmYellow }}>
          {totalHoursOfEngineOperation !== null
            ? `${totalHoursOfEngineOperation.toFixed(2)} ${t('graph.h')}`
            : t('graph.noData')}
        </span>
      </Typography>
      {isHybrid && (
        <div>
          {/* <Typography
            align="left"
            className={isPdfReport ? classes.smallFont : classes.normalFont}
          >
            {`${t('graph.externalPowerSupply')}: `}
            <span style={{ color: chartColors.electricBlue }}>
              {totalHoursOfExternalPowerSupply !== null
                ? `${totalHoursOfExternalPowerSupply.toFixed(2)} ${t(
                    'graph.h'
                  )}`
                : t('graph.noData')}
            </span>
          </Typography> */}

          {/* <Typography
            align="left"
            className={isPdfReport ? classes.smallFont : classes.normalFont}
          >
            {`${t('graph.internalPowerSupply')}: `}
            <span style={{ color: chartColors.electricBlue }}>
              {totalHoursOfInternalPowerSupply !== null
                ? `${totalHoursOfInternalPowerSupply.toFixed(2)} ${t(
                    'graph.h'
                  )}`
                : t('graph.noData')}
            </span>
          </Typography> */}
        </div>
      )}
    </div>
  );
}
