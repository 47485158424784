import { combineReducers } from 'redux';
import authReducer from './authReducer'
import searchReducer from './searchReducer';
import machineReducer from './machineReducer';
import machineDetailsReducer from './machineDetailsReducer';

export default combineReducers({
  auth: authReducer,
  search: searchReducer,
  machines: machineReducer,
  machineDetails: machineDetailsReducer
})
