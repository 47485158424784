import React, { useEffect } from 'react';
import { RubbleMap } from './RubbleMap';
import { googleMapUrlWithKey } from '../constants';
import { useSelector, useDispatch } from 'react-redux';
import {
  CircularProgress,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { IReduxState } from '../types';
import { usePosition } from './usePosition';
import { getAllMachines } from '../actions/machineActions';
import { useTranslation } from 'react-i18next';
import { useIdle } from './useIdle';
import { handleUserIdle } from '../actions/authActions';

const useStyles = makeStyles((theme: Theme) => ({
  loadingElement: {
    position: 'absolute',
    height: 'calc(100vH - (88px + 56px))',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fullHeight: {
    position: 'absolute',
    height: 'calc(100vH - (88px + 56px))',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vH - 96px)',
    },
    width: `100%`,
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isFetchingAllMachines = useSelector(
    (state: IReduxState) => state.machines.isFetchingAllMachines
  );
  const machines = useSelector(
    (state: IReduxState) => state.machines.filteredMachines
  );
  const userConfirmedGeo = useSelector(
    (state: IReduxState) => state.search.map.userConfirmedGeo
  );
  const loadingElement = (
    <div className={classes.loadingElement}>
      <CircularProgress variant="indeterminate" color="primary" />
      <Typography variant="body1" color="primary">
        {t('general.loadingText')}
      </Typography>
    </div>
  );

  // Get user geo postion
  usePosition(userConfirmedGeo);

  const isIdle = useIdle();

  // Get all machines on mount
  useEffect(() => {
    dispatch(getAllMachines());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(handleUserIdle(isIdle));
  }, [isIdle, dispatch]);


  return (
    <React.Fragment>
      {isFetchingAllMachines ? (
        loadingElement
      ) : (
        <React.Fragment>
          <RubbleMap
            hideOverlay={false}
            showMarkerCluster={true}
            machines={machines}
            googleMapURL={googleMapUrlWithKey}
            loadingElement={loadingElement}
            containerElement={<div style={{ height: '100%', width: '100%' }} />}
            mapElement={
              <div className={classes.fullHeight} style={{ zIndex: 1001 }} />
            }
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
