import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Typography,
  makeStyles,
  Theme,
  Link,
} from '@material-ui/core';
import { IReduxState } from '../types';
import RubbleBreadcrumbs from './RubbleBreadcrumbs';
import { routes } from '../constants';
import { useTranslation } from 'react-i18next';
import { fetchBackendInfo, handleUserIdle } from '../actions/authActions';
import { useIdle } from './useIdle';
import rmBackground from '../assets/img/rm_welcome-screen.jpg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    //marginTop: theme.spacing(2),
    background: `url(${rmBackground})`,
    minHeight: 'calc(100vH - 96px)',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vH - 96px)',
    },
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    paddingLeft: theme.spacing(4),
    // margin: `${theme.spacing(4)}px auto`,
    position: 'relative',
    maxWidth: '1960px',
  },
  status: {},
  content: {
    maxWidth: '800px',
    margin: '0px auto',
    textAlign: 'center',
  },
  separator: {
    borderTop: '1px solid white',
    marginBottom: '20px',
    marginLeft: '100px',
    marginRight: '100px',
  },
}));

export default function About() {
  const classes = useStyles();
  const userSettings = useSelector((state: IReduxState) => state.auth.user);
  const backendInfo = useSelector(
    (state: IReduxState) => state.auth.backendInfo
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isIdle = useIdle();

  // Get all machines on mount
  useEffect(() => {
    dispatch(fetchBackendInfo());
  }, [dispatch]);

  useEffect(() => {
    dispatch(handleUserIdle(isIdle));
  }, [isIdle, dispatch]);

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <RubbleBreadcrumbs current={routes.about.pathName} />
      </div>
      <div className={classes.content}>
        <Container maxWidth="lg">
          <Typography paragraph variant="h4">
            {t('about.productName')}
          </Typography>

          <Typography paragraph variant="body1">
            {t('about.productDescription')}
          </Typography>

          <Typography paragraph variant="body1">
            {t('about.technicalQuestionsParagraph')}
            &nbsp;
            <Link href="mailto:do@rubblemaster.com">
              {t('about.technicalQuesionsEmailLabel')}
            </Link>
          </Typography>
        </Container>

        <Container maxWidth="lg" className={classes.status}>
          <div className={classes.separator} />
          <Typography paragraph variant="body1">
            RUBBLE MASTER HMH GmbH
            <br />
            Im Südpark 196 | 4030 Linz | Austria
            <br />
            T +43 732 73 71 17 – 668
            <br />
            F +43 732 73 71 17 – 101
            <br />E{' '}
            <Link href="mailto:do@rubblemaster.com">
              {t('about.technicalQuesionsEmailLabel')}
            </Link>
          </Typography>
        </Container>

        <Container maxWidth="lg" className={classes.status}>
          <div className={classes.separator} />
          <Typography paragraph variant="body1">
            {`${t('about.version')}: ${
              !!backendInfo.version ? backendInfo.version.split("-")[0] : '-'
            }` } 
          </Typography>
          <Typography paragraph variant="body1">
            {t('general.loggedInAs')} {userSettings?.email}
          </Typography>
        </Container>
      </div>
    </div>
  );
}
