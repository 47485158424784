/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface StateDto
 */
export interface StateDto {
    /**
     * 
     * @type {number}
     * @memberof StateDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof StateDto
     */
    key?: string | null;
}

export function StateDtoFromJSON(json: any): StateDto {
    return StateDtoFromJSONTyped(json, false);
}

export function StateDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): StateDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'key': !exists(json, 'key') ? undefined : json['key'],
    };
}

export function StateDtoToJSON(value?: StateDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'key': value.key,
    };
}


