import { useState, useEffect } from 'react';
import { getUserPositionRequest, getUserPostion } from '../actions/machineActions';
import { useDispatch } from 'react-redux';

export interface ICoords {
  coords?: {
    latitude: Number,
    longitude: Number,
  }
}

const defaultSettings = {
  enableHighAccuracy: false,
  timeout: Infinity,
  maximumAge: 0,
};

export const usePosition = (userConfirmedGeo: boolean, settings = defaultSettings) => {
  const [position, setPosition] = useState({});
  const [error, setError] = useState<string | null>(null);
  const dispatch = useDispatch();

  const onChange = (value: ICoords) => {
    const { coords } = value;
    const userCoords = {
      lat: coords?.latitude,
      lng: coords?.longitude
    };
    setPosition(userCoords);
    if (!userConfirmedGeo) {
      dispatch(getUserPostion(true, userCoords))
    }
  };

  const onError = (error: any) => {
    setError(error.message);
    dispatch(getUserPostion(false, error.message))
  };

  useEffect(() => {
    if (!userConfirmedGeo) {
      dispatch(getUserPositionRequest());
    }
    const geo = navigator.geolocation;
    if (!geo) {
      setError('Geolocation is not supported');
      return;
    }
    return geo.getCurrentPosition(onChange, onError, settings);
    // eslint-disable-next-line
  }, [userConfirmedGeo]);

  return { ...position, error };
}
