/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    MachineTypeDto,
    MachineTypeDtoFromJSON,
    MachineTypeDtoToJSON,
    StateDto,
    StateDtoFromJSON,
    StateDtoToJSON,
} from '../models';

export interface ApiMachineTypesDeleteRequest {
    id?: number;
}

export interface ApiMachineTypesIdPutRequest {
    id: number;
    machineTypeDto?: MachineTypeDto;
}

export interface ApiMachineTypesPostRequest {
    machineTypeDto?: MachineTypeDto;
}

/**
 * no description
 */
export class MachineTypesApi extends runtime.BaseAPI {

    /**
     */
    async apiMachineTypesDeleteRaw(requestParameters: ApiMachineTypesDeleteRequest): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.id !== undefined) {
            queryParameters['id'] = requestParameters.id;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MachineTypes`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async apiMachineTypesDelete(requestParameters: ApiMachineTypesDeleteRequest): Promise<boolean> {
        const response = await this.apiMachineTypesDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     */
    async apiMachineTypesGetRaw(): Promise<runtime.ApiResponse<Array<MachineTypeDto>>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/api/MachineTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MachineTypeDtoFromJSON));
    }

    /**
     */
    async apiMachineTypesGet(): Promise<Array<MachineTypeDto>> {
        const response = await this.apiMachineTypesGetRaw();
        return await response.value();
    }

    /**
     */
    async apiMachineTypesIdPutRaw(requestParameters: ApiMachineTypesIdPutRequest): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiMachineTypesIdPut.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/MachineTypes/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: MachineTypeDtoToJSON(requestParameters.machineTypeDto),
        });

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiMachineTypesIdPut(requestParameters: ApiMachineTypesIdPutRequest): Promise<void> {
        await this.apiMachineTypesIdPutRaw(requestParameters);
    }

    /**
     */
    async apiMachineTypesPostRaw(requestParameters: ApiMachineTypesPostRequest): Promise<runtime.ApiResponse<StateDto>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/MachineTypes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MachineTypeDtoToJSON(requestParameters.machineTypeDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => StateDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiMachineTypesPost(requestParameters: ApiMachineTypesPostRequest): Promise<StateDto> {
        const response = await this.apiMachineTypesPostRaw(requestParameters);
        return await response.value();
    }

}
