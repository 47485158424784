import React from 'react';
import { Typography, makeStyles, Theme } from '@material-ui/core';
import rmBackground from '../assets/img/rm-background.jpg';
import { Link } from 'react-router-dom';
import { routes } from '../constants';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    background: `url(${rmBackground}) center`,
    minHeight: 'calc(100vH - 88px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      minHeight: 'calc(100vH - 96px)',
    }
  },
  textBox: {
    maxWidth: '600px',
    background: '#fff',
    opacity: 0.8,
    padding: `${theme.spacing(4)}px`
  }
}));

export default function NotFound() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.textBox}>
        <Typography color="secondary" variant="h6">{t('general.404Message')}</Typography>
        <Link to={routes.portal.pathName}>{t('general.goBack')}</Link>
      </div>
    </div>
  )
}
