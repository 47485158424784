import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { Chip, IconButton, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IReduxState, IExtendedMachineTagOption } from '../../types';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import {
  addMachineTag,
  deleteMachineTag,
} from '../../actions/machineDetailsActions';
import { themeColors } from '../../constants';
import { TagDto } from '../../rubblemaster_openapi3_typescript-fetch';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 200,
  },
  dialog: {
    padding: `${theme.spacing(2)}px`,
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginBottom: `${theme.spacing(4)}px`,
  },
  saveButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: `${theme.spacing(4)}px`,
  },
  tagsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  contentWrapper: {
    margin: `${theme.spacing(4)}px 0`,
  },
}));

export interface IMachineStateDialogProps {
  open: boolean;
  onClose: () => void;
}

function MachineTagsDialog(props: IMachineStateDialogProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { onClose, open } = props;
  const dispatch = useDispatch();
  const machineTagOptions: IExtendedMachineTagOption[] = useSelector(
    (state: IReduxState) => state.search.machineTagOptions
  );
  const machine = useSelector(
    (state: IReduxState) => state.machineDetails.machine
  );
  const machineId = machine.id;
  const [machineTags, setMachineTags] = useState<IExtendedMachineTagOption[]>();

  const handleClose = () => {
    onClose();
  };

  const handleAdd = (tag: any) => {
    dispatch(addMachineTag(machineId as number, tag));
  };

  const handleDelete = (tag: any) => {
    dispatch(deleteMachineTag(machineId as number, tag));
  };

  useEffect(() => {
    const tags = machine.tags;
    const machineTags = tags
      ? tags.map((machineTag: any) => {
          const color = machineTagOptions.length
            ? machineTagOptions.filter(
                (tagOption: any) => tagOption.id === machineTag.id
              )[0].color
            : themeColors.yellow;
            console.log(color);
          return {
            id: machineTag.id,
            key: machineTag.key,
            color,
          };
        })
      : [];
      console.log(machineTags);
    setMachineTags(machineTags);
  }, [machineTagOptions, machine]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="machine-tag-dialog"
      open={open}
    >
      <div className={classes.dialog}>
        <div className={classes.title}>
          <DialogTitle id="machine-tag-dialog">
            {t('machine.editMachineTags')}
          </DialogTitle>
          <IconButton
            size="medium"
            aria-label="close"
            onClick={handleClose}
            title="edit"
          >
            <CloseIcon color="action" />
          </IconButton>
        </div>
        <div className={classes.content}>
          <div className={classes.contentWrapper}>
            <Typography variant="h6">{t('machine.active')}</Typography>
            {machineTags && (
              <div className={classes.tagsWrapper}>
                {!!machineTags &&
                  machineTags.map((item: any, index: number) => {
                    return (
                      <Chip
                        variant="outlined"
                        style={{
                          borderColor: item.color,
                          color: item.color,
                          margin: '8px',
                        }}
                        key={`tag-${index}-${item}`}
                        label={item.key}
                        onDelete={() => handleDelete(item)}
                      />
                    );
                  })}
              </div>
            )}
          </div>
          <div className={classes.contentWrapper}>
            <Typography variant="h6">{t('machine.available')}</Typography>
            {machineTagOptions && machineTags && (
              <div className={classes.tagsWrapper}>
                {machineTagOptions
                  .filter(
                    (tagOption: any) =>
                      machineTags
                        .map((tag: any) => tag.id)
                        .includes(tagOption.id) === false
                  )
                  .map((item: any, index: number) => {
                    return (
                      <Chip
                        variant="outlined"
                        style={{
                          borderColor: item.color,
                          color: item.color,
                          margin: '8px',
                        }}
                        key={`tag-${index}-${item}`}
                        label={item.key}
                        deleteIcon={<AddIcon style={{ color: item.color }} />}
                        onDelete={() => handleAdd(item)}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default function EditMachineTags() {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.iconWrapper}>
        <IconButton
          size="small"
          aria-label="edit"
          onClick={handleClickOpen}
          title="edit"
        >
          <EditIcon color="action" />
        </IconButton>
      </div>
      <MachineTagsDialog open={open} onClose={handleClose} />
    </div>
  );
}
