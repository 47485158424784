import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

export default function SkeletonLoaderCardSmall() {
  return (
    <div style={{ margin: '0 1rem' }}>
      <Skeleton animation="wave" />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" variant="rect" width={300} height={175} />
      <Skeleton animation="wave" />
      <Skeleton animation="wave" variant="rect" width={300} height={175} />
      <Skeleton animation="wave" />
    </div>
  );
}
