import React from 'react';
import { ListItemIcon, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import { useTranslation } from 'react-i18next';
import { themeColors } from '../../constants';
import { IRouteProps } from '../../types';

export default function MuiMenuPortal(props: IRouteProps) {
  const { t } = useTranslation();
  return (
    <div style={props.isActive ? { color: themeColors.yellow } : {}}>
      <ListItemIcon>
        <DashboardIcon fontSize="small" />
      </ListItemIcon>
      <Typography variant="inherit">{t('navigation.portal')}</Typography>
    </div>
  );
}
