import React, { Suspense } from 'react';
import Header from './components/Header/index';
import { ThemeProvider } from '@material-ui/core/styles';
import { rubbleMasterTheme, routes } from './constants';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import NotFound from './components/NotFound';
import { CssBaseline } from '@material-ui/core';
import history from './components/history';
import ProtectedRoute from './components/Auth/ProtectedRoute';
import Machine from './components/MachineDetails/';
import About from './components/About';
import Upload from './components/uploader/Upload';
import RubbleList from './components/RubbleList/index';
import './i18n';
import Portal from './components/Portal/index';
import Welcome from './components/Welcome/index';
import UploadFile from './components/uploader/UploadFile';

const App: React.FC = () => {
  console.log("In App");
  return (
    <Suspense fallback={null}>
      <div className="App">
        <ThemeProvider theme={rubbleMasterTheme}>
          <CssBaseline />
          <Router history={history}>
            <Header />
            <Switch>
              <Route exact path={routes.portal.pathName} component={Portal} />
              <Route exact path={routes.welcome.pathName} component={Welcome} />
              <Route
                exact
                path={[routes.map.pathName, routes.fleetManagement.pathName]}
              >
                <ProtectedRoute component={Dashboard} />
              </Route>
              <Route exact path={routes.about.pathName}>
                <ProtectedRoute component={About} />
              </Route>
              <Route exact path={routes.list.pathName}>
                <ProtectedRoute component={RubbleList} />
              </Route>
              {/* <Route exact path={routes.upload.pathName} component={Upload} /> */}
              {/* <Route exact path={routes.upload.pathName} component={UploadFile} /> */}
              <Route path="/fleetmanagement/machine/:id">
                <ProtectedRoute component={Machine} />
              </Route>
              <Route 
                exact 
                path={routes.cockpit.pathName} 
                component={() => {
                  // Production
                  let adr = 'https://rmcockpit.azurewebsites.net/';
                  window.location.href = adr;
                  return null;
                }}
              />
              <Route 
                exact 
                path={routes.machineConfigurator.pathName} 
                component={() => {
                  // Production
                  let adr = 'https://icy-desert-0f60c3203.5.azurestaticapps.net/';
                  // Current
                  //let adr = 'https://mango-moss-0a6bacc03.5.azurestaticapps.net/';
                  window.location.href = adr;
                  return null;
                }}
              />
              <Route exact path={routes.login.pathName}>
                <Redirect to={routes.portal.pathName} />
              </Route>

              <Route path={routes.notFound.pathName} component={NotFound} />
            </Switch>
          </Router>
        </ThemeProvider>
      </div>
    </Suspense>
  );
};

export default App;
