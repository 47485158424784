import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { chartColors, themeColors } from '../../../constants';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IReduxState } from '../../../types';
import SkeletonLoaderCardSmall from '../SkeletonLoaderCardSmall';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      flexGrow: 1,
    },
    card: {
      height: 500,
      [theme.breakpoints.up('xs')]: {
        margin: `${theme.spacing(4)}px 0`,
      },
      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px`,
      },
    },
    cardContent: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(2)}px 0`,
      },
    },
    actionsArena: {
      position: 'relative',
      height: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(4),
        justifyContent: 'start',
      },
    },
    lastUpdate: {
      position: 'absolute',
      left: theme.spacing(1),
      top: 0,
      color: themeColors.white,
    },
    title: {
      marginTop: theme.spacing(2),
    },
    gaugesWrapper: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    gaugeWrapper: {
      height: 175,
      width: 200,
    },
    noData: {
      height: 100,
      width: 200,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })
);

export default function FuelGraphGauges() {
  const classes = useStyles();
  const { t } = useTranslation();
  const averageFuelLevels = useSelector(
    (state: IReduxState) => state.machineDetails.averageFuelLevels
  );
  const isFetchingTelemetry = useSelector(
    (state: IReduxState) => state.machineDetails.isFetchingTelemtry
  );
  const lastFuelLevel = !!averageFuelLevels.fuelLevel
    ? averageFuelLevels.fuelLevel.toFixed(2)
    : null;
  const lastAdBlueLevel = !!averageFuelLevels.adBlueLevel
    ? averageFuelLevels.adBlueLevel.toFixed(2)
    : null;

  const dataDiesel = [
    {
      id: 'empty',
      color: themeColors.darkGray,
      value: !!lastFuelLevel ? 100 - +lastFuelLevel : 0,
      label: 'empty',
    },
    {
      id: 'diesel',
      color: chartColors.deepPurple,
      value: !!lastFuelLevel ? +lastFuelLevel : 100,
      label: 'diesel',
    },
  ];
  const dataAdblue = [
    {
      id: 'empty',
      color: themeColors.darkGray,
      value: !!lastAdBlueLevel ? 100 - +lastAdBlueLevel : 0,
      label: 'empty',
    },
    {
      id: 'adblue',
      color: chartColors.blue,
      value: !!lastAdBlueLevel ? +lastAdBlueLevel : 100,
      label: 'adblue',
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Card className={classes.cardContent}>
          <CardContent
            className={classes.actionsArena}
            style={{
              paddingBottom: 0,
              paddingTop: isFetchingTelemetry ? '1rem' : '2rem',
            }}
          >
            {!isFetchingTelemetry ? (
              <React.Fragment>
                <Typography className={classes.lastUpdate} variant="caption">
                  {`${t('graph.lastReceivedValue')}: ${
                    !!averageFuelLevels.timestamp
                      ? moment
                          .utc(averageFuelLevels.timestamp)
                          .local()
                          .format(`DD.MM.YYYY HH:mm`)
                      : '-'
                  }`}
                </Typography>
                <Typography
                  className={classes.title}
                  align="center"
                  variant="h5"
                >
                  {t('graph.averageFuelLevels')}
                </Typography>
                {!!averageFuelLevels ? (
                  <div className={classes.gaugesWrapper}>
                    {lastFuelLevel !== null ? (
                      <div className={classes.gaugeWrapper}>
                        <ResponsivePie
                          data={dataDiesel}
                          enableRadialLabels={false}
                          enableSlicesLabels={false}
                          isInteractive={false}
                          startAngle={90}
                          endAngle={-90}
                          innerRadius={0.5}
                          padAngle={1}
                          cornerRadius={3}
                          colors={(d: any) => d.color}
                          borderColor={{
                            from: 'color',
                            modifiers: [['darker', 0.6]],
                          }}
                          slicesLabelsSkipAngle={10}
                          slicesLabelsTextColor={themeColors.white}
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                        />
                        <Typography
                          align="center"
                          variant="body1"
                          style={{ color: `${dataDiesel[1].color}` }}
                        >
                          {`${lastFuelLevel} % ${t('graph.diesel')}`}
                        </Typography>
                      </div>
                    ) : (
                      <div className={classes.noData}>
                        <Typography
                          className={classes.title}
                          align="center"
                          variant="h6"
                          style={{ color: `${dataDiesel[1].color}` }}
                        >
                          {`${t('graph.noData')} - ${t('graph.diesel')}`}
                        </Typography>
                      </div>
                    )}
                    {lastAdBlueLevel !== null ? (
                      <div className={classes.gaugeWrapper}>
                        <ResponsivePie
                          data={dataAdblue}
                          enableRadialLabels={false}
                          enableSlicesLabels={false}
                          isInteractive={false}
                          startAngle={90}
                          endAngle={-90}
                          innerRadius={0.5}
                          padAngle={1}
                          cornerRadius={3}
                          colors={(d: any) => d.color}
                          borderColor={{
                            from: 'color',
                            modifiers: [['darker', 0.6]],
                          }}
                          slicesLabelsSkipAngle={10}
                          slicesLabelsTextColor={themeColors.white}
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                        />
                        <Typography
                          align="center"
                          variant="body1"
                          style={{ color: `${dataAdblue[1].color}` }}
                        >
                          {`${lastAdBlueLevel} % ${t('graph.adblue')}`}
                        </Typography>
                      </div>
                    ) : (
                      <div className={classes.noData}>
                        <Typography
                          className={classes.title}
                          align="center"
                          variant="h6"
                          style={{ color: `${dataAdblue[1].color}` }}
                        >
                          {`${t('graph.noData')} - ${t('graph.adblue')}`}
                        </Typography>
                      </div>
                    )}
                  </div>
                ) : (
                  <Typography
                    className={classes.title}
                    align="center"
                    variant="h5"
                  >
                    {t('graph.noData')}
                  </Typography>
                )}
              </React.Fragment>
            ) : (
              <SkeletonLoaderCardSmall />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
