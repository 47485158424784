import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  createStyles,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { languageOptions, themeColors } from '../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { IReduxState } from '../../types';
import { MeasurementSystem } from '../../rubblemaster_openapi3_typescript-fetch/models';
import { useHistory } from 'react-router';
import {
  handleUserLanguageChange,
  handleUserUnitChange,
} from '../../actions/authActions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: themeColors.darkGray,
    },
    formControl: {
      margin: theme.spacing(1),
      width: 'fit-content',
      display: 'flex',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    flag: {
      height: '1rem',
    },
  })
);

export default function TopNavigation() {
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const [language, setLanguage] = useState<string>('en-GB');
  const [units, setUnits] = useState<MeasurementSystem>(
    MeasurementSystem.Metric
  );
  const history = useHistory();
  const dispatch = useDispatch();

  // show unitsSelect on all pages of fleet management
  const showUnitsSelect =
    history.location.pathname.includes('machine') ||
    history.location.pathname.includes('map') ||
    history.location.pathname.includes('list') ||
    history.location.pathname.includes('about');

  const auth = useSelector((state: IReduxState) => state.auth);
  const isoLanguageCode = auth.user?.isoLanguageCode ?? 'en-GB';
  const measurementSystem =
    auth.user?.measurementSystem ?? MeasurementSystem.Metric;

  const changeLanguage = (selectedLanguage: string) => {
    setLanguage(selectedLanguage);
    i18n
      .changeLanguage(selectedLanguage.slice(0, 2))
      .then(() => console.log('language changed successfully'));
    if (auth.isAuthenticated && selectedLanguage !== isoLanguageCode) {
      dispatch(handleUserLanguageChange(selectedLanguage));
    }
  };

  const changeMeasurementSystem = useCallback(
    (selectedUnit?: MeasurementSystem) => {
      if (selectedUnit) {
        setUnits(selectedUnit);
        if (auth.isAuthenticated && measurementSystem !== selectedUnit) {
          dispatch(handleUserUnitChange(selectedUnit));
        }
      }
    },
    [auth.isAuthenticated, dispatch, measurementSystem]
  );

  useEffect(() => {
    if (isoLanguageCode !== language && auth.isAuthenticated) {
      changeLanguage(isoLanguageCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isoLanguageCode]);

  useEffect(() => {
    if (measurementSystem !== units) {
      changeMeasurementSystem(measurementSystem);
    }
  }, [changeMeasurementSystem, measurementSystem, units]);

  return (
    <Box className={classes.root} height="2rem" position="static">
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        style={{ height: 'inherit', paddingLeft: '16px' }}
      >
        <FormControl className={classes.formControl}>
          <Select
            labelId="select-language-label"
            id="select-language"
            value={language}
            onChange={(ev) => changeLanguage(ev.target.value as string)}
          >
            {languageOptions.map((option) => {
              return (
                <MenuItem
                  value={option.isoLanguageString}
                  key={option.isoLanguageString}
                >
                  <Tooltip
                    title={t(`general.languages.${option.isoLanguageString}`)}
                    arrow
                  >
                    <div style={{ display: 'flex' }}>
                      <img
                        className={classes.flag}
                        src={option.img}
                        alt="Selected Language flag"
                      />
                      <Typography
                        style={{ fontSize: '0.75rem', marginLeft: '0.5rem' }}
                      >
                        {t(`general.languages.${option.isoLanguageString}`)}
                      </Typography>
                    </div>
                  </Tooltip>
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
        {showUnitsSelect && (
          <FormControl className={classes.formControl}>
            <Select
              id="select-units"
              value={units}
              onChange={(ev) =>
                changeMeasurementSystem(ev.target.value as MeasurementSystem)
              }
            >
              {Object.values(MeasurementSystem).map((unitName) => (
                <MenuItem value={unitName} key={unitName}>
                  <Typography style={{ fontSize: '0.75rem' }}>
                    {unitName}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Grid>
    </Box>
  );
}
