import React from 'react';
import { IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import EmailIcon from '@material-ui/icons/Email';
import { IMachine } from '../types';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from './useWindowSize';

interface IProps {
  dense?: boolean;
  machine: IMachine;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    width: 'auto',
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
  },
  noPaddingBottom: {
    paddingBottom: 0,
  },
}));

export default function ShareMachine(props: IProps) {
  const { machine, dense } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const isDesktop =
    !!windowSize && !!windowSize.width && windowSize.width > 1024
      ? true
      : false;

  const location = {
    latitude: machine.telemetrySnapshot?.location?.latitude,
    longitude: machine.telemetrySnapshot?.location?.longitude,
  };
  const shareUrl = `https://www.google.com/maps?q=${location.latitude},${location.longitude}`;

  const handleClick = () => {
    // @ts-ignore --> No type export for share
    if (window.navigator.share) {
      window.navigator
        // @ts-ignore --> No type export for share
        .share({
          title: `Machine ${machine.serialNumber}`,
          text: `Location for Machine ${machine.serialNumber}`,
          url: shareUrl,
        });
    }
  };

  const isShareApiSupported = () => {
    // @ts-ignore --> No type export for share
    if (window.navigator.share) return true;
    return false;
  };

  const shareViaSms = (event: React.MouseEvent) => {
    event.stopPropagation();
    window.open(
      `sms:;?&body=${t('general.machine')}%20%20${machine.serialNumber} ${t(
        'general.location'
      )} ${shareUrl}`
    );
  };

  const shareViaEmail = (event: React.MouseEvent) => {
    event.stopPropagation();
    const body = `${t('general.location')}: ${shareUrl}`;
    window.open(
      `mailto:?subject=${t('general.machine')}%20${t('general.details')}%20${
        machine.serialNumber
      }&body=${body}`
    );
  };

  const copyLocation = () => {
    window.navigator.clipboard.writeText(
      `${location.latitude},${location.longitude}`
    );
  };
  if (isShareApiSupported()) {
    return (
      <div className={classes.iconWrapper}>
        <IconButton aria-label="share" onClick={handleClick} title="share">
          <ShareIcon color="action" />
        </IconButton>
        <Typography
          align="center"
          variant="caption"
          color="textSecondary"
          component="p"
        >
          {t('general.share')}
        </Typography>
      </div>
    );
  } else {
    return (
      <div className={classes.root}>
        {!isDesktop && (
          <div className={classes.iconWrapper}>
            <IconButton
              className={dense ? classes.noPaddingBottom : ''}
              aria-label="share"
              onClick={shareViaSms}
              title={t('general.shareLocationSms')}
            >
              <SmartphoneIcon color="action" />
            </IconButton>
            <Typography
              align="center"
              variant="caption"
              color="textSecondary"
              component="p"
            >
              {t('general.share')}
            </Typography>
          </div>
        )}
        <div className={classes.iconWrapper}>
          <IconButton
            className={dense ? classes.noPaddingBottom : ''}
            aria-label="share"
            onClick={shareViaEmail}
            title={t('general.shareLocationEmail')}
          >
            <EmailIcon color="action" />
          </IconButton>
          <Typography
            align="center"
            variant="caption"
            color="textSecondary"
            component="p"
          >
            {t('general.share')}
          </Typography>
        </div>
        {window.navigator.clipboard && (
          <div className={classes.iconWrapper}>
            <IconButton
              className={dense ? classes.noPaddingBottom : ''}
              onClick={copyLocation}
              title={t('general.copyToClipboard')}
            >
              <FileCopyIcon />
            </IconButton>
            <Typography
              align="center"
              variant="caption"
              color="textSecondary"
              component="p"
            >
              {t('general.copy')}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}
