import React from 'react';
import DateFnsUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';
import { useSelector, useDispatch } from 'react-redux';
import { IReduxState } from '../../types';
import {
  updateStartDate,
  updateEndDate,
  updateMachineTelemetryData,
  fetchMachineDetails,
} from '../../actions/machineDetailsActions';
import {
  Button,
  makeStyles,
  Theme,
  Card,
  CardContent,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import { useWindowSize } from '../useWindowSize';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1,
    flexGrow: 1,
  },
  card: {
    [theme.breakpoints.up('xs')]: {
      marginBottom: `${theme.spacing(2)}px`,
    },
    [theme.breakpoints.up('md')]: {
      margin: `0 ${theme.spacing(2)}px`,
    },
  },
  cardContent: {
    margin: `0 ${theme.spacing(1)}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
    justifyContent: 'space-between',
    height: '100%',
    padding: 0,
    paddingBottom: 0,
  },
  datePickerWrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'row',
    padding: 0,
  },
  datePicker: {
    margin: `${theme.spacing(2)}px`,
  },
  buttonWrapper: {
    marginBottom: `${theme.spacing(2)}px`,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      margin: `0 ${theme.spacing(2)}px`,
    },
    alignItems: 'center',
    justifyContent: 'center',
  },
  updateButtonWrapper: {
    position: 'relative',
  },
  updateButtonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

interface IProps {
  onCreateReport: any;
}

export default function GraphControls(props: IProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const size = useWindowSize();
  const startDate = useSelector(
    (state: IReduxState) => state.machineDetails.search.startDate
  );
  const endDate = useSelector(
    (state: IReduxState) => state.machineDetails.search.endDate
  );
  const isFetchingTelemtry = useSelector(
    (state: IReduxState) => state.machineDetails.isFetchingTelemtry
  );
  const machineId = useSelector(
    (state: IReduxState) => state.machineDetails.machine.id
  );

  const isSmallScreen =
    size.width !== undefined && size.width > 600 && size.width < 800
      ? true
      : false;

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Card
          style={isSmallScreen ? { marginTop: 0 } : { marginTop: 'inherit' }}
        >
          <CardContent
            className={classes.cardContent}
            style={{ paddingBottom: 0 }}
          >
            <div className={classes.datePickerWrapper}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDateTimePicker
                  className={classes.datePicker}
                  margin="normal"
                  id="start-date-picker"
                  label={t('graph.startDate')}
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  value={startDate}
                  maxDate={endDate}
                  //@ts-ignore
                  onChange={(date: Date) => dispatch(updateStartDate(date))}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  strictCompareDates
                  disableFuture                  
                />
                <KeyboardDateTimePicker
                  className={classes.datePicker}
                  margin="normal"
                  id="end-date-picker"
                  label={t('graph.endDate')}
                  ampm={false}
                  format="DD/MM/YYYY HH:mm"
                  value={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                  //@ts-ignore
                  onChange={(date: Date) => dispatch(updateEndDate(date))}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  strictCompareDates
                  disableFuture
                />
              </MuiPickersUtilsProvider>
              <div className={classes.updateButtonWrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<SearchIcon />}
                  disabled={isFetchingTelemtry}
                  onClick={() =>
                    {
                      // Update der Daten
                      dispatch(fetchMachineDetails(machineId, endDate));
                      dispatch(updateMachineTelemetryData(startDate, endDate, machineId));
                    }
                  }
                >
                  {size.width !== undefined &&
                    size.width > 700 &&
                    t('graph.update')}
                </Button>
                {isFetchingTelemtry && (
                  <CircularProgress
                    size={24}
                    className={classes.updateButtonProgress}
                  />
                )}
              </div>
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                variant="outlined"
                color="primary"
                startIcon={<GetAppIcon />}
                disabled={!!isFetchingTelemtry ? true : false}
                onClick={() => props.onCreateReport()}
              >
                {t('report.report')}
              </Button>
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
