/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
} from '../models';
import { UploadDto, UploadDtoToJSON, UploadResponseDto, UploadResponseDtoFromJSON } from '../models/UploadDto';
import { promises } from 'dns';

export interface ApiUploadPostRequest {
    uploadDto? : UploadDto;
}

/**
 * no description
 */
export class UploadApi extends runtime.BaseAPI {

    /**
     */
    async apiUploadPostRaw(requestParameters: ApiUploadPostRequest): Promise<runtime.ApiResponse<UploadResponseDto>> {
        console.log("In apiUploadPostRaw");
        console.log(requestParameters);

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Upload/upload`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UploadDtoToJSON(requestParameters.uploadDto),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadResponseDtoFromJSON(jsonValue));
    }

    /**
     */
    async apiUploadPost(requestParameters: ApiUploadPostRequest): Promise<UploadResponseDto> {
        console.log("In apiUploadPost");
        console.log(requestParameters);

        const response = await this.apiUploadPostRaw(requestParameters);

        console.log("apiUploadPostRaw completed");
        console.log(response);
        
        return await response.value();
    }

    async apiUploadFileRaw(file: File, id: string): Promise<runtime.ApiResponse<UploadResponseDto>> {
        console.log("Uploading file " + file.name);

        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        console.log("Uploading file...");
    
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", id);
    
        console.log("formData:");
        console.log(formData);

        const response = await this.request({
            path: `/api/Upload/uploadFile`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formData,
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UploadResponseDtoFromJSON(jsonValue));
    }

    async apiUploadFile(file: File, id: string): Promise<UploadResponseDto> {
        const response = await this.apiUploadFileRaw(file, id);
        return await response.value();
    }

    async apiCommitRaw(id: string): Promise<runtime.ApiResponse<UploadResponseDto>> {
        console.log("In Commit(" + id + ")" );

        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Upload/commit`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JSON.stringify(id),
        });
        return new runtime.JSONApiResponse(response, (jsonValue) => UploadResponseDtoFromJSON(jsonValue));
    }

    async apiCommit(id: string): Promise<UploadResponseDto> {
        const response = await this.apiCommitRaw(id);
        return await response.value();
    }

    async apiStateRaw(): Promise<runtime.ApiResponse<string>> {
        const queryParameters: runtime.HTTPQuery = {};
        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/api/Upload/state`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });
        return new runtime.JSONApiResponse(response);
    }

    async apiState(): Promise<string> {
        const response = await this.apiStateRaw();
        return await response.value();
    }

}
