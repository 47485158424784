import React from 'react';
import { ButtonBase, makeStyles, Theme, Typography } from '@material-ui/core';
import { themeColors } from '../../constants';
import rmDigital from '../../assets/img/rm_digital_c.jpg';
import rmFleetManagement from '../../assets/img/rm_fleetmanagement_c.jpg';
import rmDocumentation from '../../assets/img/rm_documentation_c.jpg';
import rmWebshop from '../../assets/img/rm_webshop.jpg';
import rmCockpit from '../../assets/img/rm_cockpit_c.jpg';
import rmMachineConfigurator from '../../assets/img/rm_configurator_c.jpg';
import rmInvestment from '../../assets/img/rm_investment_c.jpg';
import rmUpload from '../../assets/img/rm_investment_c.jpg';
import { useTranslation } from 'react-i18next';

interface IServiceCardProps {
  type: 'digital' | 'fleetmanagement' | 'documentation' | 'webshop' | 'cockpit' | 'machineConfigurator' | 'investmentCalculator' | 'upload';
  isActive: boolean;
  isAvailable: boolean;
  cardTitle: string;
  description?: string;
  loginButtonLabel?: string;
  onClickHandler?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  cardWrapper: {
    height: 270,
    width: 225,
    margin: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
    },
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(4)}px`,
    },
    position: 'relative',
  },
  card: {
    width: '225px',
    height: '270px',
    color: themeColors.black,
    transition: '0.1s all ease-in-out',
    transformOrigin: '50% 50%',
    backfaceVisibility: 'hidden',
    clipPath: 'polygon(100% 0, 100% 250px, 205px 100%, 0 100%, 0 0)',
    WebkitClipPath:'polygon(100% 0, 100% 250px, 205px 100%, 0 100%, 0 0)',
    '&:hover': {
      transform: 'scale(1.1)',
      boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    },
    alignItems: 'flex-start',
    zIndex: 1,
    position: 'relative',
  },
  cardDisabled: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: themeColors.white,
    left: 0,
    right: 0,
    top: 0,
    opacity: 0.5,
  },
  cardActive: {},
  cardContent: {
    color: themeColors.black,
    height: '270px',
    background: themeColors.lightGray,
  },
  textBox: {
    maxWidth: '600px',
    opacity: 0.8,
  },
  title: {
    fontFamily: '"EurostileLTStd", "Jura", "Roboto", "Helvetica", "Arial", sans-serif',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    textAlign: 'left',
    minHeight: 50,
    marginLeft: theme.spacing(1),
    lineHeight: '1.25rem',
  },
  description: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    marginLeft: theme.spacing(1),
    textAlign: 'left',
  },
  headerImage: {
    width: 208,
    height: 100,
    margin: theme.spacing(1),
  },
  label: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: theme.spacing(2),
  },
  shadowOuterHelper: {
    filter: 'drop-shadow(10px 10px 6px black)',
    width: '100%',
    height: '100%',
    top: 0,
    position: 'absolute',
  },
  shadowInnerHelper: {
    clipPath: 'polygon(100% 0, 100% 250px, 205px 100%, 0 100%, 0 0)',
    WebkitClipPath: 'polygon(100% 0, 100% 250px, 205px 100%, 0 100%, 0 0)',
    background: themeColors.lightGray,
    width: '100%',
    height: '100%',
    top: 0,
    position: 'absolute',
    zIndex: 0,
  },
}));

export default function ServiceCard(props: IServiceCardProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { type, description, cardTitle, isActive, onClickHandler, loginButtonLabel, isAvailable } = props;

  let headerImageSrc: string;

  switch (type) {
    case 'digital':
      headerImageSrc = rmDigital;
      break;
    case 'fleetmanagement':
      headerImageSrc = rmFleetManagement;
      break;
    case 'documentation':
      headerImageSrc = rmDocumentation;
      break;
    case 'webshop':
      headerImageSrc = rmWebshop;
      break;
    case 'cockpit':
      headerImageSrc = rmCockpit;
      break;
    case 'machineConfigurator':
      headerImageSrc = rmMachineConfigurator;
      break;
    case 'investmentCalculator':
      headerImageSrc = rmInvestment;
      break;
      case 'upload':
        headerImageSrc = rmUpload;
        break;
      default:
      headerImageSrc = rmDigital;
      break;
  }

  return (
    <div className={classes.cardWrapper}>
      <ButtonBase disableRipple={!isActive} className={classes.card} onClick={onClickHandler}>
        <div className={classes.cardContent}>
          <div className={isActive ? classes.cardActive : classes.cardDisabled} />
          <div
            className={classes.headerImage}
            style={{
              backgroundImage: `url(${headerImageSrc})`,
              backgroundSize: 'cover',
            }}
          />
          <Typography className={classes.title} gutterBottom variant="body1">
            {cardTitle}
          </Typography>
          {!!description && (
            <Typography className={classes.description} variant="subtitle2" component="p">
              {description}
            </Typography>
          )}
          <Typography className={classes.label} variant="button" component="p">
            {isAvailable ? loginButtonLabel : `${t('login.comingSoon')}`}
          </Typography>
        </div>
      </ButtonBase>
      <div className={classes.shadowOuterHelper}>
        <div className={classes.shadowInnerHelper} />
      </div>
    </div>
  );
}
