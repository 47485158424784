/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UnitValueDto,
    UnitValueDtoFromJSON,
    UnitValueDtoFromJSONTyped,
    UnitValueDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface TelemetryRangeAveragesDto
 */
export interface TelemetryRangeAveragesDto {
    /**
     * 
     * @type {number}
     * @memberof TelemetryRangeAveragesDto
     */
    averageEngineUtilization?: number;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    averageWeightLoad1?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    averageWeightLoad2?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    averageWeightLoad3?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    averageWeightLoad4?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    averageFuelLevel?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    averageAdBlueLevel?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    engineFuelRate?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    mainDischargeFuelRate?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    sideDischargeFuelRate?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    energyConsumptionRate?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    mainDischargeEnergyConsumptionRate?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof TelemetryRangeAveragesDto
     */
    sideDischargeEnergyConsumptionRate?: UnitValueDto | null;
    /**
     * 
     * @type {number}
     * @memberof TelemetryRangeAveragesDto
     */
    averageEnergyUtilization?: number;
}

export function TelemetryRangeAveragesDtoFromJSON(json: any): TelemetryRangeAveragesDto {
    return TelemetryRangeAveragesDtoFromJSONTyped(json, false);
}

export function TelemetryRangeAveragesDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TelemetryRangeAveragesDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'averageEngineUtilization': !exists(json, 'averageEngineUtilization') ? undefined : json['averageEngineUtilization'],
        'averageWeightLoad1': !exists(json, 'averageWeightLoad1') ? undefined : UnitValueDtoFromJSON(json['averageWeightLoad1']),
        'averageWeightLoad2': !exists(json, 'averageWeightLoad2') ? undefined : UnitValueDtoFromJSON(json['averageWeightLoad2']),
        'averageWeightLoad3': !exists(json, 'averageWeightLoad3') ? undefined : UnitValueDtoFromJSON(json['averageWeightLoad3']),
        'averageWeightLoad4': !exists(json, 'averageWeightLoad4') ? undefined : UnitValueDtoFromJSON(json['averageWeightLoad4']),
        'averageFuelLevel': !exists(json, 'averageFuelLevel') ? undefined : UnitValueDtoFromJSON(json['averageFuelLevel']),
        'averageAdBlueLevel': !exists(json, 'averageAdBlueLevel') ? undefined : UnitValueDtoFromJSON(json['averageAdBlueLevel']),
        'engineFuelRate': !exists(json, 'engineFuelRate') ? undefined : UnitValueDtoFromJSON(json['engineFuelRate']),
        'mainDischargeFuelRate': !exists(json, 'mainDischargeFuelRate') ? undefined : UnitValueDtoFromJSON(json['mainDischargeFuelRate']),
        'sideDischargeFuelRate': !exists(json, 'sideDischargeFuelRate') ? undefined : UnitValueDtoFromJSON(json['sideDischargeFuelRate']),
        'energyConsumptionRate': !exists(json, 'energyConsumptionRate') ? undefined : UnitValueDtoFromJSON(json['energyConsumptionRate']),
        'mainDischargeEnergyConsumptionRate': !exists(json, 'mainDischargeEnergyConsumptionRate') ? undefined : UnitValueDtoFromJSON(json['mainDischargeEnergyConsumptionRate']),
        'sideDischargeEnergyConsumptionRate': !exists(json, 'sideDischargeEnergyConsumptionRate') ? undefined : UnitValueDtoFromJSON(json['sideDischargeEnergyConsumptionRate']),
        'averageEnergyUtilization': !exists(json, 'averageEnergyUtilization') ? undefined : json['averageEnergyUtilization'],
    };
}

export function TelemetryRangeAveragesDtoToJSON(value?: TelemetryRangeAveragesDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'averageEngineUtilization': value.averageEngineUtilization,
        'averageWeightLoad1': UnitValueDtoToJSON(value.averageWeightLoad1),
        'averageWeightLoad2': UnitValueDtoToJSON(value.averageWeightLoad2),
        'averageWeightLoad3': UnitValueDtoToJSON(value.averageWeightLoad3),
        'averageWeightLoad4': UnitValueDtoToJSON(value.averageWeightLoad4),
        'averageFuelLevel': UnitValueDtoToJSON(value.averageFuelLevel),
        'averageAdBlueLevel': UnitValueDtoToJSON(value.averageAdBlueLevel),
        'engineFuelRate': UnitValueDtoToJSON(value.engineFuelRate),
        'mainDischargeFuelRate': UnitValueDtoToJSON(value.mainDischargeFuelRate),
        'sideDischargeFuelRate': UnitValueDtoToJSON(value.sideDischargeFuelRate),
        'energyConsumptionRate': UnitValueDtoToJSON(value.energyConsumptionRate),
        'mainDischargeEnergyConsumptionRate': UnitValueDtoToJSON(value.mainDischargeEnergyConsumptionRate),
        'sideDischargeEnergyConsumptionRate': UnitValueDtoToJSON(value.sideDischargeEnergyConsumptionRate),
        'averageEnergyUtilization': value.averageEnergyUtilization,
    };
}


