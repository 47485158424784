import React from 'react';
import { ListItemIcon, Typography } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import { useTranslation } from 'react-i18next';
import { themeColors } from '../../constants';
import { IRouteProps } from '../../types';

export default function MuiMenuAbout(props: IRouteProps) {
  const { t } = useTranslation();
  return (
    <div style={props.isActive ? { color: themeColors.yellow } : {}}>
      <ListItemIcon>
        <HelpIcon fontSize="small" />
      </ListItemIcon>
      <Typography variant="inherit">{t('navigation.about')}</Typography>
    </div>
  );
}
