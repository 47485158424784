import React, { useCallback, useState } from 'react';
import { ResponsiveLine } from '@nivo/line';
import { chartColors, sensorInterval, themeColors } from '../../../constants';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import moment, { RFC_2822 } from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IReduxState, ISampledTelemetry, IChartProps } from '../../../types';
import { IPoint } from '../../../types';
import { useWindowSize } from '../../useWindowSize';
import SkeletonLoaderGraph from '../SkeletonLoaderGraph';
import { ActivePointLayer } from '../../ActivePointLayer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      flexGrow: 1,
    },
    card: {
      height: 500,
      [theme.breakpoints.up('xs')]: {
        margin: `${theme.spacing(4)}px 0`,
      },
      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px`,
      },
    },
    cardContent: {
      height: '100%',
    },
    actionsArena: {
      position: 'relative',
      height: '100%',
      paddingTop: 0,
    },
    tooltipWrapper: {
      position: 'absolute',
      background: themeColors.white,
      opacity: 0.9,
      padding: theme.spacing(1),
      color: theme.palette.secondary.main,
      zIndex: 100,
      top: 20,
      left: -60,
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(2),
        paddingRight: 0,
        width: 160,
        top: 20,
        left: -70,
      },
    },
    tooltipLabel: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    center: {
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      position: 'absolute',
      width: '100%',
      top: theme.spacing(2),
    },
  })
);

export default function ThroughputLine(props: IChartProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const size = useWindowSize();
  const { isPdfReport } = props;
  let isSmallScreen = size.width !== undefined && size.width < 800;

  // Set isSmallScreen to false for the charts used in the PDF report
  isSmallScreen = isPdfReport === true ? false : isSmallScreen;

  const graphMargin = { top: 50, right: 80, bottom: 100, left: 50 };
  const graphMarginMobile = { top: 25, right: 25, bottom: 150, left: 50 };

  const isHybrid = useSelector(
    (state: IReduxState) =>
      !!state.machineDetails.machine.telemetrySnapshot?.isHybrid
  );

  const isFetchingTelemetry = useSelector(
    (state: IReduxState) => state.machineDetails.isFetchingTelemtry
  );
  const telemetry = useSelector(
    (state: IReduxState) => state.machineDetails.telemetry
  );
  const maxBeltScales = useSelector(
    (state: IReduxState) => state.machineDetails.machine.machineType.maxBeltScales ?? 4
  );
  const telemetryData: ISampledTelemetry[] | any = !!telemetry ? telemetry : [];
  // const graphTickValues = useSelector((state: IReduxState) => state.machineDetails.graphTickValues);
  const graphDateFormat = useSelector(
    (state: IReduxState) => state.machineDetails.graphDateFormat
  );

  // animate point on hover
  const [current, setCurrent] = useState(null);
  const handleHover = useCallback(
    (point) => {
      setCurrent(point);
    },
    [setCurrent]
  );

  const weightLoadFiltered = telemetryData.filter(
    (item: ISampledTelemetry) => item.weightLoad1
  );
  const weightLoadUnit = weightLoadFiltered.length
    ? weightLoadFiltered[0].weightLoad1.unit
    : t('graph.t/h');

  // **********************************************************************************
  // aktuell nur 2, 3 und 4 vorsehen
  // Liste bei maxBeltScales = 2 und weniger gefüllt, sonst leer
  const chartData2 = [
      {
        id: `${t('graph.mainDischargeConveyorShort')}`,
        color: chartColors.cyan,
        data: telemetryData
          .filter(
            (item: ISampledTelemetry, index: number) =>
              (!item.isEmpty && item.weightLoad1 !== null) ||
              (item.durationInSeconds &&
                item.durationInSeconds >
                  sensorInterval.weigher1CurrentDisplayed) ||
              // TODO KANM remove when updating NIVO and add min/max to xScale
              // enable first and last items
              index === 0 ||
              index === telemetryData.length - 1
          )
          .map((item: ISampledTelemetry) => {
            return {
              x: moment
                .utc(item.timestamp)
                .local()
                .format('YYYY-MM-DD HH:mm:ss.SSS'),
              y: item.weightLoad1?.value
                ? item.weightLoad1.value.toFixed(2)
                : item.weightLoad1?.value ?? null,
            };
          }),
      },
      {
        id: `${t('graph.secondDischargeConveyorShort')}`,
        color: chartColors.green,
        data: telemetryData
          .filter(
            (item: ISampledTelemetry, index: number) =>
              (!item.isEmpty && item.weightLoad2 !== null) ||
              (item.durationInSeconds &&
                item.durationInSeconds >
                  sensorInterval.weigher2CurrentDisplayed) ||
              // TODO KANM remove when updating NIVO and add min/max to xScale
              // enable first and last items
              index === 0 ||
              index === telemetryData.length - 1
          )
          .map((item: ISampledTelemetry) => {
            return {
              x: moment
                .utc(item.timestamp)
                .local()
                .format('YYYY-MM-DD HH:mm:ss.SSS'),
              y: item.weightLoad2?.value
                ? item.weightLoad2.value.toFixed(2)
                : item.weightLoad2?.value ?? null,
            };
          }),
      },
    ];

  // **********************************************************************************
  // Liste bei maxBeltScales = 3 gefüllt, sonst leer
  const chartData3 = [
    {
      id: `${t('graph.mainDischargeConveyorShort')}`,
      color: chartColors.cyan,
      data: telemetryData
        .filter(
          (item: ISampledTelemetry, index: number) =>
            (!item.isEmpty && item.weightLoad1 !== null) ||
            (item.durationInSeconds &&
              item.durationInSeconds >
                sensorInterval.weigher1CurrentDisplayed) ||
            // TODO KANM remove when updating NIVO and add min/max to xScale
            // enable first and last items
            index === 0 ||
            index === telemetryData.length - 1
        )
        .map((item: ISampledTelemetry) => {
          return {
            x: moment
              .utc(item.timestamp)
              .local()
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            y: item.weightLoad1?.value
              ? item.weightLoad1.value.toFixed(2)
              : item.weightLoad1?.value ?? null,
          };
        }),
    },
    {
      id: `${t('graph.secondDischargeConveyorShort')}`,
      color: chartColors.green,
      data: telemetryData
        .filter(
          (item: ISampledTelemetry, index: number) =>
            (!item.isEmpty && item.weightLoad2 !== null) ||
            (item.durationInSeconds &&
              item.durationInSeconds >
                sensorInterval.weigher2CurrentDisplayed) ||
            // TODO KANM remove when updating NIVO and add min/max to xScale
            // enable first and last items
            index === 0 ||
            index === telemetryData.length - 1
        )
        .map((item: ISampledTelemetry) => {
          return {
            x: moment
              .utc(item.timestamp)
              .local()
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            y: item.weightLoad2?.value
              ? item.weightLoad2.value.toFixed(2)
              : item.weightLoad2?.value ?? null,
          };
        }),
    },
    {
      id: `${t('graph.thirdDischargeConveyorShort')}`,
      color: chartColors.orange,
      data: telemetryData
        .filter(
          (item: ISampledTelemetry, index: number) =>
            (!item.isEmpty && item.weightLoad3 !== null) ||
            (item.durationInSeconds &&
              item.durationInSeconds >
                sensorInterval.weigher3CurrentDisplayed) ||
            // TODO KANM remove when updating NIVO and add min/max to xScale
            // enable first and last items
            index === 0 ||
            index === telemetryData.length - 1
        )
        .map((item: ISampledTelemetry) => {
          return {
            x: moment
              .utc(item.timestamp)
              .local()
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            y: item.weightLoad3?.value
              ? item.weightLoad3.value.toFixed(2)
              : item.weightLoad3?.value ?? null,
          };
        }),
    },
  ];

  // **********************************************************************************
  // Liste bei maxBeltScales = 4 gefüllt, sonst leer
  const chartData4 = [
    {
      id: `${t('graph.mainDischargeConveyorShort')}`,
      color: chartColors.cyan,
      data: telemetryData
        .filter(
          (item: ISampledTelemetry, index: number) =>
            (!item.isEmpty && item.weightLoad1 !== null) ||
            (item.durationInSeconds &&
              item.durationInSeconds >
                sensorInterval.weigher1CurrentDisplayed) ||
            // TODO KANM remove when updating NIVO and add min/max to xScale
            // enable first and last items
            index === 0 ||
            index === telemetryData.length - 1
        )
        .map((item: ISampledTelemetry) => {
          return {
            x: moment
              .utc(item.timestamp)
              .local()
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            y: item.weightLoad1?.value
              ? item.weightLoad1.value.toFixed(2)
              : item.weightLoad1?.value ?? null,
          };
        }),
    },
    {
      id: `${t('graph.secondDischargeConveyorShort')}`,
      color: chartColors.green,
      data: telemetryData
        .filter(
          (item: ISampledTelemetry, index: number) =>
            (!item.isEmpty && item.weightLoad2 !== null) ||
            (item.durationInSeconds &&
              item.durationInSeconds >
                sensorInterval.weigher2CurrentDisplayed) ||
            // TODO KANM remove when updating NIVO and add min/max to xScale
            // enable first and last items
            index === 0 ||
            index === telemetryData.length - 1
        )
        .map((item: ISampledTelemetry) => {
          return {
            x: moment
              .utc(item.timestamp)
              .local()
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            y: item.weightLoad2?.value
              ? item.weightLoad2.value.toFixed(2)
              : item.weightLoad2?.value ?? null,
          };
        }),
    },
    {
      id: `${t('graph.thirdDischargeConveyorShort')}`,
      color: chartColors.orange,
      data: telemetryData
        .filter(
          (item: ISampledTelemetry, index: number) =>
            (!item.isEmpty && item.weightLoad3 !== null) ||
            (item.durationInSeconds &&
              item.durationInSeconds >
                sensorInterval.weigher3CurrentDisplayed) ||
            // TODO KANM remove when updating NIVO and add min/max to xScale
            // enable first and last items
            index === 0 ||
            index === telemetryData.length - 1
        )
        .map((item: ISampledTelemetry) => {
          return {
            x: moment
              .utc(item.timestamp)
              .local()
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            y: item.weightLoad3?.value
              ? item.weightLoad3.value.toFixed(2)
              : item.weightLoad3?.value ?? null,
          };
        }),
    },
    {
      id: `${t('graph.fourthDischargeConveyorShort')}`,
      color: chartColors.lime,
      data: telemetryData
        .filter(
          (item: ISampledTelemetry, index: number) =>
            (!item.isEmpty && item.weightLoad4 !== null) ||
            (item.durationInSeconds 
            && item.durationInSeconds > sensorInterval.weigher4CurrentDisplayed) 
              ||
            // TODO KANM remove when updating NIVO and add min/max to xScale
            // enable first and last items
            index === 0 ||
            index === telemetryData.length - 1
        )
        .map((item: ISampledTelemetry) => {
          return {
            x: moment
              .utc(item.timestamp)
              .local()
              .format('YYYY-MM-DD HH:mm:ss.SSS'),
            y: item.weightLoad4?.value
              ? item.weightLoad4?.value.toFixed(2)
              : item.weightLoad4?.value ?? null,
          };
        }),
      },
  ];

  // **********************************************************************************
  // entsprechend der übergebenen maxBeltScales wird die korrekte Datenliste ausgewählt
  // var chartData = maxBeltScales === 4
  //   ? chartData4
  //   : maxBeltScales === 3
  //     ? chartData3
  //     : chartData2
  //   ;

  const CustomTooltip = (pointData: IPoint) => {
    const { data, serieId, serieColor } = pointData.point;
    return (
      <div className={classes.tooltipWrapper}>
        <div className={classes.tooltipLabel}>
          <div style={{ background: `${serieColor}`, width: 10, height: 10 }} />
          <span style={{ color: `${serieColor}`, marginLeft: 8 }}>
            {serieId}
          </span>
        </div>
        <div>
          <span style={{ color: `${serieColor}` }}>{t('graph.date')}: </span>
          <span>{moment(data.x).format('DD.MM.YYYY')}</span>
        </div>
        <div>
          <span style={{ color: `${serieColor}` }}>{t('graph.time')}: </span>
          <span>{moment(data.x).format('HH:mm:ss')}</span>
        </div>
        <div>
          <span style={{ color: `${serieColor}` }}>{`${t(
            'graph.throughput'
          )}: `}</span>
          <span>{`${data.y} ${weightLoadUnit}`}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.card}>
        <Card
          className={classes.cardContent}
          style={{
            boxShadow:
              isHybrid && !isPdfReport
                ? `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`
                : 'none',
          }}
        >
          <CardContent
            className={classes.actionsArena}
            style={{ paddingBottom: 0 }}
          >
            {!isFetchingTelemetry 
            ? (
              <React.Fragment>
                {telemetryData.length === 0 ||
                (telemetryData.weightLoad1 === null &&
                  telemetryData.weightLoad2 === null &&
                  telemetryData.weightLoad3 === null &&
                  telemetryData.weightLoad4 === null) 
                ? (
                  <div className={classes.center}>
                    <Typography align="center" variant="h4">
                      {t('graph.throughput')}
                    </Typography>
                    <Typography align="center" variant="h5">
                      {t('graph.noData')}
                    </Typography>
                  </div>
                  ) 
                : (
                  <React.Fragment>
                    {!isSmallScreen && (
                      <div className={classes.title}>
                        <Typography align="center" variant="h6">
                          {t('graph.throughput')}
                        </Typography>
                      </div>
                    )}
                    <ResponsiveLine
                      theme={{
                        tooltip: {
                          container: {
                            background: '#2d374d',
                            color: 'inherit',
                            boxShadow: '0 3px 9px rgba(0, 0, 0, 0.5)',
                          },
                        },
                        axis: {
                          legend: {
                            text: {
                              fill: themeColors.white,
                            },
                          },
                          ticks: {
                            line: {
                              stroke: chartColors.blueGray,
                            },
                            text: {
                              fill: themeColors.white,
                            },
                          },
                        },
                      }}
                      data={maxBeltScales === 4 ? chartData4.reverse() : maxBeltScales === 3 ? chartData3.reverse() : chartData2.reverse()}
                      animate={false}
                      colors={(d: any) => d.color}
                      lineWidth={2}
                      margin={isSmallScreen ? graphMarginMobile : graphMargin}
                      xScale={{
                        type: 'time',
                        format: '%Y-%m-%d %H:%M:%S.%L',
                        precision: 'second',
                        useUTC: false,
                      }}
                      xFormat="time:%Y-%m-%d %H:%M:%S.%L"
                      yScale={{ type: 'linear', min: 0 }}
                      curve="monotoneX"
                      axisTop={null}
                      axisRight={null}
                      enablePoints={true}
                      layers={[
                        'grid',
                        'markers',
                        'axes',
                        'areas',
                        'lines',
                        'slices',
                        'points',
                        'mesh',
                        'legends',
                        () => <ActivePointLayer point={current} />,
                      ]}
                      //@ts-ignore
                      axisBottom={{
                        orient: 'bottom',
                        tickSize: 10,
                        tickPadding: 40,
                        tickRotation: 270,
                        legendOffset: 60,
                        legendPosition: 'start',
                        format: (tick) => moment(tick).format(graphDateFormat),
                        // tickValues: graphTickValues,
                      }}
                      tooltip={CustomTooltip}
                      onMouseEnter={handleHover}
                      onMouseMove={handleHover}
                      onMouseLeave={handleHover}
                      axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: `${t('graph.throughput')} [${weightLoadUnit}]`,
                        legendOffset: -40,
                        legendPosition: 'middle',
                      }}
                      enableGridX={false}
                      enableCrosshair={false}
                      pointSize={2}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: 'serieColor' }}
                      pointLabel="x"
                      pointLabelYOffset={-12}
                      useMesh={true}
                      enableSlices={false}
                      legends={[
                        {
                          anchor: 'bottom',
                          direction: isSmallScreen ? 'column' : 'row',
                          justify: false,
                          translateX: 0,
                          translateY: isSmallScreen ? 140 : 85,
                          itemsSpacing: 0,
                          itemDirection: 'left-to-right',
                          itemWidth: 150,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          itemTextColor: '#fff',
                          symbolSize: 10,
                          symbolShape: 'circle',
                          symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        },
                      ]}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            ) : (
              <SkeletonLoaderGraph />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
