import React, { useEffect, useState } from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  IconButton,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IReduxState, IMachineStateOption } from '../../types';
import { useTranslation } from 'react-i18next';
import { updateMachineState } from '../../actions/machineDetailsActions';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 200,
  },
  dialog: {
    padding: `${theme.spacing(4)}px`,
  },
  buttonWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  saveButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    marginTop: `${theme.spacing(4)}px`,
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'column',
    margin: 0,
  },
}));

export interface IMachineStateDialogProps {
  open: boolean;
  onClose: () => void;
}

function MachineStateDialog(props: IMachineStateDialogProps) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { onClose, open } = props;
  const dispatch = useDispatch();
  const machineStateOptions = useSelector(
    (state: IReduxState) => state.search.machineStateOptions
  );
  const machineState = useSelector(
    (state: IReduxState) => state.machineDetails.machine.state
  );
  const machineId = useSelector(
    (state: IReduxState) => state.machineDetails.machine.id
  );
  const [selectedMachineState, setSelectedMachineState] = useState<
    IMachineStateOption
  >();

  const handleChange = (id: number) => {
    const selectedMachineStateOption = machineStateOptions.filter(
      (item: any) => item.id === id
    )[0];
    setSelectedMachineState(selectedMachineStateOption);
  };

  const saveMachineState = () => {
    dispatch(updateMachineState(machineId as number, selectedMachineState));
    handleClose();
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    setSelectedMachineState(machineState as IMachineStateOption);
  }, [machineState]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="machine-state-dialog"
      open={open}
    >
      <div className={classes.dialog}>
        <DialogTitle id="machine-state-dialog">
          {t('machine.editMachineState')}
        </DialogTitle>
        {machineState ? (
          <div className={classes.root}>
            <FormControl>
              <InputLabel id="machine-state">
                {t('search.machineState.select')}
              </InputLabel>
              {selectedMachineState && (
                <Select
                  labelId="machine-state-label"
                  id="machine-state"
                  value={selectedMachineState.id}
                  onChange={(event: React.ChangeEvent<{ value: any }>) =>
                    handleChange(event.target.value)
                  }
                >
                  {machineStateOptions.map(
                    (item: IMachineStateOption, index: number) => (
                      <MenuItem
                        key={`state-${index}-${item.id}`}
                        value={item.id}
                      >
                        {i18n.exists(`machine.state.${item.key}`) ? t(`machine.state.${item.key}`) : item.key }
                      </MenuItem>
                    )
                  )}
                </Select>
              )}
            </FormControl>
            <div className={classes.saveButton}>
              <Button
                variant="outlined"
                color="primary"
                onClick={saveMachineState}
              >
                {t('controls.save')}
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </Dialog>
  );
}

export default function EditMachineState() {
  const classes = useStyles();
  const [open, setOpen] = React.useState<boolean>(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className={classes.iconWrapper}>
        <IconButton
          size="small"
          aria-label={t('controls.edit')}
          onClick={handleClickOpen}
          title={t('controls.edit')}
        >
          <EditIcon color="action" />
        </IconButton>
      </div>
      <MachineStateDialog open={open} onClose={handleClose} />
    </div>
  );
}
