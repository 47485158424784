import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { chartColors, themeColors } from '../../../constants';
import {
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IChartProps, IReduxState } from '../../../types';
import SkeletonLoaderCardSmall from '../SkeletonLoaderCardSmall';
import TotalHours from '../TotalHours';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 1,
      flexGrow: 1,
    },
    card: {
      height: 500,
      [theme.breakpoints.up('xs')]: {
        margin: `${theme.spacing(4)}px 0`,
      },
      [theme.breakpoints.up('sm')]: {
        margin: `${theme.spacing(2)}px`,
      },
    },
    cardContent: {
      height: '100%',
      [theme.breakpoints.up('md')]: {
        padding: `${theme.spacing(2)}px 0`,
      },
    },
    actionsArena: {
      position: 'relative',
      height: '100%',
      paddingTop: 0,
      paddingBottom: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    lastUpdate: {
      position: 'absolute',
      left: theme.spacing(1),
      top: 0,
      color: themeColors.white,
    },
    title: {
      margin: theme.spacing(2),
      marginBottom: '-1.5rem', // move title in chart to avoid making chart smaller
    },
    gaugesWrapper: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    gaugeWrapper: {
      height: 175,
      width: 200,
    },
    electricGaugeWrapper: {
      top: 16,
      width: 150,
      height: 170,
      position: 'absolute',
    },
    noDataWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 150,
      width: 200,
    },
  })
);

export default function EngineUtilizationGaugeHybrid(props: IChartProps) {
  const { isPdfReport } = props;
  const classes = useStyles();
  const { t } = useTranslation(['translation']);
  const { averageEngineUtilization, averageEnergyUtilization } = useSelector(
    (state: IReduxState) => state.machineDetails
  );

  const lastEngineUtilization =
    averageEngineUtilization.value !== null
      ? averageEngineUtilization.value.toFixed(2)
      : 0;
  const lastEnergyUtilization =
    averageEnergyUtilization.value !== null
      ? averageEnergyUtilization.value.toFixed(2)
      : 0;
  const isFetchingTelemetry = useSelector(
    (state: IReduxState) => state.machineDetails.isFetchingTelemtry
  );
  // default: false
  const isJawCrusher = useSelector(
    (state: IReduxState) => state.machineDetails.machine.machineType.isJawCrusher ?? false
  )

  console.log("***************************************************************************");
  console.log("In EngineUtilizationGaugeHybrid");
  console.log("***************************************************************************");

  console.log(t);

  const averageEngineLoadsTxt = t('graph.averageEngineLoads');
  const averageEnergyUtilizationTxt = t('graph.averageEnergyUtilization');
  const dieselEngineLoadsTxt = t('graph.dieselEngineLoads');
  //const dieselEngineUtilizationTxt = t('graph.dieselEngineUtilization');
  const electricDriveLoadsTxt = t('graph.electricDriveLoads');
  //const electricDriveUtilizationTxt = t('graph.electricDriveUtilization');
    
  // console.log("averageEngineLoadsTxt : " + averageEngineLoadsTxt);
  // console.log("averageEnergyUtilizationTxt : " + averageEnergyUtilizationTxt);
  // console.log("dieselEngineLoadsTxt : " + dieselEngineLoadsTxt);
  // console.log("dieselEngineUtilizationTxt : " + dieselEngineUtilizationTxt);
  // console.log("electricDriveLoadsTxt : " + electricDriveLoadsTxt);
  // console.log("electricDriveUtilizationTxt : " + electricDriveUtilizationTxt);
  // console.log(t('graph.averageEngineLoads'));


  const graphData = [
    {
      id: 'empty',
      color: themeColors.darkGray,
      value: 100 - +lastEngineUtilization,
      label: 'empty',
    },
    {
      id: 'averageEngineUtilization',
      color: chartColors.rmYellow,
      value: +lastEngineUtilization,
      label: 'Engine Utilization',
    },
  ];
  const graphDataLoads = [
    {
      id: 'empty',
      color: themeColors.darkGray,
      value: 100 - +lastEngineUtilization,
      label: 'empty',
    },
    {
      id: 'averageEngineUtilization',
      color: chartColors.rmYellow,
      value: +lastEngineUtilization,
      label: 'Engine Loads',
    },
  ];
  const electricGraphData = [
    {
      id: 'empty',
      color: themeColors.darkGray,
      value: 100 - +lastEnergyUtilization,
      label: 'empty',
    },
    {
      id: 'averageEngineUtilization',
      color: chartColors.electricBlue,
      value: +lastEnergyUtilization,
      label: 'Engine Utilization',
    },
  ];
  const electricGraphDataLoads = [
    {
      id: 'empty',
      color: themeColors.darkGray,
      value: 100 - +lastEnergyUtilization,
      label: 'empty',
    },
    {
      id: 'averageEngineUtilization',
      color: chartColors.electricBlue,
      value: +lastEnergyUtilization,
      label: 'Engine Loads',
    },
  ];

  return (
    <div className={classes.root}>
      <div
        className={classes.card}
        style={{
          boxShadow: isPdfReport
            ? 'none'
            : `0.25rem 0.25rem 0 0 ${chartColors.electricBlue}`,
        }}
      >
        <Card className={classes.cardContent}>
          <CardContent
            className={classes.actionsArena}
            style={{
              paddingBottom: 0,
            }}
          >
            {!isFetchingTelemetry ? (
              <React.Fragment>
                <Typography className={classes.lastUpdate} variant="caption">
                  {`${t('graph.lastReceivedValue')}: ${
                    !!averageEngineUtilization.timestamp
                      ? moment
                          .utc(averageEngineUtilization.timestamp)
                          .local()
                          .format(`DD.MM.YYYY HH:mm`)
                      : '-'
                  }`}
                </Typography>
                <Typography
                  className={classes.title}
                  align="center"
                  variant="h5"
                >
                  {averageEnergyUtilizationTxt}
                </Typography>

                {averageEngineUtilization.value !== null ? (
                  <React.Fragment>
                    <div className={classes.gaugesWrapper}>
                      <div className={classes.gaugeWrapper}>
                        <ResponsivePie
                          data={graphDataLoads}
                          enableRadialLabels={false}
                          enableSlicesLabels={false}
                          isInteractive={false}
                          startAngle={90}
                          endAngle={-90}
                          innerRadius={0.8}
                          padAngle={1}
                          cornerRadius={3}
                          colors={(d: any) => d.color}
                          borderColor={{
                            from: 'color',
                            modifiers: [['darker', 0.6]],
                          }}
                          slicesLabelsSkipAngle={10}
                          slicesLabelsTextColor={themeColors.white}
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                        />
                      </div>
                      <div className={classes.electricGaugeWrapper}>
                        <ResponsivePie
                          data={isJawCrusher ? electricGraphDataLoads : electricGraphData}
                          enableRadialLabels={false}
                          enableSlicesLabels={false}
                          isInteractive={false}
                          startAngle={90}
                          endAngle={-90}
                          innerRadius={0.75}
                          padAngle={1}
                          cornerRadius={3}
                          colors={(d: any) => d.color}
                          borderColor={{
                            from: 'color',
                            modifiers: [['darker', 0.6]],
                          }}
                          slicesLabelsSkipAngle={10}
                          slicesLabelsTextColor={themeColors.white}
                          animate={true}
                          motionStiffness={90}
                          motionDamping={15}
                        />
                      </div>
                    </div>
                    <div>
                      <Typography
                        align="center"
                        variant="body1"
                        style={{ color: `${graphData[1].color}` }}
                      >
                        {graphData[1].value} %{' '}
                        {dieselEngineLoadsTxt}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body1"
                        style={{ color: `${electricGraphData[1].color}` }}
                      >
                        {electricGraphData[1].value} %{' '}
                        {electricDriveLoadsTxt}
                      </Typography>
                    </div>
                  </React.Fragment>
                ) : (
                  <div className={classes.noDataWrapper}>
                    <Typography
                      align="center"
                      variant="h5"
                      style={{ color: chartColors.electricBlue }}
                    >
                      {t('graph.noData')}
                    </Typography>
                  </div>
                )}
                <TotalHours isPdfReport isHybrid />
              </React.Fragment>
            ) : (
              <SkeletonLoaderCardSmall />
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
