import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import { makeStyles } from '@material-ui/styles';
import { routes } from '../../constants/routes';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    right: '1rem',
    zIndex: 9999,
    top: 0,
    width: 80,
    [theme.breakpoints.up('sm')]: {
      width: 100
    }
  }
}));

export default function Logo() {
  const classes = useStyles();
  return (
    <Link to={routes.portal.pathName}>
      <img src={logo} alt="logo" className={classes.root} />
    </Link>
  )
}
