import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RouterLink } from 'react-router-dom';
import { routes } from '../constants';
import { makeStyles, Theme, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IReduxState } from '../types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: `${theme.spacing(2)}px 0`,
      width: 250,
      [theme.breakpoints.up('sm')]: {
        width: '100%',
      },
    },
    activeRouteLink: {
      color: theme.palette.primary.light,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    nonActiveRouteLink: {
      color: theme.palette.primary.dark,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  })
);

interface IProps {
  home?: string;
  current: string;
}

export default function RubbleBreadcrumbs(props: IProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  const machine = useSelector(
    (state: IReduxState) => state.machineDetails.machine
  );

  const { current, home } = props;

  let routeName: string;

  if (current.includes('machine')) {
    routeName = `${t('navigation.machine')} / ${machine.serialNumber}`;
  } else {
    routeName = current.slice(16).charAt(1).toUpperCase() + current.slice(18);
  }

  return (
    <div className={classes.root}>
      <Breadcrumbs aria-label="breadcrumb">
        {home ? (
          <RouterLink
            className={classes.nonActiveRouteLink}
            to={routes.portal.pathName}
          >
            {t('navigation.portal')}
          </RouterLink>
        ) : (
          <div>
            <RouterLink
              className={classes.nonActiveRouteLink}
              to={routes.portal.pathName}
            >
              {`${t('navigation.portal')} `}
            </RouterLink>
            <span> / </span>
            <RouterLink
              className={classes.nonActiveRouteLink}
              to={routes.map.pathName}
            >
              {t('navigation.map')}
            </RouterLink>
          </div>
        )}
        <RouterLink
          className={classes.activeRouteLink}
          aria-current="page"
          to={props.current}
        >
          {routeName}
        </RouterLink>
      </Breadcrumbs>
    </div>
  );
}
