/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MeasurementSystem,
    MeasurementSystemFromJSON,
    MeasurementSystemFromJSONTyped,
    MeasurementSystemToJSON,
    OrganizationDto,
    OrganizationDtoFromJSON,
    OrganizationDtoFromJSONTyped,
    OrganizationDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {number}
     * @memberof UserDto
     */
    id: number;
    /**
     * 
     * @type {OrganizationDto}
     * @memberof UserDto
     */
    organization?: OrganizationDto | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserDto
     */
    isAdmin: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    isoLanguageCode?: string | null;
    /**
     * 
     * @type {MeasurementSystem}
     * @memberof UserDto
     */
    measurementSystem?: MeasurementSystem;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    organizationAccessLevel?: string | null;
}

export function UserDtoFromJSON(json: any): UserDto {
    return UserDtoFromJSONTyped(json, false);
}

export function UserDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organization': !exists(json, 'organization') ? undefined : OrganizationDtoFromJSON(json['organization']),
        'isAdmin': json['isAdmin'],
        'email': json['email'],
        'isoLanguageCode': !exists(json, 'isoLanguageCode') ? undefined : json['isoLanguageCode'],
        'measurementSystem': !exists(json, 'measurementSystem') ? undefined : MeasurementSystemFromJSON(json['measurementSystem']),
        'organizationAccessLevel': !exists(json, 'organizationAccessLevel') ? undefined : json['organizationAccessLevel'],
    };
}

export function UserDtoToJSON(value?: UserDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'organization': OrganizationDtoToJSON(value.organization),
        'isAdmin': value.isAdmin,
        'email': value.email,
        'isoLanguageCode': value.isoLanguageCode,
        'measurementSystem': MeasurementSystemToJSON(value.measurementSystem),
        'organizationAccessLevel': value.organizationAccessLevel,
    };
}


