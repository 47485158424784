import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import {
  IMachine,
  ITelemetrySnapshot,
  IState,
  IReduxState,
} from '../../types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Paper,
  Chip,
  Link,
  Button,
} from '@material-ui/core';
import { themeColors } from '../../constants';
import TuneIcon from '@material-ui/icons/Tune';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getSuggestedAddressForMachine } from '../../actions/machineActions';
import { useDispatch, useSelector } from 'react-redux';

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: ISortableMachine[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Order = 'asc' | 'desc';

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (
  a: { [key in K]: number | string },
  b: { [key in K]: number | string }
) => number {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

interface HeadCell {
  disablePadding: boolean;
  id: string | number;
  label: string;
  numeric: boolean;
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: string | number
  ) => void;
  order: Order;
  orderBy: string | number;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: string | number) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  const { t } = useTranslation();

  const headCells: HeadCell[] = [
    {
      id: 'serialNumber',
      numeric: true,
      disablePadding: false,
      label: t('list.srn'),
    },
    {
      id: 'totalHoursOfCrusherOperation',
      numeric: true,
      disablePadding: false,
      label: t('list.totalOperationHours'),
    },
    {
      id: 'isIgnitionOn',
      numeric: false,
      disablePadding: false,
      label: t('list.ignition'),
    },
    // {
    //   id: 'state',
    //   numeric: false,
    //   disablePadding: false,
    //   label: t('list.machineState'),
    // },
    {
      id: 'stateText',
      numeric: false,
      disablePadding: false,
      label: t('list.machineState'),
    },
    {
      id: 'owner',
      numeric: false,
      disablePadding: false,
      label: t('list.owner'),
    },
    {
      id: 'nextService',
      numeric: false,
      disablePadding: false,
      label: t('list.nextServiceHours'),
    },
    {
      id: 'projectConstructionSite',
      numeric: false,
      disablePadding: false,
      label: t('list.constructionSite'),
    },
    {
      id: 'suggestedAddress',
      numeric: false,
      disablePadding: false,
      label: t('list.suggestedAddress'),
    },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            className={classes.tableHead}
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              classes={{
                root: classes.sortLabel,
                active: classes.sortLabel,
                icon: classes.sortLabel,
              }}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell className={classes.tableHead}>{t('list.details')}</TableCell>
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
      margin: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
    tableHead: {
      color: themeColors.yellow,
    },
    sortLabel: {
      color: `${themeColors.yellow} !important`,
    },
    pagination: {
      color: `${themeColors.yellow} !important`,
    },
    chipRed: {
      backgroundColor: themeColors.red,
      color: themeColors.white,
    },
    chipGreen: {
      backgroundColor: themeColors.green,
      color: themeColors.white,
    },
    cell: {
      maxWidth: 300,
    },
    tableBottom: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    getAddresses: {
      margin: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        margin: `0 0 0 ${theme.spacing(2)}`,
      },
    },
  })
);

interface IProps {
  startRowsPerPage?: number;
}

interface ISortableMachine {
  id: number;
  serialNumber: string;
  totalHoursOfCrusherOperation: number | null;
  isIgnitionOn: boolean;
  owner: string;
  nextService: any;
  projectConstructionSite: string | null;
  state: IState;
  stateText: string;
  suggestedAddress?: string | null;
  telemetrySnapshot?: ITelemetrySnapshot | null;
}

export default function EnhancedTable(props: IProps) {
  const machines = useSelector(
    (state: IReduxState) => state.machines.filteredMachines
  );
  const rows = !!machines
    ? machines.map((machine: IMachine, index) => {
      if(machine !== null && machine.logInfo !== null && !!machine.logInfo){
        console.log("###################################################");
        console.log(machine.logInfo);
        console.log("###################################################");
      }
      return {
          id: machine.id,
          serialNumber: machine.serialNumber,
          totalHoursOfCrusherOperation: !!machine.telemetrySnapshot
            ? (machine.telemetrySnapshot.totalHoursOfCrusherOperation as number)
            : 0,
          isIgnitionOn: !!machine.telemetrySnapshot
            ? (machine.telemetrySnapshot?.isIgnitionOn as boolean)
            : false,
          state: machine.state,
          stateText: machine.stateText,
          owner: machine.owner,
          nextService: !!machine.nextService
            ? machine.nextService.workingHoursTillService
            : '-',
          projectConstructionSite: machine.projectConstructionSite,
          suggestedAddress: machine.suggestedAddress,
          telemetrySnapshot: machine.telemetrySnapshot,
        };
      })
    : [];
  const startRowsPerPage = props.startRowsPerPage ? props.startRowsPerPage : 0;
  const classes = useStyles();
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<string | number>('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(startRowsPerPage);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string | number
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAddress = (machine: IMachine) => {
    console.log("***************************************");
    console.log("In getAddress " + machine.id + " - " + machine.serialNumber);
    if (!!machine.telemetrySnapshot) {
      console.log("***************************************");
      console.log("In getAddress " + machine.id + " - " + machine.serialNumber);
        const { location } = machine.telemetrySnapshot;

        console.log("location");
        console.log(location);

        if (
        location &&
        location.latitude !== null &&
        location.longitude !== null
      ) {
        console.log("In then1");
        dispatch(
          getSuggestedAddressForMachine(
            machine.id,
            location.latitude,
            location.longitude
          )
        );
      }
      else{
        console.log("In else1");
      }
    }
  };

  const getPageAddresses = () => {
    console.log("In getPageAddresses");

    const machinesOnPage = stableSort(rows, getSorting(order, orderBy)).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
    console.log("=> getAddress");
    machinesOnPage.forEach((machine: IMachine) => getAddress(machine));
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: IMachine, index: number) => {
                  const labelId = `enhanced-table-${index}`;
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>

                      <TableCell id={labelId} scope="row" align="right">
                        <Link component={RouterLink} to={`/fleetmanagement/machine/${row.id}`}>
                          {row.serialNumber}
                        </Link>
                      </TableCell>

                      <TableCell align="right">
                        {row.telemetrySnapshot?.totalHoursOfCrusherOperation}
                      </TableCell>

                      <TableCell align="right">
                        <Chip
                          size="small"
                          label={
                            row.telemetrySnapshot?.isIgnitionOn ? 'on' : 'off'
                          }
                          className={
                            row.telemetrySnapshot?.isIgnitionOn
                              ? classes.chipGreen
                              : classes.chipRed
                          }
                        />
                      </TableCell>

                      {/* <!--TableCell align="right">
                        {i18n.exists(`machine.state.${row.state?.key}`)
                          ? t(`machine.state.${row.state?.key}`)
                          : row.state?.key}
                      </TableCell --> */}

                      {/* <!--TableCell align="right">
                        {i18n.exists(`machine.state.${row.stateText}`)
                          ? t(`machine.state.${row.stateText}`)
                          : row.stateText}
                      </TableCell --> */}

                      <TableCell align="right">
                        {i18n.exists(`machine.state.${row.stateText}`)
                          ? t(`machine.state.${row.stateText}`)
                          : row.stateText}
                      </TableCell>

                      <TableCell align="right">
                        {row.owner}
                      </TableCell>
                      
                      <TableCell align="left">
                        {!!row.nextService ? `${row.nextService}` : '-'}
                      </TableCell>

                      <TableCell align="left">
                        {row.projectConstructionSite}
                      </TableCell>

                      <TableCell align="left" className={classes.cell}>
                        {row.telemetrySnapshot === undefined ? (
                          t('list.addressNotFound')
                        ) : row.suggestedAddress === null ? (
                          <Button
                            aria-describedby={t('list.getAddress')}
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() => getAddress(row)}
                          >
                            {t('list.getAddress')}
                          </Button>
                        ) : row.suggestedAddress === undefined ? (
                          t('list.addressNotFound')
                        ) : (
                          row.suggestedAddress
                        )}
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          color="primary"
                          component={RouterLink}
                          to={`/fleetmanagement/machine/${row.id}`}
                        >
                          <TuneIcon />
                        </Button>
                      </TableCell>

                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <div className={classes.tableBottom}>
          <Button
            aria-describedby={t('list.getAddresses')}
            className={classes.getAddresses}
            size="small"
            variant="outlined"
            color="primary"
            onClick={getPageAddresses}
          >
            {t('list.getAddresses')}
          </Button>
          <TablePagination
            className={classes.pagination}
            rowsPerPageOptions={[5, 10, 25, 50]}
            labelRowsPerPage={t('list.rowsPerPage')}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </Paper>
    </div>
);
}
