import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { updateSelectedMachineState } from '../../actions/searchActions';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { IReduxState, IMachineStateOption } from '../../types';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minWidth: '100px',
    margin: `${theme.spacing(4)}px`,
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing(1)}px ${theme.spacing(4)}px`,
    },
  },
}));

export default function MachineStateSelect() {
  const dispatch = useDispatch();
  const selectedMachineState: string = useSelector(
    (state: IReduxState) => state.search.selectedMachineState.key
  );
  const machineStateOptions = useSelector(
    (state: IReduxState) => state.search.extendedMachineStateOptions
  );
  const classes = useStyles();
  const handleChange = (key: string) => {
    const machineStateOption = machineStateOptions.filter(
      (item: IMachineStateOption) => item.key === key
    )[0];
    dispatch(updateSelectedMachineState({ ...machineStateOption }));
  };
  const { t, i18n } = useTranslation();
  return (
    <FormControl className={classes.root}>
      <InputLabel id="machine-state">
        {t('search.machineState.select')}
      </InputLabel>
      <Select
        labelId="machine-state-label"
        id="machine-state"
        value={selectedMachineState}
        onChange={(
          event: React.ChangeEvent<{ name?: string; value: string | any }>
        ) => handleChange(event.target.value)}
      >
        {machineStateOptions.map(({ id, key }, index) => {
          return (
            <MenuItem key={`state-${index}-${id}`} value={key}>
              {i18n.exists(`machine.state.${key}`) ? t(`machine.state.${key}`) : key}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
