/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    UnitValueDto,
    UnitValueDtoFromJSON,
    UnitValueDtoFromJSONTyped,
    UnitValueDtoToJSON,
} from './';

/**
 * 
 * @export
 * @interface SampledTelemetryDto
 */
export interface SampledTelemetryDto {
    /**
     * 
     * @type {Date}
     * @memberof SampledTelemetryDto
     */
    timestamp?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof SampledTelemetryDto
     */
    isEmpty?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SampledTelemetryDto
     */
    durationInSeconds?: number | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    engineFuelRate?: UnitValueDto | null;
    /**
     * 
     * @type {number}
     * @memberof SampledTelemetryDto
     */
    percentageLoadAtCurrentSpeed?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SampledTelemetryDto
     */
    fuelLevel?: number | null;
    /**
     * 
     * @type {number}
     * @memberof SampledTelemetryDto
     */
    adBlueLevel?: number | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    weightLoad1?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    weightLoad2?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    weightLoad3?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    weightLoad4?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    engineCrushSpeedActualValue?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    engineCrushSpeedDesiredValue?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    crusherEngineTemperature?: UnitValueDto | null;
    /**
     * 
     * @type {number}
     * @memberof SampledTelemetryDto
     */
    crusherDriveCurrentLoad?: number | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    intermediateCircuitVoltage?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    energyConsumeRate?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    mainDischargeConveyorFuelRate?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    mainDischargeConveyorEnergyRate?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    sideDischargeConveyorFuelRate?: UnitValueDto | null;
    /**
     * 
     * @type {UnitValueDto}
     * @memberof SampledTelemetryDto
     */
    sideDischargeConveyorEnergyRate?: UnitValueDto | null;
}

export function SampledTelemetryDtoFromJSON(json: any): SampledTelemetryDto {
    return SampledTelemetryDtoFromJSONTyped(json, false);
}

export function SampledTelemetryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SampledTelemetryDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'isEmpty': !exists(json, 'isEmpty') ? undefined : json['isEmpty'],
        'durationInSeconds': !exists(json, 'durationInSeconds') ? undefined : json['durationInSeconds'],
        'engineFuelRate': !exists(json, 'engineFuelRate') ? undefined : UnitValueDtoFromJSON(json['engineFuelRate']),
        'percentageLoadAtCurrentSpeed': !exists(json, 'percentageLoadAtCurrentSpeed') ? undefined : json['percentageLoadAtCurrentSpeed'],
        'fuelLevel': !exists(json, 'fuelLevel') ? undefined : json['fuelLevel'],
        'adBlueLevel': !exists(json, 'adBlueLevel') ? undefined : json['adBlueLevel'],
        'weightLoad1': !exists(json, 'weightLoad1') ? undefined : UnitValueDtoFromJSON(json['weightLoad1']),
        'weightLoad2': !exists(json, 'weightLoad2') ? undefined : UnitValueDtoFromJSON(json['weightLoad2']),
        'weightLoad3': !exists(json, 'weightLoad3') ? undefined : UnitValueDtoFromJSON(json['weightLoad3']),
        'weightLoad4': !exists(json, 'weightLoad4') ? undefined : UnitValueDtoFromJSON(json['weightLoad4']),
        'engineCrushSpeedActualValue': !exists(json, 'engineCrushSpeedActualValue') ? undefined : UnitValueDtoFromJSON(json['engineCrushSpeedActualValue']),
        'engineCrushSpeedDesiredValue': !exists(json, 'engineCrushSpeedDesiredValue') ? undefined : UnitValueDtoFromJSON(json['engineCrushSpeedDesiredValue']),
        'crusherEngineTemperature': !exists(json, 'crusherEngineTemperature') ? undefined : UnitValueDtoFromJSON(json['crusherEngineTemperature']),
        'crusherDriveCurrentLoad': !exists(json, 'crusherDriveCurrentLoad') ? undefined : json['crusherDriveCurrentLoad'],
        'intermediateCircuitVoltage': !exists(json, 'intermediateCircuitVoltage') ? undefined : UnitValueDtoFromJSON(json['intermediateCircuitVoltage']),
        'energyConsumeRate': !exists(json, 'energyConsumeRate') ? undefined : UnitValueDtoFromJSON(json['energyConsumeRate']),
        'mainDischargeConveyorFuelRate': !exists(json, 'mainDischargeConveyorFuelRate') ? undefined : UnitValueDtoFromJSON(json['mainDischargeConveyorFuelRate']),
        'mainDischargeConveyorEnergyRate': !exists(json, 'mainDischargeConveyorEnergyRate') ? undefined : UnitValueDtoFromJSON(json['mainDischargeConveyorEnergyRate']),
        'sideDischargeConveyorFuelRate': !exists(json, 'sideDischargeConveyorFuelRate') ? undefined : UnitValueDtoFromJSON(json['sideDischargeConveyorFuelRate']),
        'sideDischargeConveyorEnergyRate': !exists(json, 'sideDischargeConveyorEnergyRate') ? undefined : UnitValueDtoFromJSON(json['sideDischargeConveyorEnergyRate']),
    };
}

export function SampledTelemetryDtoToJSON(value?: SampledTelemetryDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'isEmpty': value.isEmpty,
        'durationInSeconds': value.durationInSeconds,
        'engineFuelRate': UnitValueDtoToJSON(value.engineFuelRate),
        'percentageLoadAtCurrentSpeed': value.percentageLoadAtCurrentSpeed,
        'fuelLevel': value.fuelLevel,
        'adBlueLevel': value.adBlueLevel,
        'weightLoad1': UnitValueDtoToJSON(value.weightLoad1),
        'weightLoad2': UnitValueDtoToJSON(value.weightLoad2),
        'weightLoad3': UnitValueDtoToJSON(value.weightLoad3),
        'weightLoad4': UnitValueDtoToJSON(value.weightLoad4),
        'engineCrushSpeedActualValue': UnitValueDtoToJSON(value.engineCrushSpeedActualValue),
        'engineCrushSpeedDesiredValue': UnitValueDtoToJSON(value.engineCrushSpeedDesiredValue),
        'crusherEngineTemperature': UnitValueDtoToJSON(value.crusherEngineTemperature),
        'crusherDriveCurrentLoad': value.crusherDriveCurrentLoad,
        'intermediateCircuitVoltage': UnitValueDtoToJSON(value.intermediateCircuitVoltage),
        'energyConsumeRate': UnitValueDtoToJSON(value.energyConsumeRate),
        'mainDischargeConveyorFuelRate': UnitValueDtoToJSON(value.mainDischargeConveyorFuelRate),
        'mainDischargeConveyorEnergyRate': UnitValueDtoToJSON(value.mainDischargeConveyorEnergyRate),
        'sideDischargeConveyorFuelRate': UnitValueDtoToJSON(value.sideDischargeConveyorFuelRate),
        'sideDischargeConveyorEnergyRate': UnitValueDtoToJSON(value.sideDischargeConveyorEnergyRate),
    };
}


