import { useState, useEffect } from 'react';
import createActivityDetector from 'activity-detector';

export const useIdle = () => {
  const options = {
    timeToIdle: 15 * 60 * 1000, // 15min in ms
    inactivityEvents: [],
  };
  const [isIdle, setIsIdle] = useState(false);
  useEffect(() => {
    const activityDetector = createActivityDetector(options);
    activityDetector.on('idle', () => {
      setIsIdle(true);
    });
    activityDetector.on('active', () => {
      setIsIdle(false);
    });
    return () => activityDetector.stop();
    // eslint-disable-next-line
  }, []);
  return isIdle;
};
