import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import { IReduxState, IExtendedMachineTagOption } from '../../types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { updateSelectedMachineTags } from '../../actions/searchActions';
import { useWindowSize } from '../useWindowSize';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 320,
      [theme.breakpoints.up('sm')]: {
        maxWidth: 600,
      },
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function SearchTags() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const size = useWindowSize();
  const isSmallScreen =
    size.width !== undefined && size.width < 500 ? true : false;
  const machineTagOptions = useSelector(
    (state: IReduxState) => state.search.machineTagOptions
  );
  const selectedMachineTags = useSelector(
    (state: IReduxState) => state.search.selectedMachineTags
  );

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    const tags = event.target.value;
    dispatch(updateSelectedMachineTags(tags));
    if (isSmallScreen) {
      setOpen(false);
    }
  };
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="machinetags-options-label">
          {t('machine.machineTags')}
        </InputLabel>
        <Select
          labelId="machinetags-options-label"
          id="machinetags-options"
          multiple
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={selectedMachineTags}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected: any) => (
            <div className={classes.chips}>
              {selected.map(
                (item: IExtendedMachineTagOption, index: number) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    style={{
                      borderColor: item.color,
                      color: item.color,
                    }}
                    key={`tag-${index}-${item}`}
                    label={item.key}
                    className={classes.chip}
                  />
                )
              )}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {machineTagOptions &&
            machineTagOptions.map((tag: any, index: number) => (
              <MenuItem key={`${tag.key}-${tag.id}-${index}`} value={tag}>
                {tag.key}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}
