/* tslint:disable */
/* eslint-disable */
/**
 * FleetManagement Frontend API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BackendInfoDto
 */
export interface BackendInfoDto {
    /**
     * 
     * @type {string}
     * @memberof BackendInfoDto
     */
    version?: string | null;
}

export function BackendInfoDtoFromJSON(json: any): BackendInfoDto {
    return BackendInfoDtoFromJSONTyped(json, false);
}

export function BackendInfoDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): BackendInfoDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': !exists(json, 'version') ? undefined : json['version'],
    };
}

export function BackendInfoDtoToJSON(value?: BackendInfoDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
    };
}


