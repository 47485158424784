import React from 'react';
import { Theme, ListItem, Typography, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { IReduxState, AccessLevel } from '../../types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import EditMachineNote from './EditMachineNote';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: 1,
    flexGrow: 1,
  },
  card: {
    [theme.breakpoints.up('xs')]: {
      margin: `${theme.spacing(4)}px 0`,
    },
    [theme.breakpoints.up('sm')]: {
      margin: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
      height: 400,
    },
  },
  cardContent: {
    height: '100%',
  },
  actionsArena: {
    paddingTop: 0,
    paddingBottom: 0,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  list: {
    height: '100%',
    overflowY: 'auto',
    width: '100%',
  },
  tagsWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyItems: 'space-around',
  },
}));

export default function MachineNotes() {
  const classes = useStyles();
  const { t } = useTranslation();
  const machineNote = useSelector(
    (state: IReduxState) => state.machineDetails.machine.notes
  );
  const currentlyAuthenticatedUserAccessLevel = useSelector(
    (state: IReduxState) =>
      state.machineDetails.machine.currentlyAuthenticatedUserAccessLevel
  );
  return (
    <React.Fragment>
      <ListItem dense alignItems="flex-start">
        <div className={classes.title}>
          <Typography gutterBottom variant="h6">
            {t('machine.notes')}
          </Typography>
          {currentlyAuthenticatedUserAccessLevel === AccessLevel.Write && (
            <EditMachineNote />
          )}
        </div>
      </ListItem>
      {machineNote ? (
        <ListItem dense alignItems="flex-start">
          <ListItemText
            secondary={
              <Typography
                variant="body1"
                color="textSecondary"
                component="span"
              >
                {machineNote}
              </Typography>
            }
          />
        </ListItem>
      ) : (
        <ListItem dense alignItems="flex-start">
          <ListItemText
            secondary={
              <Typography
                variant="subtitle1"
                color="textSecondary"
                component="span"
              >
                {t('machine.noNotes')}
              </Typography>
            }
          />
        </ListItem>
      )}
    </React.Fragment>
  );
}
