import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_BACKEND_INFO_SUCCESS,
  USER_LANGUAGE_CHANGE,
  USER_UNIT_CHANGE,
} from '../actions/types';
import { IReduxAuthProps } from '../types';

const initialState: IReduxAuthProps = {
  isAuthenticated: null,
  isLoading: null,
  user: null,
  backendInfo: {
    version: null,
  },
};

export default function(state = initialState, action: any) {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case FETCH_USER_FAIL:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
      };
    case FETCH_BACKEND_INFO_SUCCESS:
      return {
        ...state,
        backendInfo: action.payload,
      };
    case USER_LANGUAGE_CHANGE:
      return {
        ...state,
        user: {
          ...state.user,
          isoLanguageCode: action.payload.isoLanguageCode,
        },
      };
    case USER_UNIT_CHANGE:
      return {
        ...state,
        user: {
          ...state.user,
          measurementSystem: action.payload.measurementSystem,
        },
      };
    default:
      return state;
  }
}
