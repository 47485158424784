import React from 'react';
import { IReduxAuthProps, IReduxState } from '../../types';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUserSession } from '../../actions/authActions';
import { CircularProgress } from '@material-ui/core';
import { useLocation, Route, Redirect } from 'react-router';

interface IRouteProps {
  path?: string;
  component: any;
}

export default function ProtectedRoute(props: IRouteProps) {
  const { component: Component } = props;
  const dispatch = useDispatch();
  const auth = useSelector((state: IReduxState) => state.auth);
  const location = useLocation();
  const pathName = location.pathname.substr(1);

  const [userAuth, setUserAuth] = React.useState<IReduxAuthProps>();

  React.useEffect(() => {
    dispatch(fetchUserSession(pathName));
    //eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    if (auth !== undefined) {
      setUserAuth(auth);
    }
  }, [auth]);

  if (userAuth && !!userAuth.isLoading) {
    return (
      <div
        style={{
          width: '100%',
          height: '50vH',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );
  } else {
    if (userAuth && userAuth.isAuthenticated) {
      return <Route render={(props) => <Component {...props} />} />;
    }

    if (userAuth && userAuth.isAuthenticated === false) {
      return (
        <Redirect
          to={{
            pathname: '/login',
          }}
        />
      );
    }
    return null;
  }
}
